<div>
  <a class="right-upper" (click)="closeHelper()"><mat-icon>highlight_off</mat-icon></a>
<div class="panel1">
  <div class="panel-heading">
    <h5 class="helper-title" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [innerHtml]="dialogData.title"></h5>
  </div>

  <div mat-dialog-content class="helper-content" [innerHtml]="dialogData.content">
  </div>
</div>
</div>