export class RulebookCompare {
    /**
 * The Json object for topic list data.
 */
    public  TREE_DATA = {
    'Env Topic & SubTopic': {
      Air: {
        'Air Quality':
          ['Permitting',
          'General Requirements for Hazardous Air Pollutants (HAPs)',
          'Miscellaneous Air Quality Programs and Performance Standards'
          ],
          'MACT Standards': ['40 CFR 63 Subpart Q - MACT Standards for Industrial Process Cooling Towers',
          '40 CFR 63 Subpart F - MACT Standards for the Synthetic Organic Chemical Manufacturing Industry (SOCMI)',
          '40 CFR 63 Subpart OO - MACT Standards for Equipment Leaks - Tanks',
          '40 CFR 63 Subpart PP - MACT Standards for Equipment Leaks - Containers',
          '40 CFR 63 Subpart QQ - MACT Standards for Equipment Leaks - Surface Impoundments',
          '40 CFR 63 Subpart RR - MACT Standards for Equipment Leaks - Individual Drain Systems',
          '40 CFR Subpart VV - MACT Standards for Equipment Leaks - Oil-Water Separators and Organic-Water Separators',
          '40 CFR 63 Subpart H - MACT Standards for Equipment Leaks',
          '40 CFR 63 Subpart I - MACT Standards for Certain Processes Subject to the Negotiated Regulation for Equipment Leaks',
          '40 CFR 63 Subpart T - MACT Standards for Halogenated Solvent Cleaning',
          '40 CFR 63 Subpart GG - MACT Standards for Aerospace Manufacturing and Reworking Facilities',
          '40 CFR 63 Subpart G - MACT Standards from SOCMI for Process Vents, Storage Vessels, Transfer Operations, and Wastewater',
          '40 CFR 63 Subpart N - MACT Standards for Hard and Decorative Chromium Electroplating and Chromium Anodizing Tanks',
          '40 CFR 63 Subpart II - MACT Standards for Shipbuilding and Ship Repair (Surface Coating) Operations',
          '40 CFR 63 Subpart ZZZZ - MACT Standards for Stationary Reciprocating Internal Combustion Engines (RICE)',
          '40 CFR 63 Subpart DDDDD - MACT Standards for Industrial, Commercial and Institutional Boilers and Process Heaters at Major Sources of HAP Emissions',
          '40 CFR 63 Subpart JJJJJJ - MACT Standards for Industrial, Commercial and Institutional Boilers at Area Sources of HAP Emissions',
          '40 CFR 63 Subpart CCCCCC - MACT Standards for Gasoline Dispensing Facilities at Area Sources'
        ],
        'NESHAPs':[
          '40 CFR 61 Subpart M - NESHAP MACT Standards for Asbestos',
          '40 CFR 61 Subpart E - National Emission Standard for Mercury',
          '40 CFR 61 Subpart F - National Emission Standard for Vinyl Chloride',
          '40 CFR 61 Subpart V - National Emission Standard for Equipment Leaks from Fugitive Sources',
          '40 CFR 63 Subpart R - National Emission Standards for Gasoline Distribution Facilities (Bulk Gasoline Terminals and Pipeline Breakout Stations)'
        ],
        'New Source Performance Standards (NSPS)': null,
        'Ozone-Depleting Substances (ODSs)':null,
        'Accidental Release Prevention - RMPs':null,
        '40 CFR 65 Subpart A - 40 CFR 65 Subpart G - Consolidated Air Rule for the Synthetic Organic Chemical Manufacturing Industry (SOCMI)':null,
        'Acid Rain Program':null,
        'Federal NOx Budget Trading Program (40 CFR 97)':null,
        'Greenhouse Gas Reporting Program':null,
        'Cross-State Air Pollution Rule (CSAPR) Trading Program':null,
      },
      'Bulk Storage/Spill Plans':{
        'General Requirements and SPCC Plans': null,
        'OPA 90': [
          'OPA 90-PHMSA, EPA, and BSEE Requirements',
          'OPA 90-Vessel Response Plans',
          'OPA 90-MTR Facility Response Plans and Operations Manuals'
        ],
        'Underground Storage Tanks':'Underground Storage Tanks'
      },
      'Chemical Management/Hazardous Materials':{
        'Denatured Alcohol':null,
        'EPCRA':null,
        'PCBs':null,
        'Asbestos':null,
        'Releases and Notification':null,
        'Chemical Security Planning':null,
        'Formaldehyde Emissions From Wood Composite Products':null,
        'TSCA Section 6 Chemicals':null,
        'Pesticides, Herbicides, and Fungicides':null,
        'Toxic Substances Control Act':null
      },
      'Drinking Water': null,
      'Radiation Protection': null,
      'Waste': {
        'Hazardous Waste Generators': [
          'Very Small Quantity Generator Requirements',
          'Small Quantity Generator Requirements',
          'Large Quantity Generator Requirements'
        ],
        'Hazardous Waste TSD Facilities':[
          'TSD Facilities-General Requirements',
          'TSD Facilities-Specific Requirements'
        ],
        'Used Oil Management':null,
        'Universal Wastes':null,
        'Solid Waste':null,
      },
      Water: {
        'Wastewater': [
          'Process Wastewater Discharges',
          'Discharges to Publicly Owned Treatment Works (POTWs) and to Private Treatment Works',
          'Underground Injection Control (UIC)',
          'National Pretreatment Standards'
        ],
        'Storm Water Discharges': null,
        'Wetlands':null
      }
    }
  };

  public  TREE_DATA2 = {
    'OSHA Topic & SubTopic': {
      'Fire Safety and Emergency Preparedness': {
        'Fire Protection': null,
        'Exit Routes, Emergency Action Plans, and Fire Prevention Plans': null
      },
      'Hazardous Materials and Processes': {
        'Compressed Gases': null,
        'Flammable Liquids': null,
        'Spray Finishing using Flammable and Combustible Materials': null,
        'Dip Tanks':null,
        'Explosives':null,
        'Liquefied Petroleum Gas':null,
        'Anhydrous Ammonia':null,
        'Process Safety':null,
        'Boilers and Pressure Vessels':null,
        'HAZWOPER':[
          'Hazardous Waste Cleanup Operations',
          'TSD Facilities',
          'Other Emergency Response Operations'
        ]
      },
      'General In-Plant Safety':{
        'Recordkeeping and Posting':null,
        'Personal Protective Equipment':null,
        'Confined Space':null,
        'Lockout and Tagout':null,
        'General Environmental Controls':null,
        'Medical and First Aid':null,
        'Materials Handling':[
          'General and Split Rim Wheels',
          'Powered Industrial Trucks',
          'Hoisting and Lifting Equipment'
        ],
        'Machinery and Machine Guarding':null,
        'Handheld Tools':null,
        'Welding, Cutting, and Brazing':null
      },
      'Electrical Safety':null,
      'Surfaces, Platforms and Fall Protection': {
        'Walking and Working Surfaces': null,
        'Platforms and Manlifts': null
      },
      'Industry-Specific Requirements':{
          'Telecommunications': null,
          'Electric Power Generation, Transmission, and Distribution': null,
          'Pulp, Paper, and Paperboard Mills': null,
          'Textiles': null,
          'Bakeries': null,
          'Sawmills': null,
          'Laundry Machinery and Operations': null,
          'Grain Handling Operations': null,
          'Other State-Specific Special Industries': null,
          'Diving':null
      },
      'Industrial Hygiene':{
        'Occupational Health': null,
        'Bloodborne Pathogens': null,
        'Hazard Communication': ['Hazard Communication Standard','Chemical Hygiene Standard','DOT Marking'],
        'Chemical, Biological, and Physical Exposure Control':[
          'General Air Contaminants',
          'Medical Recordkeeping',
          'Ionizing Radiation',
          '1,2-Dibromo-3-Chloropropane (DBCP)',
          '1,3-Butadiene',
          'Acrylonitrile',
          'Asbestos',
          'Benzene',
          'Beryllium',
          'Cadmium',
          'Carcinogens',
          'Chromium (VI)',
          'Coke Oven Emissions',
          'Cotton Dust',
          'Ethylene Oxide',
          'Formaldehyde',
          'Inorganic Arsenic',
          'Lead',
          'Methylene Chloride',
          'Methylenedianiline (MDA)',
          'Respirable Crystalline Silica',
          'Vinyl Chloride'
        ]
      }
    }
  };


  public  TOPIC_KEY_VALUE = new Map<number, string>([
    [24144,'Permitting'],
    [24145,'New Source Performance Standards (NSPS)'],
    [24146,'General Requirements for Hazardous Air Pollutants (HAPs)'],
    [24147,'40 CFR 63 Subpart F - MACT Standards for the Synthetic Organic Chemical Manufacturing Industry (SOCMI)'],
    [24148,'40 CFR 63 Subpart H - MACT Standards for Equipment Leaks'],
    [24149,'40 CFR 63 Subpart OO - MACT Standards for Equipment Leaks - Tanks'],
    [24150,'40 CFR 63 Subpart PP - MACT Standards for Equipment Leaks - Containers'],
    [24151,'40 CFR 63 Subpart QQ - MACT Standards for Equipment Leaks - Surface Impoundments'],
    [24152,'40 CFR 63 Subpart RR - MACT Standards for Equipment Leaks - Individual Drain Systems'],
    [24153,'40 CFR 63 Subpart VV - MACT Standards for Equipment Leaks - Oil-Water Separators and Organic-Water Separators'],
    [24154,'40 CFR 63 Subpart WW - MACT Standards for Storage Vessels Level 2 Controls'],
    [24155,'40 CFR 63 Subpart I - MACT Standards for Certain Processes Subject to the Negotiated Regulation for Equipment Leaks'],
    [24156,'40 CFR 63 Subpart T - MACT Standards for Halogenated Solvent Cleaning'],
    [24157,'40 CFR 63 Subpart GG - MACT Standards for Aerospace Manufacturing and Reworking Facilities'],
    [24158,'40 CFR 61 Subpart M - NESHAP MACT Standards for Asbestos'],
    [24159,'40 CFR 63 Subpart G - MACT Standards from SOCMI for Process Vents, Storage Vessels, Transfer Operations, and Wastewater'],
    [24160,'40 CFR 63 Subpart Q - MACT Standards for Industrial Process Cooling Towers'],
    [24161,'40 CFR 61 Subpart E - National Emission Standard for Mercury'],
    [24162,'40 CFR 61 Subpart F - National Emission Standard for Vinyl Chloride'],
    [24163,'40 CFR 61 Subpart V - National Emission Standard for Equipment Leaks from Fugitive Sources'],
    [24164,'40 CFR 63 Subpart R - National Emission Standards for Gasoline Distribution Facilities (Bulk Gasoline Terminals and Pipeline Breakout Stations)'],
    [24165,'40 CFR 63 Subpart N - MACT Standards for Hard and Decorative Chromium Electroplating and Chromium Anodizing Tanks'],
    [24166,'40 CFR 63 Subpart II - MACT Standards for Shipbuilding and Ship Repair (Surface Coating) Operations'],
    [24167,'40 CFR 63 Subpart ZZZZ - MACT Standards for Stationary Reciprocating Internal Combustion Engines (RICE)'],
    [24168,'40 CFR 63 Subpart DDDDD - MACT Standards for Industrial, Commercial and Institutional Boilers and Process Heaters at Major Sources of HAP Emissions'],
    [24169,'40 CFR 63 Subpart JJJJJJ - MACT Standards for Industrial, Commercial and Institutional Boilers at Area Sources of HAP Emissions'],
    [24170,'40 CFR 63 Subpart CCCCCC - MACT Standards for Gasoline Dispensing Facilities at Area Sources'],
    [24171,'Ozone-Depleting Substances (ODSs)'],
    [24172,'Accidental Release Prevention - RMPs'],
    [24173,'40 CFR 65 Subpart A - 40 CFR 65 Subpart G - Consolidated Air Rule for the Synthetic Organic Chemical Manufacturing Industry (SOCMI)'],
    [24174,'Acid Rain Program'],
    [24175,'Federal NOx Budget Trading Program (40 CFR 97)'],
    [24176,'Miscellaneous Air Quality Programs and Performance Standards'],
    [24177,'Greenhouse Gas Reporting Program'],
    [24178,'Cross-State Air Pollution Rule (CSAPR) Trading Program'],
    [24179,'Process Wastewater Discharges'],
    [24180,'Discharges to Publicly Owned Treatment Works (POTWs) and to Private Treatment Works'],
    [24181,'Underground Injection Control (UIC)'],
    [24182,'National Pretreatment Standards'],
    [24183,'Storm Water Discharges'],
    [24184,'Drinking Water'],
    [24185,'Solid Waste'],
    [24186,'General Requirements and SPCC Plans'],
    [24187,'OPA 90-PHMSA, EPA, and BSEE Requirements'],
    [24188,'OPA 90-Vessel Response Plans'],
    [24189,'OPA 90-MTR Facility Response Plans and Operations Manuals'],
    [24190,'EPCRA'],
    [24191,'Releases and Notification'],
    [24192,'Chemical Security Planning'],
    [24193,'PCBs'],
    [24194,'Asbestos'],
    [24195,'Formaldehyde Emissions From Wood Composite Products'],
    [24196,'TSCA Section 6 Chemicals'],//not include.
    [24197,'Toxic Substances Control Act'],
    [24198,'Underground Storage Tanks'],
    [24199,'Radiation Protection'],
    [24200,'Wetlands'],
    [24201,'Pesticides, Herbicides, and Fungicides'],
    [24202,'Denatured Alcohol'],
    [24203,'Very Small Quantity Generator Requirements'],
    [24204,'Small Quantity Generator Requirements'],
    [24205,'Large Quantity Generator Requirements'],
    [24206,'TSD Facilities-General Requirements'],
    [24207,'TSD Facilities-Specific Requirements'],
    [24208,'Used Oil Management'],
    [24209,'Universal Wastes'],

    [24453,'Fire Protection'],
    [24434,'Exit Routes, Emergency Action Plans, and Fire Prevention Plans'],
    [24437,'Compressed Gases'],
    [24438,'Flammable Liquids'],
    [24439,'Spray Finishing using Flammable and Combustible Materials'],
    [24440,'Dip Tanks'],
    [24441,'Explosives'],
    [24442, 'Liquefied Petroleum Gas'],
    [24443, 'Anhydrous Ammonia'],
    [24444,'Process Safety'],
    [24454,'Boilers and Pressure Vessels'],
    [24445,'Hazardous Waste Cleanup Operations'],
    [24446, 'TSD Facilities'],
    [24447,'Other Emergency Response Operations'],
    [24432, 'Recordkeeping and Posting'],
    [24448,'Personal Protective Equipment'],
    [24449,'Confined Space'],
    [24450,'Lockout and Tagout'],
    [24451,'General Environmental Controls'],
    [24452,'Medical and First Aid'],
    [24455,'General and Split Rim Wheels'],
    [24456,'Powered Industrial Trucks'],
    [24457,'Hoisting and Lifting Equipment'],
    [24458,'Machinery and Machine Guarding'],
    [24459,'Handheld Tools'],
    [24460,'Welding, Cutting, and Brazing'],
    [24470,'Electrical Safety'],
    [24433,'Walking and Working Surfaces'],
    [24435,'Platforms and Manlifts'],
    [24461,'Telecommunications'],
    [24462,'Electric Power Generation, Transmission, and Distribution'],
    [24463,'Pulp, Paper, and Paperboard Mills'],
    [24464,'Textiles'],
    [24465,'Bakeries'],
    [24466,'Sawmills'],
    [24467,'Laundry Machinery and Operations'],
    [24468,'Grain Handling Operations'],
    [24469,'Other State-Specific Special Industries'],
    [24471,'Diving'],
    [24436,'Occupational Health'],
    [24497,'Bloodborne Pathogens'],
    [24472,'Hazard Communication Standard'],
    [24473,'Chemical Hygiene Standard'],
    [24474,'DOT Marking'],
    [24475,'General Air Contaminants'],
    [24494,'Medical Recordkeeping'],
    [24493,'Ionizing Radiation'],
    [24486,'1,2-Dibromo-3-Chloropropane (DBCP)'],
    [24491,'1,3-Butadiene'],
    [24487,'Acrylonitrile'],
    [24476,'Asbestos'],
    [24483,'Benzene'],
    [24496,'Beryllium'],
    [24482,'Cadmium'],
    [24477,'Carcinogens'],
    [24481,'Chromium (VI)'],
    [24484,'Coke Oven Emissions'],
    [24485,'Cotton Dust'],
    [24488,'Ethylene Oxide'],
    [24489,'Formaldehyde'],
    [24479,'Inorganic Arsenic'],
    [24480,'Lead'],
    [24492,'Methylene Chloride'],
    [24490,'Methylenedianiline (MDA)'],
    [24495,'Respirable Crystalline Silica'],
    [24478,'Vinyl Chloride']
  ]);

  public getRbkey(rbname: string): number {
    for (let [key, value] of this.TOPIC_KEY_VALUE.entries()) {
        if (value === rbname)
          return key;
    }
    return 0;
  }

  public getRbName(rbkey: number): string {
    for (let [key, value] of this.TOPIC_KEY_VALUE.entries()) {
        if (key === rbkey)
          return value;
    }
    return "";
  }
}