<div *ngIf="customList else noData" class="right-block">
    <div class="widget-toolbox" id="custom-title" fxLayout="row" fxLayoutAlign="start center">
      <h4>Custom Topics</h4>
      <span class="shareCol">Share Permission</span>
    </div>
    <div class="widget-main">
      <ul *ngFor="let ttl of customList" class="nav-list">
        <ul class="sub-menu" fxLayout="row" fxLayoutAlign="start start">
          <li class="sub-menu">
            <div class="heavy">{{ttl.title}}</div>
            <ul *ngFor="let tp of ttl.topics" class="sub-item">
              <div fxLayout="row" [ngClass]="{'active': tp.topicID == (this.selectedTopic ? this.selectedTopic: customList[0].topics[0].topicID)}">
                <span fxFlex="75" class="text-inside-list fst"  (click)="onClickTopic(tp.topicID)">{{tp.topic}}</span>
                <span fxFlex="25" class="text-inside-list" matTooltip="Shared permission"  matTooltipClass="tooltip1">
                  <mat-form-field class="share-permission">
                    <select matNativeControl
                    class="permission-dropdown"
                    (change)="onShareChange($event.target.value, tp.topicID)"
                    [disabled]="userId !== tp.userID">
                      <option value="No" [selected]="tp.locktoUser === true">No</option>
                      <option value="Edit" [selected]="!tp.locktoUser">Edit</option>
                      <option value="View" [selected]="tp.locktoUser === false">View</option>
                    </select>
                </mat-form-field>

                </span>
              </div>
          </ul>
        </li>
      </ul>
    </ul>
  </div>
</div>

<ng-template #noData>No Data Available</ng-template>