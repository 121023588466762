import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeStyle, SafeUrl} from '@angular/platform-browser';

/*
* Parse JSON string to JSON objects
* Usage:
*   string | jsonArray
* Example:
*   {{ "" | jsonArray }}
*   formats to:
*/
@Pipe({name: 'jsonArray'})
export class JsonArrayPipe implements PipeTransform {
  transform(value: string): object {
    var myObj = JSON.parse(value);
    var byFormat = myObj.slice(0);
    byFormat.sort(function(a,b){
      return a.Format < b.Format ? -1 : a > b ? 1 : 0;
    });

    return byFormat;
  }
}


@Pipe({name: 'safe'})
export class SafePipe {
  constructor(private sanitizer:DomSanitizer){}

  transform(value: string, type: string): SafeHtml | SafeResourceUrl | SafeUrl{
    switch(type){
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}


@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return it.toLocaleLowerCase().includes(searchText);
    });
  }
}
