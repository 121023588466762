import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../core/api.service';
import { CustomTopic, CustomTopicWithTitle, CustomRanking, CustomTemplate, CustomScoring } from '../model/CustomQuestion';

@Injectable({
  providedIn: 'root'
})
export class CustomQuestionService {
  public topicChangeFlag = false;
  public loading = false;
  public onScoring = true;
  constructor(
    private apiService: ApiService
  ) { }

  public getCustomList(userid: number): Observable<CustomTopicWithTitle[]>{
    let endpoint = "Apptool/GetCustomTopic/" + userid;
    return this.apiService.get(endpoint);
  }

  public getCustomScoring(templateId: number){
    let endpoint = "Apptool/GetCustomScoring/" + templateId;
    return this.apiService.get(endpoint);
  }

  public getCustomRanking(templateId: number){
    let endpoint = "Apptool/GetCustomRanking/" + templateId;
    return this.apiService.get(endpoint);
  }

  public getCustomTemplate(){
    let endpoint = "Apptool/GetCustomTemplate";
    return this.apiService.get(endpoint);
  }

  public getCustomTopic(topicID: string){
    let endpoint = "Apptool/GetCustomContent/" + topicID;
    return this.apiService.get(endpoint);
  }

  public deleteCustomContent(contentKey: number){
    let endpoint = "Apptool/CustomContent/" + contentKey;
    return this.apiService.delete(endpoint);
  }

  public deleteCustomTopic(topicID: string){
    let endpoint = "Apptool/CustomTopic/" + topicID;
    return this.apiService.delete(endpoint);
  }

  public saveCustomTopic(data: CustomTopic){
    let endpoint = "Apptool/SaveCustomQuestion";
    return this.apiService.post(endpoint, data);
  }

  public updateCustomTopicShare(data: CustomTopic){
    let endpoint = "Apptool/UpdateCustomShare";
    return this.apiService.put(endpoint, data);
  }


  public getTopicChangeFlag(): boolean{
    return this.topicChangeFlag;
  }

  public setTopicChangeFlag(flag: boolean){
    this.topicChangeFlag = flag;
  }

  // To check the current page is Soring or Ranking: true - Scoring; false - Ranking;
  public getOnScoring():boolean{
    return this.onScoring;
  }

  public setOnScoring(flag: boolean){
    this.onScoring = flag;
  }

  // To add a ranking element.
  public addRankingElement(rankItem: CustomRanking){
    let endpoint = "Apptool/SaveRankitem";
    return this.apiService.post(endpoint, rankItem);
  }

  // to update a ranking element.
  public updateRankingElement(rankItem: CustomRanking){
    let endpoint = "Apptool/UpdateRankitem";
    return this.apiService.post(endpoint, rankItem);
  }

  // to delete a ranking element
  public deleteRankingElement(rankingKey: number){
    let endpoint = "Apptool/CustomRankingItem/" + rankingKey;
    return this.apiService.delete(endpoint);
  }

  // to add a scoring item
  public addScoringElement(scoringItem: CustomScoring){
    let endpoint = "Apptool/SaveScoringItem";
    return this.apiService.post(endpoint, scoringItem);
  }

  // to update a scoring element.
  public updateScoringElement(scoringItem: CustomScoring){
    let endpoint = "Apptool/UpdateScoringItem";
    return this.apiService.post(endpoint, scoringItem);
  }

  // to delete a scoring element
  public deleteScoringElement(scoringKey: number){
    let endpoint = "Apptool/CustomScoringItem/" + scoringKey;
    return this.apiService.delete(endpoint);
  }

  // to create a custom template
  public createCustomTemplate(data: CustomTemplate){
    let endpoint = "Apptool/SaveCustomTemplate";
    return this.apiService.post(endpoint, data);
  }

  // to save a existing custom template.
  public saveCustomTemplate(data: CustomTemplate){
    let endpoint = "Apptool/SaveCustomTemplate";
    return this.apiService.post(endpoint, data);
  }

  // to delete a custom template
  public deleteCustomTemplate(templateKey: number){
    let endpoint = "Apptool/CustomTemplate/" + templateKey;
    return this.apiService.delete(endpoint);
  }

}
