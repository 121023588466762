<h2 mat-dialog-title>Edit Custom Template</h2>
<div mat-dialog-content [formGroup]="myGroup">
        <mat-form-field class="full-width" mat-focused>
            <mat-label>Template Name: </mat-label>
            <input matInput formControlName="templateControl" cdkFocusInitial>
        </mat-form-field>
        <mat-checkbox
          [(ngModel)]="shareCheckbox"
          [ngModelOptions]="{standalone: true}"
          [disabled] = "userId != data.userId"
          class="full-width">Do Not Share With Company
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="activeCheckbox" [ngModelOptions]="{standalone: true}" class="full-width">Make this your Active Template</mat-checkbox>
</div>
<div mat-dialog-actions class="right-end">
  <button mat-button (click)="close()" class="mat-raised-button" cdkFocusInitial>Cancel</button>
  <button class="mat-raised-button mat-primary" [disabled]="myGroup.value.templateControl === ''" mat-button (click)="onSaveTemplate()">Save</button>
</div>