import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import {SsoComponent} from './sso/sso.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import {NotFoundComponent} from './not-found-component/not-found-component.component';
import { SasolComponent } from './sasol/sasol.component';
import { TransportComponent } from './transport/transport.component';

const routes: Routes = [
	{
		path: '',
		component: SsoComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path:'sasol',
		component: SasolComponent
	},
	{
		path: 'login/:token',
		component: SsoComponent
	},
	{
		path: 'home',
		component: DashboardComponent,
		canActivate:[AuthGuard]
	},
	{
		path: 'notfound',
		component: NotFoundComponent,
		//canActivate:[AuthGuard]
	},
	{
		path: 'open.asp',
		component: TransportComponent

	},
	/*{
		path: 'apptool',
		loadChildren:() => import('./apptool/apptool.module').then(m => m.ApptoolModule)
	},*/
	{ path: '**', redirectTo: 'notfound'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
