import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import {TemplateService} from '../../service/template.service';
import { TemplateContent } from '../../model/TemplateRulebook';
import { AlertifyService } from '../../../_models/alertify.service';

@Component({
  selector: 'app-list-rulebook',
  templateUrl: './list-rulebook.component.html',
  styleUrls: ['./list-rulebook.component.css']
})
export class ListRulebookComponent implements OnInit {

  @Input() rbList:TemplateContent[];
  @Input() selected: string;
  @Input() csskey: number;
  @Input() auditName: string;
  @Output() selectedAppkey = new EventEmitter<number>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: TemplateService,
    private alertifyService: AlertifyService) { }

  ngOnInit() {
    this.service.setKey(parseInt(this.selected));
  }

  onClickRulebook(appkey: number, rbName: string, abrided: boolean){
    if(abrided) return;
    //console.log("click on rulebook: " + appkey);
    this.service.setRbName(rbName);
    this.service.setKey(appkey);
    this.selectedAppkey.emit(appkey);
  }

  onBackSummary(){
    var myurl = 'audithub/apptool/edit/' + this.csskey ;
    this.router.navigateByUrl(myurl).then( err =>{
      console.log(err);
    });

  }

  onNextRulebook(){
    var values = this.service.getNextAppkey();
    if(values != null){
      var nextAppkey = values.first;
      var nextRbName = values.second;

      this.service.setKey(nextAppkey);
      this.service.setRbName(nextRbName);
      this.selectedAppkey.emit(nextAppkey);
    }else{
      //alert("You have reached the end of the Applicability Questions!");
      this.alertifyService.warning("You have reached the end of the Applicability Questions!");
    }
  }
}