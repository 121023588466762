export const Emis = [
  {"name":"Velocity", "value": 2},
  {"name":"Convergence", "value": 3},
  {"name":"Ideagen EHS", "value": 4},
  {"name":"Cority", "value": 5},
  {"name":"Marsh", "value": 6},
  {"name":"SBN", "value": 7},
  {"name":"ETQ", "value": 9},
  {"name":"Intelex", "value": 10},
  {"name":"EHS Insight", "value": 11},
  {"name":"AIC", "value": 13},
  {"name":"Partner Demo", "value": 15},
  {"name":"BSI/Entropy", "value": 16},
  {"name":"SafetyStratus", "value": 18},
  {"name":"Vector", "value": 24},
];