import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import {TemplateService} from '../../service/template.service';


import { TemplateContent } from '../../model/TemplateRulebook';

@Component({
  selector: 'app-rulebook-list',
  templateUrl: './rulebook-list.component.html',
  styleUrls: ['./rulebook-list.component.css']
})
export class RulebookListComponent implements OnInit {

  @Input() rbList:TemplateContent[];
  @Input() selected: string;
  @Input() csskey: number;
  @Output() selectedkey = new EventEmitter<number>();

  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private service: TemplateService) { }

  ngOnInit() {
    this.service.setKey(parseInt(this.selected));
  }

  onClickRulebook(rbkey: number, rbName: string){
    this.service.setRbName(rbName);
    this.service.setKey(rbkey);
    this.selectedkey.emit(rbkey);  
  }

  onBackSummary(){
    var myurl = 'audithub/apptool/edit/' + this.csskey ;
    this.router.navigateByUrl(myurl).then( err =>{
      console.log(err);
    });
  }

  onNextRulebook(){    
    var values = this.service.getNextRbkey();
    if(values != null){
      var nextRbkey = values.first;
      var nextRbName = values.second;
     
      this.service.setKey(nextRbkey);
      this.service.setRbName(nextRbName);
      this.selectedkey.emit(nextRbkey);
    }
  }
}