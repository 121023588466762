
<div class="spinner-container" *ngIf="service.loading">
  <mat-spinner></mat-spinner>
</div>
<div class="button-row" fxLayout="row" fxLayoutAlign="space-between center">
  <div style="display: flex;">
    <ng-container *ngIf="hasState">
      <button mat-button (click)="showState()" class="title" [ngClass]="{'highlight': selectedState != null}">
        <mat-icon matTooltip="State" matTooltipClass="tooltip1">location_on</mat-icon>
      </button>
    </ng-container>
    <h5 class="title" *ngIf="selectedState">@{{selectedState}}</h5>
  </div>
  <div>
    <button mat-button (click)="onBackSummary()">Back to Summary</button>
  <button mat-button (click)="onBuild('C3.0')" *ngIf="account.userType !== 'ra-only'">Build RegRegister</button>
  <button mat-button (click)="onBuild('reghub')" matTooltip="To upload templates & citations into RegHub to be monitored" matTooltipClass="tooltip1">
    <mat-icon aria-hidden="true" aria-label="Next">visibility</mat-icon>Monitor
  </button>
  </div>
</div>


<div *ngIf="regRegister$ | async as acs">
  <div class="rulebook-content">
    <mat-table [dataSource]="dataSource">

      <!-- Position Column -->
      <ng-container matColumnDef="citation">
        <mat-header-cell *matHeaderCellDef class="citation"> Citation </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a class="linkInCell" (click)="displayCitation(element.citation)">{{element.citation}}</a>
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="para">
        <mat-header-cell *matHeaderCellDef class='para'> para </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a class="linkInCell" [ngClass]="{'disabledLink': account.userType === 'ra-only'}" (click)="displayParagraph(element.rbParaKey)">{{element.para}} </a>
        </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="acronym">
        <mat-header-cell *matHeaderCellDef class='para'> acronym </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.acronym}} </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="topic">
        <mat-header-cell *matHeaderCellDef class='topic'> topic </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.topic}} </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="rulebook">
        <mat-header-cell *matHeaderCellDef  class='topic'> rulebook </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{decodeHtml(element.rulebook)}} </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="section" >
        <mat-header-cell *matHeaderCellDef> Section </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.section}} </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

  </div>

</div>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>