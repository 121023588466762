<mat-card class="card-demo">
  <mat-card-title class="l8r2">
    <h2></h2>
    <button class="button-action" mat-button
      (click)="newTopic()">New Custom Topic
    </button>
    <button mat-button class="button-action"
      (click)="backToHome()"
      matTooltip="Back to RegApply home"
      matTooltipClass="tooltip1">
      <mat-icon>west</mat-icon>
    </button>
  </mat-card-title>

  <mat-card-content class="large">
    <div fxLayout="column" fxFill>
      <div fxLayout fxFlex>
        <div class="one" fxFlex="80" fxLayoutAlign="start start">
          <app-edit-custom
            [customList]=this.customTopic
            [selectedTopic] = this.selectedTopic
            [userId] = userId
            (deleteSubtopic)="onDeleteSubtopic($event)"
            (deleteTopic)="onDeleteTopic($event)"
            (saveCustomTopic)="onSaveCustomTopic($event)"
            (newSubtopic)="onNewSubtopic()"
            class="edit-custom">
          </app-edit-custom>
        </div>
        <div class="two" fxFlex="20" fxLayoutAlign="start start">
          <app-list-custom
            [customList]=this.customTopic
            [selectedTopic] = this.selectedTopic?.topicID
            [userId] = this.userId
            (selectedCustomTopic)="onClickCustomTopic($event)"
            (updateCustomShare)="onUpdateCustomShare($event)"
             class="list-custom">
          </app-list-custom>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer class="bottomPad">
    <div class="hint"></div>
  </mat-card-footer>
</mat-card>
<div class="spinner-container" *ngIf="alertifyService.loading">
  <mat-spinner></mat-spinner>
</div>
