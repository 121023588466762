<div class="spinner-container" *ngIf="service.loading">
  <mat-spinner></mat-spinner>
</div>

<div class="audit-header">
  <h2>International Protocol (IP) Request - My List</h2>  
</div>

<div class="evenly">
  <mat-checkbox *ngFor="let item of IPArray" (change)="onCountryCheck($event, item.Id)">{{item.Name}}</mat-checkbox>
</div>
