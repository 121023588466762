import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ApiErrorHandler } from '../_models/api.error.handler';
import { bindFunctionsToThis } from '../_extensions/object.extensions';
import { localStorageKeys } from '../_constants';
import { isNullOrUndefined } from 'util';
import { Observable, throwError } from 'rxjs';
import { map, share, catchError, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService} from 'ngx-cookie-service';


@Injectable({
	providedIn: 'root'
})
export class ApiService {
  private headers = new HttpHeaders();

  constructor(protected http: HttpClient,
    private cookieService: CookieService,
              protected apiErrorHandler: ApiErrorHandler) {
    bindFunctionsToThis(this);
    this.headers.append('Content-Type', 'application/json');
    const authToken = localStorage.getItem(localStorageKeys.authToken);

    if (!!authToken) {
      this.headers = this.headers.set('Authorization', authToken);
    }
  }

  private static parseResponse(response, options:any): any {
    if (!response) {
      return response;
    }
    return options.isJson && response.hasOwnProperty('_body')
      ? response['_body'] ? response.json() : null
      : response;
  }

  private static getParamsFromObject(paramsObj: any) {
    let urlSearchParams = new HttpParams();

    if (paramsObj) {
      for (const key in paramsObj) {
        if (paramsObj.hasOwnProperty(key)) {
          const paramValue = paramsObj[key];

          if (paramValue instanceof Date) {
            paramsObj[key] = paramsObj[key].toISOString();
          }

          if (isNullOrUndefined(paramValue)) {
            delete paramsObj[key];
          } else {
            urlSearchParams = urlSearchParams.set(key, paramsObj[key]);
          }
        }
      }
    }

    return urlSearchParams;
  }

  public get(url, paramsObj?, options = new ApiRequestOptions()): Observable<any> {
    const params = ApiService.getParamsFromObject(paramsObj);
    return this.http.get(`${environment.endpoint}${url}`, {params, headers: this.headers})
      .pipe(
        timeout(options.timeout),
        map(response => ApiService.parseResponse(response, options)),
        share(),
        catchError(this.handleError)
      );
  }

  public put(url, body?, options = new ApiRequestOptions()): Observable<any> {
    return this.http.put(`${environment.endpoint}${url}`, body, {headers: this.headers})
      .pipe(
        timeout(options.timeout),
        map(response => ApiService.parseResponse(response, options)),
        share(),
        catchError(this.handleError)
      );
  }

  public post(url, body?, options = new ApiRequestOptions()): Observable<any> {
    return this.http.post(`${environment.endpoint}${url}`, body, {headers: this.headers })
      .pipe(
        timeout(options.timeout),
        map(response => ApiService.parseResponse(response, options)),
        share(),
        catchError(this.handleError)
      );
  }

  public delete(url, paramsObj?, options = new ApiRequestOptions()): Observable<any> {
    const params = ApiService.getParamsFromObject(paramsObj);

    return this.http.delete(`${environment.endpoint}${url}`, {params, headers: this.headers})
      .pipe(
        timeout(options.timeout),
        share(),
        catchError(this.handleError)
      );
  }

  public download(url, paramsObj?, options = new ApiRequestOptions()): Observable<any> {
    const params = ApiService.getParamsFromObject(paramsObj);
    return this.http.get(`${environment.endpoint}${url}`, {params, headers: this.headers, responseType: 'blob'})
      .pipe(
        timeout(options.timeout),
        map(response => ApiService.parseResponse(response, options)),
        share(),
        catchError(this.handleError)
      );
  }

  public setAuthorizationHeader(token: any) {
    const authToken = `Bearer ${token.token}`;
    this.headers = this.headers.set('Authorization', authToken);
    localStorage.setItem(localStorageKeys.authToken, authToken);
  }

  public clearAuthorizationHeader() {
    this.headers = this.headers.delete('Authorization');
    localStorage.removeItem(localStorageKeys.authToken);
  }

  public clearCookie(){
    this.deleteCookie("STPHUB_ID");
    this.deleteCookie("authorized_state");
    this.deleteCookie("STPHUB_TOKEN");
    this.deleteCookie("EMIS");
    this.deleteCookie(environment.cookieIdleMultiTab);
    //this.cookieService.delete(environment.cookieIdleMultiTab);
    let domain = environment.DOMAIN;
    this.cookieService.set('STPHUB_ID', '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'),'/',domain,false,'Lax'); // using cookieService.set() to delete the cookie.
    this.cookieService.set('STPHUB_TOKEN', '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'),'/',domain,false,'Lax'); // using cookieService.set() to delete the cookie.

  }

  deleteCookie(cookie: string){
    let domain = environment.DOMAIN;
    console.debug("to delete cookie: " + cookie + " in domain " + domain);
    try{
      document.cookie = cookie + "=test@test.com; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; Domain=" + domain + ";Secure";
      //this.cookieService.delete(cookie, "/", domain);
    }catch(err){
      console.error("error: " + err.message);
    }
  }

  private handleError(error: HttpErrorResponse) {
    //console.debug("api servie is handling error." + error);
    //this.apiErrorHandler.handle(error);
    return throwError(error);
  }
}

export class ApiRequestOptions {
  public isJson: boolean | undefined = true;
  public timeout = 5 * 60 * 1000;
}