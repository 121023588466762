import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatSortModule} from '@angular/material/sort';
import {MatGridListModule} from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatBadgeModule} from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxPrintModule} from 'ngx-print';
import {MatSelectModule} from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ColorPickerModule} from 'ngx-color-picker';

import { AutocompleteComponent } from './google-places.component';
import { EditApplicabilityComponent } from './edit-applicability/edit-applicability.component';
import { AnswerQuestionComponent } from './edit-applicability/answer-question/answer-question.component';
import { ListRulebookComponent } from './edit-applicability/list-rulebook/list-rulebook.component';
import { EditTemplateComponent, StateListDialog ,DisplayReviewDialog, PreAnswerListDialog,ConfirmBuildDialog} from './edit-template/edit-template.component';
import { NewAuditComponent } from './new-audit/new-audit.component';
import { ConfirmDialog} from './new-audit/new-audit.component';
import { ViewRulebooksComponent, StateDialog } from './view-rulebooks/view-rulebooks.component';
import { RulebookContentComponent} from './view-rulebooks/rulebook-content/rulebook-content.component';
import { RulebookListComponent } from './view-rulebooks/rulebook-list/rulebook-list.component';
import { ParaDisplayDialog, ViewCitationsComponent } from './view-citations/view-citations.component';
import { CustomQuestionsComponent,NewTopicDialog } from './custom-questions/custom-questions.component';
import { EditCustomComponent } from './custom-questions/edit-custom/edit-custom.component';
import { ListCustomComponent } from './custom-questions/list-custom/list-custom.component';
import { RankAndScoreComponent,NewTemplateDialog, EditTemplateDialog } from './rank-and-score/rank-and-score.component';
import { ListScoringComponent } from './rank-and-score/list-scoring/list-scoring.component';
import { EditRankingDialog, EditScoringComponent, EditScoringDialog } from './rank-and-score/edit-scoring/edit-scoring.component';
import { DownloadListDialog} from './apptool.component';
import { BuildStatusComponent } from '../build-status/build-status.component';
import {PubviewTrackComponent} from '../pubview-track/pubview-track.component';

@NgModule({
  declarations: [
    AutocompleteComponent,
    EditTemplateComponent,
    EditApplicabilityComponent,
    AnswerQuestionComponent,
    ListRulebookComponent,
    NewAuditComponent,
    ConfirmDialog,
    StateListDialog,
    StateDialog,PreAnswerListDialog,ConfirmBuildDialog,
    ViewRulebooksComponent,
    RulebookContentComponent,
    RulebookListComponent,
    ViewCitationsComponent,
    CustomQuestionsComponent,
    EditCustomComponent,
    ListCustomComponent, NewTopicDialog, EditRankingDialog, DisplayReviewDialog,
    EditScoringDialog, RankAndScoreComponent, ListScoringComponent,
    EditScoringComponent, NewTemplateDialog, EditTemplateDialog, ParaDisplayDialog, DownloadListDialog, BuildStatusComponent, PubviewTrackComponent,],
  imports: [
    RouterModule,
    CommonModule,
    MatGridListModule,
    FlexLayoutModule,
    MatRadioModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatCheckboxModule,MatDividerModule, MatTableModule, MatSortModule,MatPaginatorModule,MatRadioModule,
    MatButtonModule,MatBadgeModule,MatMenuModule,MatToolbarModule,MatButtonToggleModule,MatTooltipModule,MatSelectModule,MatGridListModule,
    FormsModule, ReactiveFormsModule, NgxPrintModule,  MatDialogModule, ColorPickerModule,
  ],
  entryComponents:[ConfirmDialog,
    StateListDialog, StateDialog, NewTopicDialog,PreAnswerListDialog,ConfirmBuildDialog,
    EditRankingDialog,EditScoringDialog,
    NewTemplateDialog,EditTemplateDialog, ParaDisplayDialog,DownloadListDialog,DisplayReviewDialog,]
})
export class ApptoolModule { }
