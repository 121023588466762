<div *ngIf="templateList else noData" class="right-block">

  <div class="widget-body">
    <div class="widget-toolbox" fxLayout="row" fxLayoutAlign="start center">
        <h4>Template List</h4>
    </div>
    <div class="widget-main">
      <ul *ngFor="let ttl of templateList; let i = index" class="nav-list">
          <li class="sub-menu"
            (click)="onClickTemplate(ttl.templateKey)"
            [ngClass]="{'active': ttl.templateKey == this.currentItem, 'bold': ttl.activeStatus}"
            [matTooltip]="ttl.activeStatus ? 'Current active template' : null" matTooltipClass="tooltip1">
            {{ttl.templateName}}<span *ngIf="ttl.activeStatus">*</span>
          </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #noData>No Data Available</ng-template>