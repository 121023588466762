import { Component, OnInit, Input, Output,EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { Observable,BehaviorSubject } from 'rxjs';

import { AppSect, Rulebook } from '../../model/TemplateRulebook';
import { TemplateService } from '../../service/template.service';

@Component({
  selector: 'app-rulebook-content',
  templateUrl: './rulebook-content.component.html',
  styleUrls: ['./rulebook-content.component.css']
})
export class RulebookContentComponent implements OnInit {
  @Input() rb$: Observable<Rulebook>;
  hideGuidenote: boolean;
  selectedState: string;
  auditName: string;

  constructor(private service: TemplateService) {

   }

  // to get the @input data from parent.
  ngOnInit() {
    this.auditName = this.service.getAuditName();
    this.rb$.subscribe( data => {
   });

   this.service.getHideGuidenote().subscribe(re => {
     this.hideGuidenote = re;
    })
  }

  ngOnChanges(){
    this.rb$.subscribe(data =>  {
    });
  }
}