<div class="page-content">
	<header class="w3-containter">

	</header>
    <div class="video-zone" >
        <div class="video-item" >
            <label class="video-title">Ranking & Scoring</label>
            <button  class="normal-button" mat-button (click)="displayVideo('scoring')"><mat-icon>smart_display</mat-icon></button>
        </div>

        <div class="video-item" >
            <label class="video-title">Pocket Reference</label>
            <button  class="normal-button" mat-button  (click)="displayVideo('pocket')"><mat-icon>smart_display</mat-icon></button>
        </div>

        <div class="video-item" >
            <label class="video-title">How to Build a New Template</label>
            <button class="normal-button"  mat-button  (click)="displayVideo('template')"><mat-icon>smart_display</mat-icon></button>
        </div>

        <div class="video-item" >
            <label class="video-title">Product List: Basics of PDF Publications</label>
            <button class="normal-button" mat-button  (click)="displayVideo('publications')"><mat-icon>smart_display</mat-icon></button>
        </div>
        <div class="video-item" >
            <label class="video-title">Xcelerator Tool</label>
            <button  class="normal-button" mat-button  (click)="displayVideo('xcelerator')"><mat-icon>smart_display</mat-icon></button>
        </div>
    </div>
    <br><br><br>
    <H4>Watch More Training Videos at <a href="https://elibrary.stpehs.com/videos/" target="_blank">STP Video Library</a></H4>

    <div></div>
    <div></div>
</div>