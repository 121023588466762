<mat-toolbar color="#707478" class="fixed-top stp-header">    
    <app-header  class="mainheader" [userEmail]= "userEmail" (trigger)="goHome()"></app-header>    
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">

	<mat-sidenav class="sidebar" mode="side" [(opened)]="opened" (opened)="events.push('open!')" (closed)="events.push('close!')">		
		<mat-nav-list class="nav">
					
			<a  mat-list-item class="nav-header">Navigation</a>
			<a routerLink="/publication" routerLinkActive="active" mat-list-item class="has-sub"><mat-icon>book</mat-icon><span>Publication List</span></a>
			<a  mat-list-item class="has-sub"><mat-icon>build</mat-icon><span>Applicability Tool</span></a>
			<a  mat-list-item class="has-sub"><mat-icon>assignment</mat-icon><span>Request International Protocol</span></a>
			<a  mat-list-item class="has-sub"><mat-icon>phone_iphone</mat-icon><span>STP Pocket Reference</span></a>
            <a  mat-list-item class="has-sub"><mat-icon>chat</mat-icon><span>Tickets</span></a>  
            <a href="javascript:;" mat-list-item class="sidebar-minify-btn sidebar-toggle"><button (click)="toggleSidenav()" mat-icon-button>
    					<mat-icon aria-label="open/close sidebar">swap_horiz</mat-icon>
  					</button></a>
					
			        <!-- end sidebar minify button -->
				</mat-nav-list>
	</mat-sidenav>

	<mat-sidenav-content>		
	    <div id="content" class="content">
    	<!-- begin page-header -->
    		<h1 class="page-header">Specialty Technical Publishers <small>Online Delivery Portal</small></h1>
    	
		</div>
		<stp-footer></stp-footer>   
  	</mat-sidenav-content>	
</mat-sidenav-container>

