import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {
  isLoading$: BehaviorSubject<boolean>; // Subscribe this observable to determin if the page is loading to lock it.
  public loading = false;

  constructor() {
    alertify.set('notifier', 'position', 'top-right');
    alertify.set('notifier', 'delay', 3);
    this.isLoading$ = new BehaviorSubject<boolean>(false);
  }

  confirm(message: string, okCallback: () => any) {
    alertify.confirm().setting({'label': '<em>STP AuditHub</em>', 'message': message, 'onok':function(){alertify.success('Ok')}}).show();
  }

  confirmOkCancel(title: string, message: string, okCallback: () => any, cancelCallback: () => any) {
    alertify.confirm(title, message, () => { okCallback(); }
                , () => { cancelCallback(); });
  }

  showLoadingAnimation(showFlag: boolean){
    this.loading = showFlag;
    this.isLoading$.next(showFlag);
  }

  confirmOk(message: string){
    alertify.alert()
    .setting({
      'label': 'Ok',
      'message': message,
    }).setHeader('<b>STP AuditHub</b>').show();
  }


  addSuccess(message: string) {
    this.success(`Add ${message} successfully`);
  }

  editSuccess(message: string) {
    this.success(`Edit ${message} successfully`);
  }

  deleteSuccess(message: string) {
    this.success(`Delete ${message} successfully`);
  }

  success(message: string) {
    alertify.success(message);
  }

  error(message: string) {
    try{
      if(message === "Internal server error"){
        console.debug("internal error: " + message);
        message = "The system is busy. Please try again later."
      }
      alertify.error(message);
    }catch(Error){
      console.error(Error.message);
    }
  }

  warning(message: string) {
    alertify.notify(message , 'warning', 3, null);
  }

  message(message: string) {
    alertify.message(message);
  }
}