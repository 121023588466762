<div *ngIf="rbList" class="right-block screen">
  <div class="widget-body">
    <div class="widget-toolbox" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button (click)="onBackSummary()">Back to Summary</button>
        <button mat-button (click)="onNextRulebook()">Next Rulebook<mat-icon aria-hidden="true" aria-label="Next">arrow_right_alt</mat-icon></button>
    </div>
    <div class="widget-main">
      <ul *ngFor="let ac of rbList" class="nav-list">
        <ul *ngFor="let tpc of ac.topics" class="sub-menu" fxLayout="row" fxLayoutAlign="start start">
          <li class="sub-menu">
            <span class="heavy">{{ac.acronym}}<mat-icon>chevron_right</mat-icon>{{tpc.topic}}</span>
            <ng-container *ngFor="let rb of tpc.rulebooks">
              <ul *ngIf="rb.checkNa == false" class="sub-item">
                <div fxLayout="row" [ngClass]="{'active': rb.rbKey == this.selected}">
                  <span fxFlex="92" class="text-inside-list"  (click)="onClickRulebook(rb.rbKey, rb.rbName)">{{rb.rbName}}</span>
                  <mat-icon fxFlex="8" *ngIf="rb.rbKey == this.selected" aria-hidden="true" aria-label="Next" class="active">grade</mat-icon>
                </div>                
              </ul>
            </ng-container>
          </li>
        </ul>
      </ul>
    </div>
    <div class="widget-toolbox" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button (click)="onBackSummary()">Back to Summary</button>
        <button mat-button (click)="onNextRulebook()">Next Rulebook<mat-icon aria-hidden="true" aria-label="Next">arrow_right_alt</mat-icon></button>
    </div>
  </div>
</div>