import { Component, OnInit, ChangeDetectorRef, Type, ViewChild,ComponentRef,  ComponentFactoryResolver,   AfterViewInit, Input,  Renderer2, ViewContainerRef, OnChanges,  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';

import { PublicationService } from '.././publication.service';
import { OutlineItem } from './outlineItem';

@Component({
  selector: 'pdf-outline',
  template:  `<div [innerHtml] = "data | safe: 'html'" class='outline1'></div>`
})
export class OutlineComponent implements OnChanges{
  @Input() data: any;
  loading:boolean;
  constructor(private cd: ChangeDetectorRef){ this.loading = false;}
  ngOnChanges(){
    this.loading = true;
  }
}


@Component({
  selector: 'app-show-publication',
  templateUrl: './show-publication.component.html',
  styleUrls: ['./show-publication.component.css']
})
export class ShowPublicationComponent implements OnInit, AfterViewInit {
  pubId: string;
  version: string;
  formatId: string;
  pdfOutline: OutlineItem;
  pdfOnDisplay: string = "notavailable";

  @ViewChild('pdfOutlineContainer', {read: ViewContainerRef}) container;
  componentRef: ComponentRef<any>;

  constructor(
    private route: ActivatedRoute,
    private service: PublicationService,
    private cfr: ComponentFactoryResolver,
    protected http: HttpClient,
    private renderer: Renderer2) { }

  ngAfterViewInit(){
    this.service.getPdfUrl(this.pubId, this.version).subscribe(
      res => {
        for(const item of res){
          if(item.Format == 'pdf'){
            const fp = this.getNavigationHtmlFileName(item.Path);
            this.service.getFileContent(fp).subscribe({
              next: (res: string) => {
                let tmp: string = this.addDropdownIcon(res);
                this.pdfOutline = new OutlineItem(OutlineComponent, tmp);
                this.loadComponent();
              },
              error: (err) => console.error(err),
            }

            )
          }
        }
      }
    )
  }

  // pdfPath: IP/IPBRAF12/IPBRAF-Title.pdf
  getNavigationHtmlFileName(pdfPath: string){
      //console.log("Navigaition file for: " + pdfPath);
      var splitted = pdfPath.split("/");
      var rootPath = this.getProtocolVirtualDirectory();
      return rootPath  +  splitted[0] + "/" + splitted[1] + "/" + splitted[1] + ".html";
  }

  getProtocolVirtualDirectory(): string {
    return environment.PROTOCOL_VIRTUAL_DIRECTORY;
  }

  ngOnInit() {
      // Create event listener to handle clicking on outline title.
      let global = this.renderer.listen('document', 'click', (evt) => {
        evt.preventDefault();

        if(evt.target.localName == 'mat-icon'){ // Clicking on dropdown arrow, toggle the outline items.
          this.toggleSubmenu(evt.target);
        }
        else if(evt.target.localName == 'a'){ // Clicking on outline item, open the targeted pdf.
          this.openPdf(evt.target);
        }else{
          // Ignore other clicking.
        }
      });

      this.route.paramMap.subscribe(
        paraMap => (
          this.pubId = paraMap.get('id'),
          this.version = paraMap.get('version'),
          this.formatId = paraMap.get('format-id'),
          this.queryPdfOnDisplay(this.pubId, this.version)
        )
      );
  }

  queryPdfOnDisplay(pubid: string, version: string){
    this.service.getPdfUrl(pubid, version).subscribe(
        res => {
          for(let val of res){
            if(val.Format == 'pdf'){
              this.pdfOnDisplay =  this.getProtocolVirtualDirectory()  + val.Path;
              let headers = new HttpHeaders();
              headers = headers.set('Accept', 'application/pdf');

              this.http.get(this.pdfOnDisplay, {headers: headers, responseType: 'blob'})
              .subscribe(
                response => {

                },
                err => {
                  console.error("error:" + err.error);
                  this.pdfOnDisplay = "unavailable";
                }
              )
            }
          }
        }
    )
  }

  openPdf(node: any){
    this.pdfOnDisplay = this.getProtocolVirtualDirectory() + node.href.substring(node.baseURI.length + 5);
  }


  toggleSubmenu(node: HTMLElement){
      let submenu = this.renderer.parentNode(this.renderer.parentNode(this.renderer.parentNode(node))).querySelector('ul');
      let submenuAttr = submenu.getAttribute('class');

      if(submenuAttr == 'submenu'){
        node.innerHTML = "keyboard_arrow_right";
        this.renderer.removeClass(submenu, 'submenu');
        this.renderer.addClass(submenu, 'showmenu');
      }else{
        node.innerHTML =  "keyboard_arrow_down";
        this.renderer.removeClass(submenu, 'showmenu');
        this.renderer.addClass(submenu, 'submenu');
      }
  }


  loadComponent(){
    this.container.clear();
    const componentFactory = this.cfr.resolveComponentFactory(OutlineComponent);
    this.componentRef = this.container.createComponent(componentFactory);
    (<OutlineComponent>this.componentRef.instance).data = this.pdfOutline.data;
    this.componentRef.changeDetectorRef.detectChanges();
  }


  // For topics in the HTML text which have a submenu, add a dropdown icon.
  addDropdownIcon(content:string): string {
    var re = /<b><\/b><\/a>\r\n\s*<ul\sclass=\'submenu\'>/g;
    return content.replace(re, "<b class=\"floatRight\"><mat-icon _ngcontent-c11 class=\"mat-icon notranslate material-icons mat-icon-no-color\" role=\"img\" aria-hidden=\"true\">keyboard_arrow_down</mat-icon><\/b><\/a>\n\r<ul class=\'submenu\'>");
  }
}