<div class="close-div">
  <button mat-button><mat-icon (click) = onCancel()>close</mat-icon></button>
</div>
<h2 mat-dialog-title>Choose Topics</h2>
<mat-dialog-content>
  <div class="tab-container">
    <div class = "w3-bar w3-blac">
      <button class="w3-bar-item lButton tablink w3-red" [ngClass] = "onDisplay === 1 ? 'ondisplay': ''" (click)="loadTree(1)">Env Topic</button>
      <button class="w3-bar-item rButton tablink w3-red" [ngClass] = "onDisplay === 2 ? 'ondisplay': ''" (click)="loadTree(2)">OSHA Topic</button>
    </div>

    <div id="env-tree" style="overflow-y: scroll; height:400px;">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="evenly">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-checkbox class="checklist-leaf-node"
                          [checked]="checklistSelection.isSelected(node)"
                          (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
          </mat-tree-node>

          <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.item">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <mat-checkbox [checked]="descendantsAllSelected(node)"
                          [indeterminate]="descendantsPartiallySelected(node)"
                          (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
          </mat-tree-node>
        </mat-tree>
    </div>
  </div>

<div mat-dialog-actions class="action-button">
  <button mat-button (click)="onConfirm()" class="button-close" cdkFocusInitial>Confirm</button>
  <button mat-button (click)="onCancel()" mat-dialog-close class="button-close">Cancel</button>
</div>
</mat-dialog-content>