import { Component, OnInit} from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AlertifyService } from '../../_models/alertify.service';

import {TemplateService} from '../service/template.service';
import { AppSect, TemplateContent} from '../model/TemplateRulebook';

@Component({
  selector: 'app-edit-applicability',
  templateUrl: './edit-applicability.component.html',
  styleUrls: ['./edit-applicability.component.css']
})
export class EditApplicabilityComponent implements OnInit {
  csskey: string;
  auditName: string;
  appkey: string;
  appSect$: Observable<AppSect[]>;
  selectedRbName: string;
  selectedAppkey: number;
  rulebookList: TemplateContent[];
  currentTopic: string;
  isAnswering: boolean;
  sharePermission: boolean; //

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertifyService,
    public service: TemplateService
    ) { }

  ngOnInit() {
    this.selectedRbName = this.service.decodeHtml(this.service.getRbName().value);
    this.isAnswering = false;
    this.service.getCurrent().subscribe(res =>{
      //let item = res.findIndex(x => x.acronym == "Custom"); // Do not display custom question on applicablity page.

      this.rulebookList = res;

    });

    this.auditName = this.service.getAuditName();

    this.route.paramMap.subscribe(
      paraMap => (
        this.csskey = paraMap.get('id'),
        this.appkey = paraMap.get('appkey'),
        this.sharePermission = this.service.isTemplateEditable(+this.csskey)
      )
    );
    this.currentTopic = this.service.getTopic(parseInt(this.appkey));
    this.loadApplicability(this.csskey, this.appkey);
  }




  loadApplicability(csskey: string, appkey: string){
    try{
      this.service.showLoadingAnimation(true);
      this.appSect$ = new Observable<AppSect[]>();
      this.appSect$ = this.service.getApplicability(csskey, appkey);
      let re = /<b>Question:<\/b>/gi;

      this.appSect$.subscribe(
        res => {
          this.service.showLoadingAnimation(false);
          res.forEach(element => {
            element.question = this.service.decodeHtml(element.question).replace(re, "");//decode html entities; remove <b>Question:</b> tag.
            element.exemption = this.service.decodeHtml(element.exemption);
          });
          this.service.revokeReviewFlag(+csskey, +appkey, 1);
        },
        err => {
          console.error("got an error:" + err);
          this.service.showLoadingAnimation(false);
          this.alertService.error(err.message);
        },
        () => this.service.showLoadingAnimation(false)
      );
    }catch(Error){
      this.alertService.error(Error.message);
    }
  }


  onClickRulebook(appkey: number){
    //console.log("received click event from child: " + appkey);
    this.selectedRbName = this.service.getRbName().value;
    this.appkey = this.service.getKey().value.toString();
    this.currentTopic = this.service.getTopic(parseInt(this.appkey));

    this.loadApplicability(this.csskey, appkey.toString());
  }

/**
 * Answer the applicability questions.
 * @param answer
 */
  onAnswerApp(answer: string){
    this.service.showLoadingAnimation(true);
    this.service.updateAppAnswer(this.csskey, +this.appkey, answer)
    .subscribe(
      val => {
       // to update rulebookList
       this.isAnswering = false;
       this.selectedRbName = this.selectedRbName + '';
       this.rulebookList.forEach(arcon =>{
         arcon.topics.forEach(tp =>{
           tp.rulebooks.forEach(rb =>{
             if(rb.appKey == val.appkey){
               rb.percentage = this.service.calRulebookPercentage(answer);
               rb.checkNa = this.service.calCheckNa(answer);
               rb.appAnswer = answer;
             }
           })
         })
       });
       // Update the cache
       this.service.cacheRulebookLocalStorage(parseInt(this.csskey), this.rulebookList);
       //sessionStorage.setItem('template', JSON.stringify(this.rulebookList));
      },
      error => {
        console.error("onAnswerApp() return: " + error.error);
      },
      ( )=> {
        console.log("onAnswerApp() comlete.");
        this.service.showLoadingAnimation(false);
      }
    );
  }
}