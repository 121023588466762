import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import {CustomTemplate} from '../../model/CustomQuestion';

@Component({
  selector: 'app-list-scoring',
  templateUrl: './list-scoring.component.html',
  styleUrls: ['./list-scoring.component.css']
})
export class ListScoringComponent implements OnInit {
  @Input() templateList: CustomTemplate[];
  @Input() currentItem: number;
  @Output() selectedItem = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    //this.selectedOne = this.scoringList[0].key;
  }

  // Scoring item in the list is clicked: show the content of the choosen item.
  onClickTemplate(templateId: number){
    this.selectedItem.emit(templateId);
  }
}