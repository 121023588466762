import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { AlertifyService } from '../_models/alertify.service';
import { OAuthSettings } from '../oauth/oauth';
import { User } from '../_models/account';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

@Injectable({
  providedIn: 'root'
})

export class AzureAuthService {
  public authenticated: boolean;
  public user?: User;
  public graphClient?: Client;

  constructor(
    private msalService: MsalService,
    private alertsService: AlertifyService) {

      const accounts = this.msalService.instance.getAllAccounts();
      this.authenticated = accounts.length > 0;
      if (this.authenticated) {
        this.msalService.instance.setActiveAccount(accounts[0]);
      }
      this.getUser().then((user) => {this.user = user});
  }

  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  async signIn(): Promise<void> {
    const result = await this.msalService
      .loginPopup(OAuthSettings)
      .toPromise()
      .catch((reason) => {
        console.error("login failed: " + reason);
        if(reason && reason.toString().indexOf("cancelled") !== -1){
          this.alertsService.error('Login failed: ' + reason);
        }
        return;
      });
    console.debug("login result: " + result);

    if (result) {
      this.msalService.instance.setActiveAccount(result.account);
      this.authenticated = true;
      // Temporary placeholder
      this.user = await this.getUser();

      // Temporary to display token in an error box
      //this.alertsService.addSuccess('Token acquired: '+ result.accessToken);
    }
  }

  // Sign out
  async signOut(): Promise<void> {
    await this.msalService.logout().toPromise();
    this.user = undefined;
    this.authenticated = false;
  }

  private async getUser(): Promise<User | undefined> {
    if (!this.authenticated) return undefined;

    // Create an authentication provider for the current user
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.msalService.instance as PublicClientApplication,
      {
        account: this.msalService.instance.getActiveAccount()!,
        scopes: OAuthSettings.scopes,
        interactionType: InteractionType.Popup
      }
    );

    // Initialize the Graph client
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider
    });

    // Get the user from Graph (GET /me)
    const graphUser: MicrosoftGraph.User = await this.graphClient
      .api('/me')
      .select('displayName,mail,mailboxSettings,userPrincipalName')
      .get();

    const user = new User();
    user.displayName = graphUser.displayName || '';
    // Prefer the mail property, but fall back to userPrincipalName
    user.email = graphUser.mail || graphUser.userPrincipalName || '';
    user.timeZone = graphUser.mailboxSettings && graphUser.mailboxSettings.timeZone || 'UTC';

    // Use default avatar
    user.avatar = '/assets/no-profile-photo.png';

    return user;
  }
}