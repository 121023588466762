<div class="spinner-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<div class="page-content">
	<header class="w3-containter">
		<h5 class="w3-text-purple"><b><mat-icon>dashboard</mat-icon> MY DASHBOARD</b></h5>
	</header>
	<div class="dashboard-links">
		<a routerLink="/audithub/publication" mat-list-item class="w3-quarter-1"
		[disabled] = "account.userType.charAt(2) === '0'" [ngClass] = "account.userType.charAt(2) === '0' ? 'disabledlink' : ''">
			<div>
				<div class="w3-container w3-purple w3-padding-16">
				<div class="w3-left">
					<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/Toc-Icon-1.png" class="dashboard-img"/>
				</div>
				<h4>PRODUCT LIST</h4>
				</div>
			</div>
		</a>
		<a *ngIf="regComparePermission" routerLink = "/audithub/regcompare" target="_blank"
			mat-list-item class="has-sub w3-quarter-2"
			matTooltip="To use the existing data to provide a way for users to select jurisdictions, topics and subtopics and get a crosstab view of how/whether the selected states differ from the federal requirements in each subtopic."
			matTooltipClass="tooltip2"
		>
			<div>
				<div class="w3-container w3-purple w3-padding-16">
				<div class="w3-left">
					<img src="{{AuditHubDomain}}/assets/wp-content/uploads/RegCompare-Icon.png" class="dashboard-img"/>
				</div>
				<h4>REGCOMPARE</h4>
				</div>
			</div>
		</a>
		<div *ngIf="!regComparePermission"
			(click)="regCompareDeny()"
			mat-list-item class="has-sub w3-quarter-2"
			matTooltip="To use the existing data to provide a way for users to select jurisdictions, topics and subtopics and get a crosstab view of how/whether the selected states differ from the federal requirements in each subtopic."
			matTooltipClass="tooltip2"
		>
			<div>
				<div class="w3-container w3-purple w3-padding-16">
				<div class="w3-left">
					<img src="{{AuditHubDomain}}/assets/wp-content/uploads/RegCompare-Icon.png" class="dashboard-img"/>
				</div>
				<h4>
					REG COMPARE
				</h4>
				</div>
			</div>
		</div>


		<a routerLink="/audithub/apptool/list"
			mat-list-item class="has-sub w3-quarter-1"
			[disabled] = "account.userType.charAt(1) === '0'" [ngClass] = "account.userType.charAt(1) === '0' ? 'disabledlink' : ''">
			<div >
				<div class="w3-container w3-purple w3-padding-16">
				<div class="w3-left">
					<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/RegApply-Icon.png" class="dashboard-img"/>
				</div>
				<h4>REGAPPLY</h4>
				</div>
			</div>
		</a>

		<a routerLink="/audithub/pocketref/" mat-list-item class="w3-quarter-2"
		[disabled] = "account.userType.charAt(2) === '0'" [ngClass] = "account.userType.charAt(2) === '0' ? 'disabledlink' : ''">
			<div>
				<div class="w3-container w3-purple w3-padding-16">
				<div class="w3-left">
					<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/Pocket-Reference-Icon.png" class="dashboard-img"/>
				</div>
				<h4>POCKET REFERENCE</h4>
				</div>
			</div>
		</a>

	</div>
	<div class="panel">
		<div class="half" id="halfwidth">
			<h5 class="text-orange">JURISDICTIONS</h5>
        	<dx-vector-map
				id="vector-map" #vectorMap
				[bounds]="[-180, 85, 180, -60]"
				(onClick)="onMapClick($event)">
				<dxo-tooltip
        			[enabled]="true"
        			[customizeTooltip]="customizeTooltip"  [zIndex]="5000"></dxo-tooltip>
				<dxo-size [height]="460" [width]="this.mapwidth"></dxo-size>
				<dxi-layer name="areas"
					[dataSource]="worldMap"
					[customize]="customizeLayers">
				</dxi-layer>>
				<dxi-legend [visible]="true" title="European Union Updates Available Yearly" horizontalAlignment="right" verticalAlignment="top">
				</dxi-legend>
			</dx-vector-map>
		</div>
		<div class="half at-front">
			<h5 class="text-orange">LATEST TEMPLATES</h5>
			<table class="latest-template w3-table w3-striped w3-white  w3-bordered w3-border w3-hoverable ">
				<tr class="home-page">
					<th>Audit Name</th>
					<th>Building</th>
					<th>Download</th>
				</tr>

				<tr *ngFor="let pub of latestAudit" class="home-page">
					<td class="audit-name"
						(click)="onAuditClick(pub.csskey, pub.auditName, pub.status, pub.facilityLocation, pub.startDate, pub.endDate, pub.monitoring)"
						[ngClass] ="{'upgrading': pub.status == 'upgrading','upgraded': pub.status == 'upgraded'}"
						[matTooltip] = "pub.status == 'upgrading' ? 'Template is updating' : null">{{pub.auditName}}
					</td>
					<td class="highlight" [ngClass] ="{'upgrading': pub.status == 'upgrading','upgraded': pub.status == 'upgraded'}">
						<ng-container *ngIf="pub.inBuilding == 1 || pub.inBuilding == 0">
							<mat-icon class="info" matTooltip="Building" matTooltipClass="tooltip1">hourglass_empty</mat-icon>
						</ng-container>
						<ng-container *ngIf="pub.inBuilding == 3">
							<mat-icon class="error" matTooltip="Stopped" matTooltipClass="tooltip1">warning</mat-icon>
						</ng-container>
						<ng-container *ngIf="pub.inBuilding == 2">
							<mat-icon class="success" matTooltip="Completed" matTooltipClass="tooltip1">check</mat-icon>
						</ng-container>
					</td>
					<td><button mat-button class="button-action download-icon"
							(click)="onDownloadTemplate(pub.csskey, pub.auditName)"
							[matTooltip]="pub.downloadable == 1 ? 'Xcelerator' : 'RegRegister'"
							[disabled]="pub.downloadable == -1 || pub.inBuilding != 2"
							matTooltipClass="tooltip1">
							<mat-icon fontSet="material-icons-outlined" class="byturn">download</mat-icon>
						</button>
					</td>
				</tr>

			</table>
		</div>
	</div>

	<div class="panel">
		<div class="half">
			<h5 class="text-orange">
				<span>LATEST UPDATES</span>
				<span>RELEASE NOTE</span>
			</h5>
			<table class="w3-table w3-striped w3-white    w3-bordered w3-border w3-hoverable ">
				<ng-container *ngFor="let pub of latestPub">
					<tr class="home-page">
						<td>{{pub.title}}</td>
						<td>
							<ng-container *ngIf="(pub.category == 'IP' || pub.category == 'CAN-MEX')">
								<a *ngIf = "!['ACT','NT','NS','ISR','QLD','UAE'].includes(pub.acronym) && (pub.title.indexOf('Local') === -1)"
						href="publications\IP\IP{{pub.acronym.indexOf('|') !== -1 ? pub.acronym.substring(0, pub.acronym.indexOf('|')) :pub.acronym}}{{pub.version.toString().substring(2)}}\IP{{pub.acronym.indexOf('|') !== -1 ? pub.acronym.substring(0, pub.acronym.indexOf('|')) :pub.acronym}}{{pub.version.toString().substring(2)}}-RN-Marketing.pdf" target="_blank">View</a>
							</ng-container>
							<ng-container *ngIf="pub.category != 'IP' && pub.category != 'CAN-MEX'">
								<a *ngIf="!['ERMM','OSS','SDEA-CA-Sum','SDOA-CA-Sum'].includes(pub.acronym)"
								class="viewRelease"
								href="publications\{{pub.category.toUpperCase()}}\{{pub.acronym}}\{{pub.acronym}}-RN-Marketing.pdf" target="_blank">View
								</a>
							</ng-container>

						</td>
					</tr>
				</ng-container>

			</table>
			<!--button class="w3-button w3-orange w3-text-white">More Updates <mat-icon>forward</mat-icon></button-->
		</div>
		<div class="half">
			<h5 class="text-orange">UPCOMING UPDATES</h5>
			<table class="w3-table w3-striped w3-white    w3-bordered w3-border w3-hoverable ">
				<ng-container *ngFor="let up of upcomingProducts">
					<tr class="home-page">
						<td>{{up}}</td>
					</tr>
				</ng-container>
			</table>
		</div>
	</div>

	<div class="w3-container w3-lightgrey dashboard-links">
		<div class="w3-container w3-third">
			<h5 class="w3-bottombar text-orange w3-border-orange">
				<a href="{{StpDomain}}" target="_blank">STP HOME</a>
			</h5>
			<a href="{{StpDomain}}/about" target="_blank">About STP</a>
			<a href="{{StpDomain}}/#latestblogs" target="_blank">Recent blogs</a>
			<a href="https://share.hsforms.com/1sisTbQ2oR7idfYVsz2MvgA2s4w" target="_blank">Newsletters</a>
		</div>
		<div class="w3-container w3-third">
			<h5 class="w3-bottombar text-purple w3-border-purple">
				<a href="{{RegHubDomain}}/dashboard" target="_blank">STP RegHub</a>
			</h5>
			<a href="{{StpDomain}}/products/#reghub" target="_blank">About STP RegHub</a>
		</div>
		<div class="w3-container w3-third">
			<h5 class="w3-bottombar text-orange w3-border-orange">
				<a href="{{AuditHubDomain}}/dashboard" target="_blank">STP AuditHub</a>
			</h5>
			<a href="https://elibrary.stpehs.com/videos/" target="_blank">Training Videos</a>
			<a href="{{StpDomain}}/products/#audithub/" target="_blank">About STP AuditHub</a>
		</div>
	</div>
</div>
<div class="spinner-container" *ngIf="this.tpService.loading">
    <mat-spinner></mat-spinner>
</div>