<div id="header" class="header1 navbar-default" fxLayout="row" fxLayoutAlign="space-between center">
	<h2 class="page-title" >
		<mat-icon (click)="openMenu('1')" class="hamburger-menu">menu</mat-icon>&nbsp;&nbsp;{{title}}
		<mat-icon *ngIf="title == 'Product List' || title == 'New Template'
				|| title == 'Pocket Reference' || title == 'Custom Questions' || title == 'Custom Ranking & Scoring'"
				class="help-info" (click)="openHelpInfo($event, title)">info</mat-icon></h2>
    <ul class="header-profile">
				<div class="dropdown navbar-user">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown">
						{{userEmail}}<span class="user-profile">
						<img src="/assets/images/User-icon.png" alt="" style="margin-right: 20px;"></span> <b class="caret"></b>
					</a>
					<div class="dropdown-menu dropdown-menu-right">
                        <!--a routerLink="/home" class="dropdown-item">Search</a>
                        <a routerLink="/home" class="dropdown-item">Settings</a-->
                        <div class="dropdown-divider"></div>
						<a (click)="clearCache()" class="dropdown-item">Clear Cache</a>
						<a (click)="aboutAudit($event)" class="dropdown-item">About AuditHub</a>
                        <a (click)="signOut()" class="dropdown-item">Sign Out</a>
					</div>
				</div>
	</ul>
</div>