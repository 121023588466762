import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {HttpClient, HttpHeaders, HttpBackend} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ActivatedRouteSnapshot} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { ApiService } from '@stp/core/api.service';

// interface UserProfileSettings {
//   UserID: string;
//   CompanyID: string;
//   RegHubIntlPermissions: string;
//   AuditHubPermissions: string;
//   Timeout: string;
//   TimeoutWarning: string;
//   LogoutURL: string;
export enum UserProfileSettings {
  userID = 'userID',
  companyID = 'companyID',
  regHubIntlPermissions = 'regHubIntlPermissions',
  auditHubPermissions = 'auditHubPermissions',
  timeout = 'timeout',
  timeoutWarning = 'timeoutWarning',
  logoutURL = 'logoutURL'
}
interface IUserProfileSettings {
  [UserProfileSettings.userID]: string;
  [UserProfileSettings.companyID]: string;
  [UserProfileSettings.regHubIntlPermissions]: string;
  [UserProfileSettings.auditHubPermissions]: string;
  [UserProfileSettings.timeout]: string;
  [UserProfileSettings.timeoutWarning]: string;
  [UserProfileSettings.logoutURL]: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserProfileApiService {

  static userProfileSettings: unknown;

  constructor(private httpClient: HttpClient, private cookieService: CookieService, private apiService: ApiService) {
  }

  public initializeUserProfileApi(route, callback) {
		const companySettings = this.getDecodedAccessToken(route);
		if (companySettings == null) {
		  return;
		}

		const companySessionConfig = this.getCompanySessionConfig(companySettings,
		  (userProfileSettings) => {
			  if (callback !== null) {
			    callback(userProfileSettings);
			  }
      },
		  (err) => {
			  return of(false);
		  });
		// subscribe is necessary for the http request (for profile api) to get called
		if (companySessionConfig != null) {
      companySessionConfig.subscribe(config => {
      });
    }
	}

  public getCompanySessionConfig(companySettings, successCallback, errorCallback): Observable<unknown> {
    const route = 'account/profile/' + companySettings.UserGuid;
    // const profileApiKey = environment.profileApiKey;
    // const headers = new HttpHeaders(
    //   {'Ocp-Apim-Subscription-Key': `${profileApiKey}`}
    // );
    // const options = {headers};
    // Remove unnecessary calls to the Profile API
    if (this.hasAllSessionStorageValues()) {
      const result: IUserProfileSettings = {
        userID: sessionStorage.getItem(UserProfileSettings.userID),
        companyID: sessionStorage.getItem(UserProfileSettings.companyID),
        regHubIntlPermissions: sessionStorage.getItem(UserProfileSettings.regHubIntlPermissions),
        auditHubPermissions: sessionStorage.getItem(UserProfileSettings.auditHubPermissions),
        timeout: sessionStorage.getItem(UserProfileSettings.timeout),
        timeoutWarning: sessionStorage.getItem(UserProfileSettings.timeoutWarning),
        logoutURL: sessionStorage.getItem(UserProfileSettings.logoutURL)
      };

      successCallback(result);
      return null;
    }

    return this.httpClient.get<any>(environment.endpoint + route).pipe(
      map(apiResponse => {
        try {
          const userProfileSettings: IUserProfileSettings = {
            userID: apiResponse.userID,
            companyID: apiResponse.companyId,
            regHubIntlPermissions: apiResponse.regHubIntlPermissions,
            auditHubPermissions: apiResponse.auditHubPermissions,
            timeout: apiResponse.timeout,
            timeoutWarning: apiResponse.timeoutWarning,
            logoutURL: apiResponse.logoutURL
        };
        UserProfileApiService.userProfileSettings = userProfileSettings;

        sessionStorage.setItem(UserProfileSettings.userID, userProfileSettings.userID);
        sessionStorage.setItem(UserProfileSettings.companyID, userProfileSettings.companyID);
        sessionStorage.setItem(UserProfileSettings.regHubIntlPermissions, userProfileSettings.regHubIntlPermissions);
        sessionStorage.setItem(UserProfileSettings.auditHubPermissions, userProfileSettings.auditHubPermissions);
        sessionStorage.setItem(UserProfileSettings.timeout, userProfileSettings.timeout);
        sessionStorage.setItem(UserProfileSettings.timeoutWarning, userProfileSettings.timeoutWarning);
        sessionStorage.setItem(UserProfileSettings.logoutURL, userProfileSettings.logoutURL);

        successCallback(userProfileSettings);
      } catch (err) {
        return errorCallback(err);
      }
    })
    );
    //return null;
  }

  public getDecodedAccessToken(route: ActivatedRouteSnapshot): any {
    const token =
      this.cookieService.get(environment.token) ||
      new URLSearchParams(route.fragment).get('id_token');

    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  public static clearSessionStorage() {
    sessionStorage.removeItem(UserProfileSettings.userID);
    sessionStorage.removeItem(UserProfileSettings.companyID);
    sessionStorage.removeItem(UserProfileSettings.regHubIntlPermissions);
    sessionStorage.removeItem(UserProfileSettings.auditHubPermissions);
    sessionStorage.removeItem(UserProfileSettings.timeout);
    sessionStorage.removeItem(UserProfileSettings.timeoutWarning);
    sessionStorage.removeItem(UserProfileSettings.logoutURL);
  }

  private hasAllSessionStorageValues() {
    return sessionStorage.hasOwnProperty(UserProfileSettings.userID) &&
      sessionStorage.hasOwnProperty(UserProfileSettings.companyID) &&
      sessionStorage.hasOwnProperty(UserProfileSettings.regHubIntlPermissions) &&
      sessionStorage.hasOwnProperty(UserProfileSettings.auditHubPermissions) &&
      sessionStorage.hasOwnProperty(UserProfileSettings.timeout) &&
      sessionStorage.hasOwnProperty(UserProfileSettings.timeoutWarning) &&
      sessionStorage.hasOwnProperty(UserProfileSettings.logoutURL);
  }

}
