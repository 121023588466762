export class ApiResponse {
  public isSuccess: boolean;
  public messages: ApiResponseMessages[];
}

export class ApiResponseMessages {
  public key: string;
  public value: string;
}


