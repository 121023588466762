import { Component, OnInit, Inject, ElementRef, AfterViewInit } from '@angular/core';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import { AlertifyService } from '../../_models/alertify.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import {CustomTopic, CustomContent, CustomTopicWithTitle} from '../model/CustomQuestion';
import { CustomQuestionService } from '../service/custom-question.service';

@Component({
  selector: 'app-custom-questions',
  templateUrl: './custom-questions.component.html',
  styleUrls: ['./custom-questions.component.css']
})
export class CustomQuestionsComponent implements OnInit {
  customTopic: CustomTopicWithTitle[]; // All the custom questions for this client.
  selectedTopic: CustomTopic; // The current custom topic displaying.
  userId: number;

  constructor(
    public service: CustomQuestionService,
    public alertifyService: AlertifyService,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit() {
    this.service.setTopicChangeFlag(false);
    this.userId = JSON.parse(localStorage.getItem('account')).id;
    this.alertifyService.showLoadingAnimation(true);

    // Call backend to get the custom questions for this client.
    this.service.getCustomList(this.userId).subscribe(
      res => {
        this.alertifyService.showLoadingAnimation(false);
        this.customTopic = res;
        this.selectedTopic = this.customTopic[0].topics[0]; // By default, display the first topic.
      },
      error =>{
        this.alertifyService.showLoadingAnimation(false);
        console.error("look: " + error.message);
        if(error.status == 404){
          this.alertifyService.success("No custom topics found.");
        }else{
          this.alertifyService.error(error.message);
        }
      }
    );
  }

  // Button 'new custom topic' clicked.
  newTopic(){
    // Open dialog for user to input new topic.
    const dialogRef = this.dialog.open(NewTopicDialog, {
      position: {top: '200px'},
      width: '800px',
      data: this.customTopic
    });

    // Save the topic collecting from dialog.
    dialogRef.afterClosed().subscribe(result => {
      let data: CustomTopic = {
        title: result.topic,
        topic: result.subtopic,
        topicID: '0',
        userID: this.userId,
        userName:'',
        locktoUser: true,
        contents: null
      };
      this.service.saveCustomTopic(data)
      .subscribe(
        rep => {
          console.log("backend return: " + rep);
          var nc: CustomContent[] = [{
            contentKey: 0,
            questionNum: 1,
            question: "",
            guidenote: "",
            citation: "",
            citationLink: ""
          }];

          data.topicID = rep.topicId;
          data.contents = nc;
          let ct: CustomTopicWithTitle ={
            title: data.title,
            topics: [data]
          }
          if(this.customTopic !== undefined){
            this.customTopic.push(ct);
          }else{
            this.customTopic = [ct];
          }

          this.selectedTopic = data;

        },
        error => {
          console.error(error.message);
         //this.alertifyService.error(error.error);
        }
      );
    });
  }

  // An topic is clicked, get the contents of this topic.
  onClickCustomTopic(topicID: string){
    // To check if the topic contents have been retrived.
    let item =  this.customTopic.find(ct => ct.topics.some( x => {return x.topicID === topicID;}));
    if(!item) return false;
    let itemTopic = item.topics.find(x => x.topicID === topicID);

    // Before displaying another topic, check if there is an unsaved new topic and delete it if yes.
    this.dropNewSubtopic(this.selectedTopic.topicID); // the parameter is the previous selected topic.


    this.selectedTopic = itemTopic;
  }

  // topic share status changed, call backend to save it.
  onUpdateCustomShare(ct: CustomTopic){
    this.service.updateCustomTopicShare(ct).subscribe(
      res => {
        console.log("backend return: " + res);
      },
      error =>{
        this.alertifyService.error(error.error);

      }
    )

  }

  // Add a new subtopic button clicked.
  onNewSubtopic(){
    console.log("parent gets requestion: new topic.");
    let item = this.customTopic.find(ct => ct.topics.some(x => {return x.topicID === this.selectedTopic.topicID;}));

    var nc: CustomContent[] = [{
      contentKey: 0,
      questionNum: 1,
      question: "",
      guidenote: "",
      citation: "",
      citationLink: ""
    }];
    let newTopic: CustomTopic = {
      title: item.title,
      topic: '',
      topicID: '0',
      userID: this.userId,
      userName: '',
      locktoUser: true,
      contents: nc
    };
    item.topics.push(newTopic);
    let itemTopic = item.topics.find(x => x.topicID === newTopic.topicID);
    this.selectedTopic = itemTopic;
  }

  // Drop an unsaved new subtopic
  dropNewSubtopic(topicID: string){
    let item = this.customTopic.find(ct => ct.topics.some(x => {return x.topicID === topicID;}));
    if(item){
      const index = item.topics.findIndex(x => x.topicID === '0');
      if(index > -1){
        item.topics.splice(index, 1);
      }
    }
  }

  // Delete subtopic
  onDeleteSubtopic(contentKey: number){
    //console.log("parent get request: delete subtopic: " + this.selectedTopic.topicID + "/" + contentKey);

    this.service.deleteCustomContent(contentKey)
      .subscribe(
        value => {
          console.log("delete custom content return sucesss");
          let idx = this.selectedTopic.contents.findIndex(x => x.contentKey === contentKey);
          if(idx > -1){
            this.selectedTopic.contents.splice(idx, 1);
            this.alertifyService.success("Deleted.");
          }
        },
        error => {
          console.error(error.message);
          this.alertifyService.error(error.error);
        }
      );
  }

  backToHome(){
    this.router.navigateByUrl("/audithub/apptool/list");
  }

  // Delete topic
  onDeleteTopic(topicID: string){
    this.service.setTopicChangeFlag(false);
    if(topicID == '0'){ // new topic which has not been saved to database.
      let item = this.customTopic.find(ct => ct.topics.some(x => {return x.topicID === this.selectedTopic.topicID;}));
      let itemTopic = item.topics.findIndex(x => x.topicID === topicID);
      item.topics.splice(itemTopic, 1);
      this.selectedTopic = this.customTopic[0].topics[0]; // set the first topic as the current topic.
      this.alertifyService.success("Topic deleted.")
    }else{ // Call backend to delete the topic.
      this.service.deleteCustomTopic(topicID)
      .subscribe(
        value => {
          let item = this.customTopic.find(ct => ct.topics.some(x => {return x.topicID === this.selectedTopic.topicID;}));
          let itemTopic = item.topics.findIndex(x => x.topicID === topicID);

          if(item.topics.length == 1){
            let idx = this.customTopic.findIndex(ct => ct.topics.some(x => {return x.topicID === this.selectedTopic.topicID;}))
            this.customTopic.splice(idx, 1);
          }else{
            item.topics.splice(itemTopic, 1);
          }
          this.selectedTopic = this.customTopic[0].topics[0]; // set the first topic as the current topic.
          this.alertifyService.success("Topic deleted.")
        },
        error => {
          console.error(error.message);
          this.alertifyService.error(error.error);
        }
      );
    }
  }

  // Save custom topic.
  onSaveCustomTopic(topic: CustomTopic){
    this.service.saveCustomTopic(topic)
      .subscribe(
        rep => {
          this.selectedTopic.topicID = rep.topicId;
          this.service.getCustomTopic(rep.topicId).subscribe(
            res => {
              //let item = this.customTopic.find(ct => ct.topics.some(x => {return x.topicID === res.;}));
              this.selectedTopic.contents = res;
              this.alertifyService.success("Saved.");
            }
          );
        },
        error => {
          console.error(error.message);
          this.alertifyService.error(error.error);
        }
      );
  }
}


@Component({
  selector: 'new-title',
  templateUrl: './new-custom-title.html',
  styleUrls: ['./custom-questions.component.css']
})
export class NewTopicDialog implements AfterViewInit {
  form: FormGroup;

  constructor(
    private el: ElementRef,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CustomTopicWithTitle[],
    public dialogRef: MatDialogRef<NewTopicDialog>) {
      this.form = this.fb.group({
        topic: ["", Validators.required],
        subtopic: new FormControl()
      });
    }

  ngAfterViewInit(){
    let topic = document.getElementById('topicInput');
    topic.focus();
  }

  close(): void {
    this.dialogRef.close();
  }

  validateTopic(){
   let topic =  this.form.controls['topic'].value;
    if(!topic){
      return "Please input topic before saving.";
    }
    let subtopic = this.form.controls['subtopic'].value;
    if(!subtopic){
      return "Please input subtopic before saving.";
    }
    if(this.data !== undefined){
      if(this.findTitle(topic)){
        return "Topic has existed. Please change another name.";
      }
    }

    return "0";
  }

  findTitle(title: string):boolean{
    if(this.data.find(x => x.title == title) === undefined)
      return false;
    return true;
  }

  // To save the new custom topic.
  onNewCustomTopic(){
    var msg = this.validateTopic();
    if(msg === "0")
      this.dialogRef.close(this.form.value);
    else
      alert(msg);
  }
}