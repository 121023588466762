import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import { MatIconModule} from '@angular/material/icon';
import { HeaderComponent } from '../../_components/header/header.component';
import { Account } from '../../_models';
import {RouteConfigs, localStorageKeys } from '../../_constants';
import  { AuthService} from '../../auth/auth.service';


@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit {
events: string[] = [];
  protected routeConfigs = RouteConfigs;
  opened: boolean = true;
  account: Account;

  shouldRun = true;
  userEmail: string;

  constructor(
    private authService: AuthService,
    private router: Router
    ) { }

  ngOnInit() {
    this.account = AuthService.currentUser;
    this.userEmail = this.account.email;
  }

  ngOnDestroy(): void {
     //localStorage.removeItem(localStorageKeys.authToken);
     this.authService.signOut();
  }

  goPublications() {
    //this.sidenav.close();
    this.router.navigateByUrl("audithub/publication");
  }

  toggleSidenav() {
    this.opened = !this.opened;
  }

  goHome(){

  }

}
