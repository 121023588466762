<h2 mat-dialog-title>Please click the link to download</h2>
<div mat-dialog-content>
  <table class="download-dialog">
    <tr>
      <th>No.</th>
      <th>Date</th>
      <th>Type</th>
      <th>Jurisdiction</th>
      <th>Download</th>
    </tr>
    <tr *ngFor="let item of filteredData; let i =index" class="mat-row">
      <td>{{i  + 1}}</td>
      <td>{{item.fdate}}</td>
      <td>{{item.ftype}}</td>
      <td>{{item.jurisdiction}}</td>
      <td>
        <a href="xcelerator-builds/{{item.fname}}" download="{{item.saveAs}}" [matTooltip]="item.saveAs"><mat-icon>download</mat-icon></a>
      </td>
    </tr>
  </table>

</div>
<div mat-dialog-actions class="right-end">
  <button mat-button (click)="close()" class="mat-raised-button" cdkFocusInitial>Close</button>
</div>
