import {Observable, BehaviorSubject} from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';

import { PublicationService } from './publication.service';
import { PublicationModel } from './publication';
import { PublicationTmp, PublicationDetail} from './publication-table';
import { AlertifyService } from  '../_models/alertify.service';
import { ɵCurrencyIndex } from '@angular/core';
import { stringify } from 'querystring';


export class PublicationDataSource implements DataSource<PublicationTmp>{
  private publicationsSubject = new BehaviorSubject<PublicationTmp[]>([]);
  private resultNumber = new BehaviorSubject<number>(0);
  public categories = new BehaviorSubject<string[]>([]);
  public loading = false;
  private publicationCache: PublicationModel[]; // cache for the whole publications;
  private currentCache: PublicationModel[]; // cache for the current publication result - filter or search.

  constructor( private service: PublicationService,
    private alertifyService: AlertifyService) {}

  loadPublications(id: number, category: string, pageIndex: number, pageSize: number) {
    if(this.resultNumber.value == 0){// if there is no cache for the data, call backend to get them.
      this.showLoadingAnimation(true);
      var cat = category

      this.service.getPublication(id, cat).subscribe(
        data => {
          this.publicationCache = this.processIpData(this.sortPublication(data), category);
          this.currentCache = this.publicationCache;
          let tmp = this.loadCurrent(this.publicationCache, pageIndex, pageSize);

          this.publicationsSubject.next(tmp);
          this.resultNumber.next(this.publicationCache.length);
         /* if(category.length == 0){
            this.categories.next(this.listCategory(data));
          }*/
          this.showLoadingAnimation(false);
        },
        error =>{
          this.showLoadingAnimation(false);
          if(error.status == 404){
            this.publicationsSubject.next(null);
            this.alertifyService.error("Not found.");
            return false;
          }
          this.alertifyService.error(error.message);
        }
      );
    }else {
      console.log("load from the cache.");
      let tmp = this.loadCurrent(this.publicationCache, pageIndex, pageSize);
      this.publicationsSubject.next(tmp);
    }
  }


  loadPublicationPage(pageIndex: number, pageSize: number) {
    let tmp = this.loadCurrent(this.currentCache, pageIndex, pageSize);
    this.publicationsSubject.next(tmp);
  }

  loadCategory(category: string, pageIndex: number, pageSize: number) {

    console.log("load from the cache.");
    if(category === ""){
      this.currentCache = this.publicationCache;
    }else{
      this.currentCache = this.publicationCache.filter(x => x.category == category);
    }
    this.resultNumber.next(this.currentCache .length);
    let tmp = this.loadCurrent(this.currentCache , pageIndex, pageSize);
    this.publicationsSubject.next(tmp);

  }

  sortPublication(pubs: PublicationModel[]): PublicationModel[]{

    pubs.sort(function(a,b){
      var title_a = a.title.trimStart();
      var title_b = b.title.trimStart();
      var separator_a = a.title.indexOf(",");
      if(separator_a !== -1 && (a.category === "IP" || a.category === 'CAN-MEX')){
        title_a = a.title.substring(separator_a + 1).trimStart();
      }
      var separator_b = b.title.indexOf(",");
      if(separator_b !== -1 && (b.category === "IP" || b.category === 'CAN-MEX')){
        title_b = b.title.substring(separator_b + 1).trimStart();
      }

      if(title_a === title_b){
        return a.acronym < b.acronym ? -1 : 1;
      }
      return title_a < title_b ? -1 : 1;
    });

    return pubs;

  }

  /**
   * List the categories the pubs contain.
   *  */
  listCategory(pubs: PublicationModel[]): string[] {
    var category: string[] = [...new Set(pubs.map(item => item.category))];
    console.log("category = " + category);
    return category;
  }

  processIpData(data: PublicationModel[], cat: string): PublicationModel[]{

    if(cat == "IP"){ // For IP, get rid of Canada and Mexico.
      var filter: PublicationModel[] = [];
      data.forEach((element, index) => {
        if(!element.acronym.startsWith("CAN") && !element.acronym.startsWith("MEX")){
          filter.push(element)
        }
      });
      return filter;
    }else if(cat == "CAN-MEX"){ // For CAN, only keep Canada and Mexico.
      var filter: PublicationModel[] = [];
      data.forEach((element, index) => {
        if(element.acronym.startsWith("CAN") || element.acronym.startsWith("MEX") ){
          filter.push(element);
        }
      });
      return filter;
    }else{
      return data;
    }
  }

  searchPublications(id: number, keystring: string, pageIndex: number, pageSize: number){
   this.showLoadingAnimation(true);
      this.service.searchPublication(id, keystring).subscribe(
        data => {
          //this.publicationCache = data;
          let tmp = this.loadCurrent(data, pageIndex, pageSize);

          this.publicationsSubject.next(tmp);
          this.resultNumber.next(data.length);
          this.showLoadingAnimation(false);
        },
        error =>{
          this.showLoadingAnimation(false);
          if(error.status == 404){
           // this.publicationsSubject.next(null);
            this.alertifyService.error("Not found.");
            return false;
          }
          this.alertifyService.error(error.message);
        }
      );
  }

  searchPublication(keystring: string, category: string, pageIndex: number, pageSize: number) : number{
    var categoryData : PublicationModel[] = [];
    if(category == ""){
      categoryData = this.publicationCache.filter(x =>  x.title.toLowerCase().includes(keystring.toLowerCase())
        || x.acronym.toLowerCase().includes(keystring.toLowerCase()));
    }else{
      categoryData = this.publicationCache.filter(x => x.category == category && (x.title.toLowerCase().includes(keystring.toLowerCase())
      || x.acronym.toLowerCase().includes(keystring.toLowerCase())));
    }

    if(categoryData.length == 0) return -1;
    this.currentCache = categoryData;
    this.resultNumber.next(categoryData.length);
    let tmp = this.loadCurrent(categoryData, pageIndex, pageSize);
    this.publicationsSubject.next(tmp);
    return 0;

  }

  // For international protocols, only show it's country name.
  buildInternationTitle(data: PublicationModel[]): PublicationModel[]{
    data.forEach( x => {
          x.title = x.title.substring(x.title.indexOf("- ") + 1);
    })
    return data;
  }

  resetResultNumber(){
    this.resultNumber.next(0);
  }

  toggleArchive(acronym: string, title: string){
    var tmp = this.publicationsSubject.value;
    var current = tmp.find(x => x.acronym == acronym && x.title == title);
    if(current.archived == 1){
      this.expandCurrent(acronym, title);
    }else if(current.archived == 2){
      this.hideCurrent(acronym, title);
    }
  }


  expandCurrent(acronym: string, title: string){
    var tmp = this.publicationsSubject.value;
    var current = tmp.find(x => x.acronym == acronym && x.title == title);
    current.archived = 2; // to set it to hide.

    let data = this.publicationCache;

    for (var i = 0; i < data.length; i++){
      if(data[i].acronym == acronym && data[i].title == title){
        if(!this.isLatestPub(data, i)){
          const t: PublicationTmp = {
            acronym : data[i].acronym,
            category : data[i].category,
            pubId : data[i].pubId,
            archived: 0,
            hasMonitoring: false,
            version: data[i].version,
            title: data[i].title,
            lastUpdate: (data[i].lastUpdate || "").substring(0, 10),
            formatAndPath: data[i].formatAndPath,
            state: data[i].state,
            updating: data[i].acronym.indexOf("|") == -1 ? "" : data[i].acronym.substr(data[i].acronym.indexOf("|") + 1, 4),
            old: true
          }
          var index = tmp.findIndex(x => x.acronym == acronym);
          tmp.splice(index + 1, 0, t);
        }
      }
    }
    this.publicationsSubject.next(tmp);
  }

  hideCurrent(acronym: string, title: string){
    var tmp = this.publicationsSubject.value;

    for (var i = 0; i < tmp.length; i++){
      if(tmp[i].acronym == acronym && tmp[i].title == title){
        if(!this.isLatestPub(tmp, i)){
          tmp.splice(i, 1);
          i--;
        }else{
          tmp[i].archived = 1; // set it to archived.
        }
      }
    }
    this.publicationsSubject.next(tmp);
  }

  // load data according to the page index.
  loadCurrent(data: PublicationModel[], pageIndex:number, pageSize: number): PublicationTmp[] {
    var tmp = new Array();
        let start = pageIndex * pageSize;

        for (var i = 0; i < data.length; i++){
          if(i >= start && i < start + pageSize){
            var archive = 0; // 0- not achived; 1- achived; 2- expanded
            var old = true; // set it to 'Old' version as default value.
            if(this.isDuplicate(data, i)){
              archive = 1;
              if(this.isLatestPub(data, i)){
                old = false;
              }
            }
              const t: PublicationTmp = {
              acronym : data[i].acronym,
              category : data[i].category,
              pubId : data[i].pubId,
              archived: archive,
              hasMonitoring: false,
              version: data[i].version,
              title: data[i].title,
              lastUpdate: (data[i].lastUpdate || "").substring(0, 10),
              formatAndPath: data[i].formatAndPath,
              state: data[i].state,
              updating: data[i].acronym.indexOf("|") == -1 ? "a" : data[i].acronym.substr(data[i].acronym.indexOf("|") + 1, 4),
              old: old
            }
            if(archive === 0 || !old)
              tmp.push(t);
          }
        }
       return tmp;
  }


  /**
   * Check if the publication is duplicate.
   * @param data: the whole publication list
   * @param ind: the n-th publication in the list
   * @returns true if the ind-th item is duplicate
   */
  isDuplicate(data: PublicationModel[], ind: number): boolean{
    if(data.length < ind) return false;

    for(let i = 0; i < data.length; i++){
      if(i == ind) continue;

      if(data[i].acronym == data[ind].acronym && data[i].title == data[ind].title){
        return true;
      }
    }
    return false;
  }

  /**
   * Check if the publication is the latest version.
   * @param data: the whole publication list.
   * @param ind: the index of the publication in @param data for determining.
   * @returns
   */
  isLatestPub(data: PublicationModel[], ind: number): boolean {
    if(data.length < ind) return true;

    const all = data.filter((item) => item.acronym === data[ind].acronym);
    const max = all.reduce((prev, current) => (prev.version > current.version) ? prev: current);
    if(max.version === data[ind].version){
      return true;
    }
    return false;
  }

  getNumber(): Observable<number>{
    return this.resultNumber.asObservable();
  }

  getCategory():Observable<string[]>{
    return this.categories.asObservable();
  }

  showLoadingAnimation(showFlag: boolean){
    this.loading = showFlag;
  }

  connect(collectionViewer: CollectionViewer): Observable<PublicationTmp[]>{
  	return this.publicationsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
  	this.publicationsSubject.complete();
  }
}