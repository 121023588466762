import { Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {Router, NavigationEnd  } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {MatSidenav} from '@angular/material/sidenav';
import { Account } from '../../_models';
import {RouteConfigs, localStorageKeys } from '../../_constants';
import  { AuthService} from '../../auth/auth.service';
import { CookieService} from 'ngx-cookie-service';
import {environment} from './../../../environments/environment';
import { env } from 'process';


@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  events: string[] = [];
  protected routeConfigs = RouteConfigs;
  opened: boolean = true;
  account: Account;
  title: string;
  mobileScreen = false;
  regComparePermission = false;
  version = "1.0";
  StpDomain = environment.StpDomain;
  AuditHubDomain = environment.AudithubDomain;
  RegHubDomain = environment.RegHubDomain;
  userEmail: string;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private observer: BreakpointObserver,
    private router: Router
    ) {
      this.routeEvent(this.router);
    }

    routeEvent(router: Router){
      router.events.subscribe( e =>{
        if( e instanceof NavigationEnd){
          this.title = this.BuildTitleFromUrl(e.url); // Generate title for the page by checking the URL.
        }
      });
    }

  ngOnInit() {
    //console.log("dashboard init..." + this.router.url);
    this.account = AuthService.currentUser;
    this.userEmail = this.account.email;
    //this.title = "Welcome to AuditHub";
    this.version = environment.version;
    var featur = this.cookieService.get("ah_feature");
    if(featur && featur.indexOf("reg_compare") !== -1){
      this.regComparePermission = true;
    }
  }

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.mobileScreen = true;
        this.sidenav.close();

      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
        this.mobileScreen = false;
      }
    });
  }

  menuClick(menu: string){
    if(this.sidenav.opened && this.mobileScreen) {
      this.sidenav.close();
    }
    if(this.authService.getLoadingStatus()) return false;
    switch(menu){
      case 'home':
        this.router.navigateByUrl("/audithub/home");
        break;
      case 'product':
        this.router.navigateByUrl("/audithub/publication");
        break;
      case 'new-template':
        this.router.navigateByUrl("/audithub/apptool/new");
        break;
      case 'list-template':
        this.router.navigateByUrl("/audithub/apptool/list");
        break;
      case 'custom-questions':
        this.router.navigateByUrl("/audithub/apptool/custom-questions");
        break;
      case 'rank-and-score':
        this.router.navigateByUrl("/audithub/apptool/rank-and-score");
        break;
      case 'iprequest':
        this.router.navigateByUrl("/audithub/iprequest");
        break;
      case 'pocketref':
        this.router.navigateByUrl("/audithub/pocketref");
        break;
      case 'regcompare':
        window.open('/audithub/regcompare', '_blank');
        break;
      case 'iprequest-list':
        this.router.navigateByUrl("/audithub/iprequest/list");
        break;
      case 'trainvideo':
        this.router.navigateByUrl("/audithub/trainvideo");
        break;
      case 'buildingStatus':
        this.router.navigateByUrl("/audithub/apptool/buildingStatus");
        break;
      case 'publicationView':
        this.router.navigateByUrl("/audithub/apptool/pubViewTrack");
        break;
      default:
        console.debug("later on");
    }
  }


  ngOnDestroy(): void {
     //localStorage.removeItem(localStorageKeys.authToken);
     this.authService.signOut();
  }

  ngOnChanges(){
    this.title =  this.router.url;
  }

  hasNumber(input: string): boolean {
    return /\d/.test(input);
  }

  // According to the current url, show the corresponding title in the header.
  BuildTitleFromUrl(url: string): string{
    let urlPart = this.RemoveUrlNumberPart(url);

    switch(urlPart){
      case '/audithub/home':
        return 'Welcome to AuditHub';
      case '/audithub/publication':
        return 'Product List';
      case '/audithub/apptool/new':
        if(this.hasNumber(url))
          return 'Edit Template';
        return 'New Template';
      case '/audithub/apptool/list':
        return 'RegApply Home';
      case '/audithub/apptool/custom-questions':
        return 'Custom Questions';
      case '/audithub/apptool/rank-and-score':
        return 'Custom Ranking & Scoring';
      case '/audithub/apptool/edit':
        return 'Audit Summary';
      case '/audithub/apptool/viewRulebooks':
        return 'View Rulebook ' + this.GetUrlParameter(decodeURIComponent(url));
      case '/audithub/apptool/viewCitations':
        return 'RegRegister ' + this.GetUrlParameter(decodeURIComponent(url));
      case '/audithub/apptool/applicability':
        return 'Build Applicability'
      case '/audithub/iprequest':
        return 'IP Request';
      case '/audithub/iprequest/list':
        return 'IP Request - My List';
      case '/audithub/trainvideo':
        return 'Training Videos'
      case '/audithub/pocketref':
        return 'Pocket Reference';
      case '/audithub/regcompare':
        return 'AuditHub - RegCompare';
      case '/audithub/apptool/buildingStatus':
        return 'Welcome to Building Center';
      case '/audithub/apptool/pubViewTrack':
          return 'Product Views Report';
      default:
        return 'Welcome to AuditHub';
    }
  }

  // To get the parameter part of the URL
  // example: /audithub/apptool/viewCitations/16173|auditname => auditname
  GetUrlParameter(url: string): string {
    console.debug("getUrlparameter(" + url);
    let ind = url.lastIndexOf("|");
    if(ind !== -1){
      console.debug("audit name = " + url.substring(ind +1));
      return "[" + url.substring(ind + 1) +"]";
    }
    return "";
  }

  // To remove the parameter part of the URL
  // example: /audithub/apptool/viewCitations/16173 => /audithub/apptool/viewCitations
  // example: /audithub/apptool/applicability/16173/15756 => /audithub/apptool/applicability
  RemoveUrlNumberPart(url: string): string {
    var t = 0;
    var ind = url.length;

    while(!isNaN(t)){
      url = url.substring(0, ind);
      ind = url.lastIndexOf("/");
      var end = url.substring(ind + 1);
      t = parseInt(end);
    }
    return url;
  }

  goPublications() {
    //this.sidenav.close();
    this.router.navigateByUrl("audithub/publication");
  }

  toggleSidenav() {
    this.opened = !this.opened;
  }

  openSideMenu(value: boolean){
    if (this.sidenav.opened) {
      this.sidenav.close();
    } else {
      this.sidenav.open();
    }
  }

  goHome(){}
}