import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CustomTopic, CustomContent, CustomTopicWithTitle} from '../../model/CustomQuestion';
import { CustomQuestionService } from '../../service/custom-question.service';
import { AlertifyService } from '../../../_models/alertify.service';


@Component({
  selector: 'app-list-custom',
  templateUrl: './list-custom.component.html',
  styleUrls: ['./list-custom.component.css']
})
export class ListCustomComponent {
  @Input() customList: CustomTopicWithTitle[];
  @Input() selectedTopic: string;
  @Input() userId: number;
  @Output() selectedCustomTopic = new EventEmitter<string>();
  @Output() updateCustomShare = new EventEmitter<CustomTopic>();

  constructor(private service: CustomQuestionService, public alertifyService: AlertifyService,) { }

  // Topic in the topic list is clicked: show the content of the choosen topic.
  onClickTopic(topicID: string){
    if(this.service.getTopicChangeFlag()){
      this.alertifyService.confirmOkCancel("STP AuditHub", "There are unsaved changes. Are you sure to leave?",
      () => {
        // OK: do nothing
        this.switchTopic(topicID);
      },
      () =>{ // Cancel
        return false;
      });
    }else{
      this.switchTopic(topicID);
    }
  }

  switchTopic(topicID: string){
    this.selectedTopic = topicID;
    this.service.setTopicChangeFlag(false); // Restore the change flag when switch to another topic.
    this.selectedCustomTopic.emit(topicID);
  }

  // Checkbox indicating topic share status is clicked: call backend to change the status.
  onShareChange(permission, topicID:string){
    var permit: boolean | null;
    /* lockToUser: Auguse 18, 2023 redefine the colunm
                 *  true: not share
                 *  false: share with view permission
                 *  null: share with edit permission
                 */
    switch(permission){
      case "No":
        permit = true;
        break;
      case "View":
        permit = false;
        break;
      default:
        permit = null;
    }
    var tp: CustomTopic = {
      title: "",
      topic: "",
      topicID: topicID,
      userID: 0,
      userName:'',
      locktoUser: permit,
      contents: []
    };
    this.updateCustomShare.emit(tp);
  }
}