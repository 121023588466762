<div id="audit-print">
    <h2>Template: {{auditName}}</h2>
    <div *ngIf="auditPrint$ | async as aps" class="ow-fluid">
        <div *ngFor="let ap of aps" class="row-fluid">
            <div class='acronyms-level'>
                <h3 class='acronyms-name'><strong>Publication</strong>: {{ap.acronym}}</h3>
                <div class='acronym-level'>
                    <div *ngFor="let tp of ap.topics">
                        <div>
                            <h4>Topic: {{tp.topic}}</h4>
                            <div class="table">
                                <div *ngFor="let rb of tp.rulebooks">
                                    <div class="row-fluid table-header">
                                        <div>{{rb.rbName}}</div>
                                        <div class="row-end"><input type='checkbox' [checked]="rb.checkNa">N/A</div>
                                    </div>
                                    <br/>
                                    <div *ngIf="!rb.checkNa">
                                    <div *ngFor="let apq of rb.appSect" class="table-body">
                                        <div *ngIf="apq.rbSectNum == 0" class="row-fluid">
                                            <div class="c1">Rulebook Question</div>
                                            <div class="c3" [innerHTML]="apq.question"></div>
                                            <div class="row-end"><strong>Answer:</strong> {{apq.answerQ}}</div>
                                            <div *ngIf="apq.exemption != null && apq.answerQ != 'N'">
                                                <div class="c1">Excemption Question</div>
                                                <div class="c3" [innerHTML]="apq.exemption"></div>
                                                <div class="row-end"><strong>Answer:</strong> {{apq.answerE}}</div>
                                            </div>
                                        </div>

                                        <div *ngIf="apq.rbSectNum > 0" class="row-fluid">
                                            <div class="c1">Section {{apq.rbSectNum}}</div>
                                            <div class="c3" [innerHTML]="apq.question"></div>
                                            <div class="row-end"><strong>Answer:</strong> {{apq.answerQ}}</div>
                                            <div *ngIf="apq.exemption != null && apq.answerQ != 'N'">
                                                    <div class="c1">Excemption Question</div>
                                                    <div class="c3" [innerHTML]="apq.exemption"></div>
                                                    <div class="row-end"><strong>Answer:</strong> {{apq.answerE}}</div>
                                            </div>
                                        </div>
                                    </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="audit-header">
    <div class="button-row">
        <button mat-button
			class="icon-only"
			matTooltip="refresh the data"
			matTooltipClass="tooltip1"
			(click)="reload()">
			<mat-icon fontSet="material-icons-outlined">sync</mat-icon>
		</button>
        <button mat-button class="icon-only"
            (click)="printAudit()"
            matTooltip="Print audit - a record of how you answered all of the applicability screening questions."
            matTooltipClass="tooltip1">
            <mat-icon>local_printshop</mat-icon>
        </button>
        <button mat-button class="icon-only"
            matTooltip="Add/remove topics"
            matTooltipClass="tooltip1"
            (click)="onAddRulebook($event)"><mat-icon>add_box</mat-icon>
        </button>
        <button mat-button class="icon-only"
            (click)="backToHome()"
            matTooltip="Back to RegApply home"
            matTooltipClass="tooltip1">
            <mat-icon>west</mat-icon>
        </button>
    </div>
</div>

<div *ngIf="rulebooks else noRulebook" class="audit-content"  fxLayout fxFlex>
    <div  class="audit-rb" fxFlex="65">
        <ul class="nav nav-tabs">
            <li *ngFor="let ac of rulebooks">
                <a [routerLink]="" [class]="(ac.acronym==selectedAcronym)? 'selected' : 'none'" (click)="onAcronymChange(ac.acronym)">{{ac.acronym}}({{ac.releaseNum}})</a>
            </li>
        </ul>
        <ng-container *ngIf="selectedAcronym !== 'Custom'">
            <div class="table-content" *ngFor="let ac of rulebooks">
                    <div class="active" *ngIf="ac.acronym == selectedAcronym">
                        <div class="widget-box" *ngFor="let tp of ac.topics">
                            <div class="widget-header">
                                <li>{{tp.topic}}</li>
                                <span></span>
                            </div>
                            <div class="widget-body">
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th class="t1">Rulebook</th>
                                            <th class="t2">Last Saved</th>
                                            <th class="t3">Status</th>
                                            <th class="t3">Apply<mat-icon class="help-info" (click)="openHelpInfo($event,6)">info</mat-icon></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rb of tp.rulebooks">
                                            <td class="t1 rbName"
                                                (click)="onRbClick(rb.appKey,rb.rbName,rb.abridged)">
                                                {{rb.rbName}}
                                                <mat-icon *ngIf="rb.abridged" matTooltip="Abridged Protocol">info</mat-icon>
                                                <mat-icon class="needsReview" *ngIf="rb.checkUpdate"
                                                    matTooltip="Rulebook updates for Review"
                                                    (click)="onReviewUpdate($event, rb.appKey)">
                                                    flag
                                                </mat-icon>
                                            </td>
                                            <td class="t2"><span>{{rb.dateModified?.toLocaleString().slice(0, 16).replace("T", " ")}}</span></td>
                                            <td class="t3">
                                                <span class="rb-percent"
                                                    [ngClass]="{'complete': rb.status == 'Applicable',
                                                        'not-applicable': rb.status == 'Not Applicable',
                                                        'needsReview': rb.status == 'Needs Review'}">
                                                    {{rb.status}}
                                                </span>
                                            </td>
                                            <td class="t3">
                                                <mat-checkbox class="topic-check" [(ngModel)]="rb.checkNa"
                                                    [disabled]="isLoading || !editable "
                                                 (change)="onCheckNaChange($event, rb.appKey)">N/A</mat-checkbox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedAcronym === 'Custom'">
            <div class="table-content" *ngFor="let ac of rulebooks">
                <div class="active" *ngIf="ac.acronym == selectedAcronym">
                    <div class="widget-box" *ngFor="let tp of ac.topics">
                        <div class="widget-header">
                            <li>{{tp.topic}}
                                <mat-icon
                                    class="help-info"
                                    matTooltip="This topic will be included in your Xcelerator where applicable."
                                    matTooltipClass="tooltip1">info
                                </mat-icon>
                            </li>
                            <span></span>
                        </div>
                        <div class="widget-body">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th class="t1">Sub Topic</th>
                                        <th class="t2">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let rb of tp.rulebooks">
                                        <td class="t1">{{rb.rbName}}</td>
                                        <td class="t2">
                                            <span class="rb-percent complete" >Applicable</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <mat-card class="audit-detail" fxFlex="35">
            <mat-card-title class="mat-primary mat-toolbar">Audit Details</mat-card-title>
            <mat-card-subtitle class="sub-title">
                {{auditName}}
                <p class="note-font">{{facilityLocation}}<br>{{auditDate}}</p>
                <section class="example-section">
                    <h3>{{percentage}}%</h3>
                    <mat-progress-bar
                            class="percentage"
                            [mode]="determinate"
                            [value]="percentage">
                    </mat-progress-bar>
                </section>
            </mat-card-subtitle>
            <mat-card-content class="large">
              <div  *ngFor="let ac of rulebooks" fxLayout="column" fxFill>
                <div fxLayout fxFlex>
                  <div class="one" fxFlex="85" fxLayoutAlign="start start">
                    <p>{{ac.title}}</p>
                  </div>
                  <div class="two" fxFlex="15" fxLayoutAlign="start start">
                    <p>{{ac.percentage}}</p>
                  </div>
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions id="bottomPad">
                <button mat-button
                    [matMenuTriggerFor]="actionMenu"
                    #menuTrigger="matMenuTrigger"
                    (click)="menuTrigger.openMenu()"
                    >
                   Actions<mat-icon>keyboard_arrow_down</mat-icon>
                </button>

                <!--First level menu-->
                <mat-menu #actionMenu="matMenu">
                    <button mat-menu-item
                        *ngIf="account.userType.charAt(2) === '1'"
                        (click)="onBuild($event, 'X3.0')"
                        matTooltip="Moves applicable audit statements into an Excel file." matTooltipClass="tooltip1">
                        <mat-icon aria-hidden="true" aria-label="Next">build</mat-icon>Build Xcelerator
                    </button>
                    <button mat-menu-item
                        *ngIf="account.userType.charAt(2) === '1'"
                        (click)="onBuild($event, 'C3.0')"
                        matTooltip="Builds an excel list of all relevant regulatory citations included in this Template." matTooltipClass="tooltip1">
                        <mat-icon aria-hidden="true" aria-label="Next">receipt</mat-icon>Build RegRegister
                    </button>
                    <button mat-menu-item
                        *ngIf="account.userType.charAt(2) === '1'"
                        (click)="onBuild($event, 'csv')"
                        matTooltip="Moves applicable audit statements into a CSV file." matTooltipClass="tooltip1">
                        <mat-icon aria-hidden="true" aria-label="Next">receipt</mat-icon>Build CSV
                    </button>
                    <button mat-menu-item
                        *ngIf="account.userType.charAt(0) === '1'"
                        (click)="onBuild($event, 'reghub')"
                        matTooltip="Uploads templates & citations into RegHub to be monitored." matTooltipClass="tooltip1">
                        <mat-icon aria-hidden="true" aria-label="Next">visibility</mat-icon>Monitor RegRegister
                    </button>
                    <button mat-menu-item
                        *ngIf="account.userType.charAt(2) === '1'"
                        (click)="onViewRulebooks()" >
                        <mat-icon aria-hidden="true" aria-label="Next">grading</mat-icon>View Rulebooks
                    </button>
                    <ng-container *ngIf="account.companyId !== 355 && getEmis().length > 0 && account.userType.charAt(2) === '1'">
                        <button mat-menu-item (click)="onBuild($event, emisid)">Build for {{getEmis()}}
                            <mat-icon class="help-info" (click)="openHelpInfo($event, 11)">info</mat-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="account.companyId == 355 && account.userType.charAt(2) === '1' && account.email.indexOf('-demo') == -1">
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>BenchmarkESG</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>ComplianceQuest</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'ehs')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>EHS Insight</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>Enablon</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>ETQ</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>Ideagen EHS</span>
                        </button>
                       <button mat-menu-item (click)="onBuild($event, 'intelex')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>Intelex</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>Interaptix </span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>Intellect</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>Origami Risk</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>Partner Demo</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>SafetyStratus</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>SBN</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>Velocity</span>
                        </button>
                        <button mat-menu-item (click)="onBuild($event, 'no')">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>Vector</span>
                        </button>
                      </ng-container>
                      <ng-container
                        *ngIf="account.companyId == 355 && account.userType.charAt(2) === '1' && account.email.indexOf('-demo') !== -1 && account.email.indexOf('stp-demo') === -1" >
                        <button mat-menu-item (click)="onBuild($event, account.email.substring(0, account.email.indexOf('-demo')))">
                            <mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
                              <span>{{this.getDemoBuildType(account.email)}}</span>
                        </button>
                      </ng-container>
                </mat-menu>
            </mat-card-actions>
    </mat-card>
</div>
<ng-template #noRulebook>
    <div class="common-section" *ngIf="service.loading == false">
        <h3>No rulebook in this template</h3>
        <p>Click 'Add/remove topics' button to add topics to the template.</p>
    </div>
</ng-template>
<div class="spinner-container" *ngIf="service.loading">
    <mat-spinner></mat-spinner>
</div>