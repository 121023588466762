<div class="login login-with-news-feed">
<div class="news-feed">
      <div class="news-image" style="background-image: url(../../assets/images/random_bg.jpg)"></div>

      <div class="news-caption">
          <a class="brand" href="index.asp">
            <img src="../../assets/images/stp-logo.png" alt="Audit Checklist Compliance Risk">
          </a>
          <h4 class="caption-title"><b>Specialty Technical Publishers </b></h4>
          <p>PRACTICAL COMPLIANCE & AUDIT SOLUTIONS</p>
      </div>
  </div>

  <div class="right-content">
  <div class="login-header">
    <div class="brand">
              <span><img src="../../assets/images/favicon.ico" alt="" style="float: left;width: 40px;margin-right: 10px;"></span><b>STP Audit Hub</b>
              <br>
              <small style="margin-top: 10px;">
                Using subscription-based online access for multiple users allows a cost-effective and efficient way of managing resources throughout your organization. Resources are always up-to-date and training is available at no charge for your employees.
              </small>
          </div>
          <div class="icon">
          <i class="fa fa-sign-in"></i>
          </div>
      </div>
      <div class="login-content">
          <form (ngSubmit)="login()" class="stp-form">
              <legend style="margin-bottom: 7px;" class="sasol">
                          Sasol AD Login
              </legend>
              <div class="login-buttons control-group">
                <p>
                  <i><b>The use of this application may be monitored, recorded, and subject to audit; unauthorized use of the application is prohibited and subject to disciplinary actions or even legal actions; and the use of the application indicates consent to monitoring and recording of actions performed.</b></i>
              </p>
                <a href='#'>
                  <button name="sasol-login-button" id="sasol-login-button" type="submit" class="btn btn-primary">Sign in</button>
              </a>
              </div>

              <br/>
                            <p>For any technical support please contact us at <b><a href="mailto:info@stpub.com">info@stpub.com</a></b> or give us a call at <b>1 604-983-3434</b>, Toll Free: <b>1 800-251-0381</b>.</p>
              <p>Contact Roxane Cinnamon for online training:<br>
                <strong><a href="mailto:onlinetraining@stpub.com">onlinetraining@stpub.com</a></strong> or <strong>1-800-251-0381</strong>
            </p>
          </form>
          <hr />
          <div id="footer">
            <div class="text center text-grey-darker">
              <div>
                <small>
                  <p>
                    &copy; 2020 STP Compliance EHS. All rights reserved.<br />
                    <a href="http://en.stpub.com/terms-conditions">Terms & Conditions</a> |
                    <a href="http://en.stpub.com/documents/pdf/LicenseAgreement.pdf">License Agreement</a> |
                    <a href="http://en.stpub.com/sitemap">Sitemap</a> |
                    <a href="http://en.stpub.com/support">Support</a>
                  </p>
                </small>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>


