import { Component, OnInit, Injectable } from '@angular/core';
import {DataSource, SelectionChange, CollectionViewer} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, merge} from 'rxjs';
import {map} from 'rxjs/operators';
import { FlatTreeControl } from '@angular/cdk/tree';


import { TemplateService } from '../../apptool/service/template.service';
import { InternationalProtocol, IpRequest} from '../../apptool/model/InternationalProtocol';
import { AlertifyService } from '../../_models/alertify.service';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(public item: string, public level = 1, public expandable = false,
    public isLoading = false) {}
}

/**
 * Call backend for dynamic data. When expanding a node in the tree, the data source will need to fetch the descendants data from backend.
 */
@Injectable({providedIn: 'root'})
export class DynamicDatabase{
  constructor(public service: TemplateService){}

  dataMap = new Map<string, string[]>();
  rootLevelNodes: string[] = [];

  /** Initial data from backend */
  initialData(data:InternationalProtocol[]): DynamicFlatNode[]{
    let map = new Map();
    var tmp: string[] = [];
    for(var i = 0; i < data.length; i++){
      var countryArr: string[] = [];
      for(var j = 0; j < data[i].Country.length; j++){
        countryArr.push(data[i].Country[j].Name);
      }
      map.set(data[i].Category,countryArr);
      tmp.push(data[i].Category);
    }
    this.rootLevelNodes = tmp;
    this.dataMap = map;
    return this.rootLevelNodes.map(name => new DynamicFlatNode(name, 0, true));
  }

  getChildren(node: string): string[] | undefined {
    return this.dataMap.get(node);
  }

  isExpandable(node: string): boolean {
    return this.dataMap.has(node);
  }
}

/**
 * File datatable, it can build a tree structured Json object from string.
 *
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {return this.dataChange.value;}
  set data(value: DynamicFlatNode[]){
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>, private _database: DynamicDatabase){}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]>{
    this._treeControl.expansionModel.changed.subscribe(change => {
      if((change as SelectionChange<DynamicFlatNode>).added || (change as SelectionChange<DynamicFlatNode>).removed){
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void{}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>){
    if(change.added){
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if(change.removed){
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  /** Toogle the node, remove from display list */
  toggleNode(node: DynamicFlatNode, expand: boolean){
    const children = this._database.getChildren(node.item);
    const index = this.data.indexOf(node);
    if(!children || index < 0){ // If no children, or cannot find the node, no op
      return;
    }

    node.isLoading = true;

    setTimeout(() => {
      if(expand){
        const nodes = children.map(name =>
          new DynamicFlatNode(name, node.level + 1, this._database.isExpandable(name))
        );
        this.data.splice(index + 1, 0, ...nodes);
      } else {
        let count = 0;
        for(let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++){}
        this.data.splice(index + 1, count);
      }

      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }, 1000);
  }
}

@Component({
  selector: 'app-ip-request',
  templateUrl: './ip-request.component.html',
  styleUrls: ['./ip-request.component.css']
})
export class IpRequestComponent implements OnInit {
  internationalProtocol: InternationalProtocol[];
  selectCountries = new Set<number>();

  constructor(public service: TemplateService, private alertifService: AlertifyService) {
  }

  ngOnInit() {
    this.service.showLoadingAnimation(true);
    this.service.getInternationalProtocol().subscribe(data => {
      this.internationalProtocol = data;
      this.service.showLoadingAnimation(false);
    });
  }

  // country checkbox is checked or unchecked.
  onCountryCheck(event: any, key:number){
    console.log(key + " checked.");
    if(event.checked){
      this.selectCountries.add(key);
    }else{
      this.selectCountries.delete(key);
    }
  }

  getCountryName(countryid: number): string {
    /*var idx = this.internationalProtocol.findIndex(x => x.Country.find(z => z.Id == countryid));
    var country = this.internationalProtocol[idx].Country.find(x => x.Id == countryid);
    */ // using flatMap to filter - it's same to the above commented codes.
    var result = this.internationalProtocol.flatMap(o => o.Country.flatMap(x => x.Id == countryid ? x.Name : [] ));
    return result[0];
  }

  // submit IP request.
  onSubmit(){
    if(this.selectCountries.size == 0){
      this.alertifService.warning("Please check countries before submit.");
    }else{
      let countries: number[] = [];
      let coutryiesName: string[] = [];
      this.selectCountries.forEach(x => {
          countries.push(x);
          coutryiesName.push(this.getCountryName(x));
        }
      );

      this.service.showLoadingAnimation(true);
      let data: IpRequest ={
        userId: 0,
        companyId: 0,
        countryId: countries,
        countryName: coutryiesName
      }
      this.service.submitIpReqeust(data).subscribe(
        value => {
          this.alertifService.success("IP request submitted.");
          //this.internationalProtocol.forEach( x => )
          this.selectCountries.clear();
        },
        error =>{
          this.service.showLoadingAnimation(false);
          this.selectCountries.clear();
          this.alertifService.error(error.message);
        },
        () => this.service.showLoadingAnimation(false)
      );
    }
  }
}