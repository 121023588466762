import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from '../../../shared/models/user.model';
import { UserDataService } from '../../../core/user/user-data.service'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
	allUsers$: Observable<User[]>;
  	constructor(private readonly userDataService: UserDataService) {}
 
  ngOnInit() {
    this.allUsers$ = this.userDataService.getAll();
  }

  userAdded(userToAdd: User){
  	this.allUsers$ = this.userDataService
  		.add(userToAdd)
  		.pipe(switchMap(() => this.userDataService.getAll()));
  }

}
