<h2 mat-dialog-title>Add New Custom Topic</h2>
<div mat-dialog-content [formGroup]="form">

        <mat-form-field class="full-width">
            <input matInput id="topicInput" maxlength="50"
                placeholder="Topic"
                formControlName="topic" required="true"><!-- Title / Acronym-->
        </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>First Sub-Topic</mat-label>
            <input matInput placeholder="First Sub-Topic" maxlength="255" formControlName="subtopic" required="true"> <!-- Topic -->
        </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()" class="mat-raised-button" cdkFocusInitial>Cancel</button>
  <button class="mat-raised-button mat-primary" mat-button (click)="onNewCustomTopic()">Save</button>
</div>