import { Component, OnInit, ViewChild, AfterViewInit, Inject, NgZone, OnDestroy } from '@angular/core';
import { merge, Observable, of as observableOf } from 'rxjs';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatRadioChange } from '@angular/material/radio';
import { switchMap, startWith, map, tap } from 'rxjs/operators';
import { NavigationEnd, Router,RouterEvent } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { AlertifyService } from '../_models/alertify.service';
import { Emis} from './../_constants/Emis';
import {TemplateListDataSource } from './templateList.datasource';
import { TemplateListService } from './service/templateList.service';
import { TemplateService } from './service/template.service';
import {TemplateShareReq} from  './model/TemplateTopics';
import { TemplateList } from './model/TemplateList';

@Component({
  selector: 'app-apptool',
  templateUrl: './apptool.component.html',
  styleUrls: ['./apptool.component.css']
})
export class ApptoolComponent implements OnInit, AfterViewInit, OnDestroy {
	dataSource: TemplateListDataSource;
	displayedColumns: string[] = ['auditName', 'cssDate', 'isShared', 'permission', 'csskey','isUpdateAvailable'];
  userId: number;
  companyId: number;
  emis: string;
  title:string;
  resultsLength = 0;
  myTemplate: string;
  inProgress = 1;
  isRateLimitReached = false;
  isLoading = false; // A flag indicating that a background process is working, to determine if the UI should be frozen.
  searchWord: string;
  notFound = false;
  previousSearchWord: string;
  navigationSubscription;
  pagesize: string;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private service: TemplateListService,
    private tpService: TemplateService,
    private alertifyService: AlertifyService,
    public dialog: MatDialog
  	) {
    this.userId = JSON.parse(localStorage.getItem("account")).id;
    this.companyId = JSON.parse(localStorage.getItem("account")).companyId;
    this.emis = this.getEmisValue();
  }

  ngOnDestroy(): void {
    if(this.navigationSubscription){
      this.navigationSubscription.unsubscribe();
    }
  }

  getPageSize(){
    this.pagesize = localStorage.getItem("pageSize")??"10";
  }

  ngOnInit() {
    this.dataSource = new TemplateListDataSource(this.service, this.tpService);
    this.myTemplate = this.service.getMyTemplate();
    if(this.myTemplate == '1'){
      this.displayedColumns = ['auditName', 'cssDate', 'isShared', 'permission', 'csskey','isUpdateAvailable', 'status', 'color']; // color: reference key
    }else{
      this.displayedColumns = ['auditName', 'cssDate', 'isShared', 'sharedBy','permission', 'csskey','isUpdateAvailable','color'];
    }

    this.tpService.isLoading$.subscribe(data =>{
      this.isLoading = data;
    })
    this.getPageSize();

    this.fetchData();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {//subscribe to the router events to get notified about the refreshing page.
      if (e instanceof NavigationEnd && this.router.getCurrentNavigation().previousNavigation.finalUrl.toString() == "/audithub/apptool/list") {
        if(e.url === "/audithub/apptool/list"){
          this.myTemplate = '1';
          this.service.setMyTemplate(this.myTemplate);
          this.searchWord = "";
          this.displayedColumns = ['auditName', 'cssDate', 'isShared', 'permission', 'csskey','isUpdateAvailable'];
          this.tpService.removeCache();
          this.fetchData();
        }
      }
    })
  }

  fetchData(){
    this.dataSource.loadDatas(this.userId, this.myTemplate === "1", 1, 0, parseInt(this.pagesize), "desc", "cssDate");
    this.dataSource.getNumber().subscribe(re => this.resultsLength = re);
  }

  newAudit(){
    this.router.navigateByUrl("audithub/apptool/new");
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    if(this.isLoading) return false; // Ignore any request when it's waiting fro backend response.

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTemplatePage(+this.myTemplate == 1, this.inProgress, this.sort.direction, this.sort.active))
      ).subscribe();
  }

  getEmisValue(){
    var emis = Emis.find( x=> x.value == JSON.parse(localStorage.getItem("account")).emis);
    if(emis === undefined){
      return undefined;
    }else{
     return emis.name;
    }
  }

  searchInputEvent(inputValue: string): void{
    if(this.notFound && inputValue.length >= this.previousSearchWord.length) {
      return; // It's no point to searh further.
    }
    this.previousSearchWord = inputValue;
    this.searchTemplate();
  }

  searchTemplate() {
    if(this.isLoading) return false;
    if(!this.searchWord || this.searchWord.length === 0 || this.searchWord.trim() === ""){
      this.notFound = false;
      this.loadTemplatePage(+this.myTemplate == 1, this.inProgress, "desc", "cssDate");
      return false;
    }
    if(this.dataSource.searchTemplate(this.searchWord) == -1){
      this.notFound = true;
      this.alertifyService.warning("Not found.");
      return false;
    };
    this.notFound = false;
  }

  /**
   * Load the tempalte data again when there is a page event.
   */
  onChangePage(pe: PageEvent){
    localStorage.setItem("pageSize", this.paginator.pageSize.toString());
    this.pagesize = this.paginator.pageSize.toString();
    if(this.isLoading) return false;
    this.dataSource.loadPage(this.paginator.pageIndex, this.paginator.pageSize);
  }

  loadTemplatePage(myTemplate: boolean, inProgress: number, sortDirection: string, sortCol: string){
    if(this.isLoading) return false;
    this.dataSource.loadDatas(this.userId, myTemplate, inProgress, this.paginator.pageIndex, parseInt(this.pagesize), sortDirection, sortCol);
  }

  onViewClick(csskey: number, auditName:string){
    if(this.isLoading) return false; // Do not proceed when waiting for response from backend.

    var myurl = 'audithub/apptool/edit/' + csskey;

    this.router.navigateByUrl(myurl).then(nav => {
      let facilityLocation = this.dataSource.getFacilityLocation(csskey);
      let auditDate = this.dataSource.getAuditDate(csskey);
      let monitor = this.dataSource.getMonitor(csskey);
      this.tpService.setAuditName(auditName !== null ? auditName : '', facilityLocation, auditDate, monitor);
      this.tpService.setAuditPercentage(this.dataSource.getPercentage(csskey));
    }, err =>{
      console.log(err);
    });
  }

  // Display template list when switching between 'Current' and 'Archived'.
  // @Input: value: - '1': current; "2": Archived.
  onValChange(value: number) {
    if(value == 2 && this.myTemplate == '0'){
      this.alertifyService.confirmOk("Cannot show shared archive.");
      return false;
    }
    this.paginator.pageIndex = 0;
    this.inProgress = JSON.parse(value.toString());

    //this.tpService.removeCache();
    this.dataSource.loadDatas(this.userId, +this.myTemplate == 1, value, 0, this.paginator.pageSize, "desc", "cssDate");
  }

  onCopyClipboard(csskey: string) {
    this.tpService.getTemplateRefkey(csskey).subscribe( rep =>{
      var items = rep.value.split("|");
      let textToCopy = "Template:&emsp;&emsp; " + items[0]
        + "<br>Csskey:&emsp;&emsp;&emsp; " + csskey
        + "<br>Reference Key: " + items[1]
        + "<br>Previous CSS key: " + items[2];
      this.alertifyService.confirmOk(textToCopy);

    });
  }

  // Diplay template list when switching between 'My template' and 'Shared template'
  onRidaoChange(event: MatRadioChange){
    if(event.value == 0 && this.inProgress == 2){
      this.alertifyService.confirmOk("Cannot show shared archive.");
      return false;
    }

    this.paginator.pageIndex = 0;
    this.myTemplate = event.value;
    this.service.setMyTemplate(this.myTemplate);

    if(this.myTemplate == '1'){
      this.displayedColumns = ['auditName', 'cssDate', 'isShared', 'permission', 'csskey','isUpdateAvailable'];
    }else{
      this.displayedColumns = ['auditName', 'cssDate', 'isShared', 'sharedBy','permission', 'csskey','isUpdateAvailable'];
    }

    this.tpService.removeCache();
    this.dataSource.loadDatas(this.userId, +this.myTemplate == 1, this.inProgress, 0, this.paginator.pageSize, "desc", "cssDate");
  }

  // Click on Copy button: copy the template, then redirect to eidt it.
  onCopyClick(csskey: number){
    //console.log("click on copy button: copy " + csskey);
    this.tpService.showLoadingAnimation(true);
    this.tpService.copyTemplate(csskey).subscribe( value =>{
      this.tpService.showLoadingAnimation(false);

      // add this template to the template list on session storage.
      var tpItem = this.dataSource.getItem(csskey);// Get the template item
      tpItem.csskey = value.csskey;
      tpItem.auditName = tpItem.auditName +  " - Copy";
      this.tpService.addItemIntoCache(tpItem);
      this.onEditClick(value.csskey);
    });
  }

  // The share property of template changed.
  onPermissionChange(value:string, csskey: number, shared: boolean, permission:string){
    value = value.trim();

    if(permission == null || permission == "N/A")
      permission = "Not";
    else
      permission = permission.trim();

    // Ingore it if the changed value is same to the original one.
    let original = (shared == false ? 'Not' : permission);

    if(value == original){
      return false;
    }
    this.tpService.showLoadingAnimation(true);
    let data: TemplateShareReq = {
      csskey: csskey,
      permission: value
    }

    this.tpService.updateSharePermission(data)
      .subscribe(
        va => {
          if(value === "Not"){
            shared = false;
          }else{
            shared = true;
          }
          this.tpService.updateTemplateList(csskey, shared, value);
        },
        error => {
          console.error(error.message);
          this.alertifyService.error(error.error.title);
          this.tpService.showLoadingAnimation(false);
        },
        () => this.tpService.showLoadingAnimation(false)
      );
  }


  // Event: to update the template - the publications which template based have new versions.
  onUpgradeTemplate(csskey: number, audit: string){
    this.alertifyService.confirmOkCancel("STP AuditHub", "<p>Updating the template may take a while. You cannot access it during updating.</p><p>Please click OK to proceed.</p>",
    () =>{
      this.tpService.showLoadingAnimation(true);
      this.isLoading = true;
      this.tpService.upgradeTemplate(csskey)
        .subscribe( value => {
          this.tpService.showLoadingAnimation(false);
          this.isLoading = false;
          this.tpService.UpdateSessionCache(csskey, value); // To change the state of the template in the list to 'upgrading'
          this.tpService.removeTemplateLocalStorage(csskey);// The contents of the template might change after updated, so delete the local cache to refresh.
          //this.onViewClick(value, audit);
          this.alertifyService.success("The audit template is updating.");
          this.dataSource.loadDatas(this.userId, this.myTemplate === "1", 1, 0, 10, "desc", "cssDate");
        },
        error => {
          this.isLoading = false;
          this.tpService.showLoadingAnimation(false);
          this.alertifyService.error(error.message);
        }
      );
    },
    () =>{// do nothing
    });
  }


  // Export template to EMIS
  onExportEmis(csskey: number, buildType: string){
    this.tpService.showLoadingAnimation(true);
    try {
      this.tpService.buildXcelerator(csskey, buildType, "").subscribe(
        res  => {
          this.tpService.showLoadingAnimation(false);
          this.alertifyService.confirmOk("<p>Your audit template is now in the formatting queue, and you will receive a confirmation email once the file is ready.</p><p>STP audit template conversions take 1 - 2 days, your EMIS provider may also need time to integrate your template.</p><p>Please contact us at <b>info@stpub.com</b> if you have any questions.</p>");
        },
        err => {
          this.tpService.showLoadingAnimation(false);
          console.log("backend return error: " + err);
          this.alertifyService.error(err.error);
        },
        () => {
          this.tpService.showLoadingAnimation(false);
        }
      );
    }catch(Error){
      this.tpService.showLoadingAnimation(false);
      alert(Error.message);
    }
  }

  // Clicking refresh button to reload the page from the server.
  reload(){
    this.getPageSize();
    this.dataSource.reloadDatas(this.userId, this.myTemplate === "1", this.inProgress, 0, parseInt(this.pagesize), "desc", "cssDate");
    this.dataSource.getNumber().subscribe(re => this.resultsLength = re);
  }

  // Delete template: call service to delete item and remove it from the page.
  onDeleteTemplate(csskey: number, item: number){
    //var r = alertify.confirmOk("Are you sure to delete the template?");
    this.alertifyService.confirmOkCancel("STP AuditHub", "Are you sure to delete the template?",
    () =>{
      this.tpService.showLoadingAnimation(true);
      this.tpService.deleteTemplate(csskey)
        .subscribe( value => {
          this.dataSource.deleteRow(item);
          this.tpService.showLoadingAnimation(false);
          this.tpService.removeItemFromCache(csskey);
          this.tpService.removeTemplateLocalStorage(csskey);
          this.alertifyService.success("Template deleted.");
        },
        error => {
          this.tpService.showLoadingAnimation(false);
          console.error(error.message);
          this.alertifyService.error(error.error.title);
        }
      );
    },
    () =>{// do nothing
    });
  }

  // Archive template: call service to move item to the archive data table.
  onArchiveTemplate(csskey: number, item: number){
    this.alertifyService.confirmOkCancel("STP AuditHub", "Are you sure to archive the template?",
    () =>{
      this.tpService.showLoadingAnimation(true);
      this.tpService.archiveTemplate(csskey)
        .subscribe( value => {
          this.dataSource.deleteRow(item);
          this.tpService.showLoadingAnimation(false);
          this.tpService.removeItemFromCache(csskey);
          this.alertifyService.success("Template archived.");
        },
        error => {
          this.tpService.showLoadingAnimation(false);
          console.error("Archive template failed: " + error.message);
          this.alertifyService.error("Archive template failed: " + error.message);
        }
      );
    },
    () =>{// do nothing
    });
  }

  // download Xcelerators
  onDownloadTemplate(csskey: number, auditName: string){
    this.tpService.showLoadingAnimation(true);
    this.tpService.downloadTempalte(csskey, this.userId).subscribe(
      value => {
        this.tpService.showLoadingAnimation(false);
        //console.log("value = " + value)
        // Multiply files, pop the file list.
          const dialogRef = this.dialog.open(DownloadListDialog, {
            width: '720px',
            data: {
              audit: auditName,
              input: value
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            console.log("checked on: " + result);
          });

      },
      error =>{
        this.tpService.showLoadingAnimation(false);
        console.error(error.message);
        if(error.status == 404){
          this.alertifyService.error("Sorry, cannot find build result.");
        }else{
          this.alertifyService.error(error.message);
        }
      }
    )
  }


  // Unarchive template: call service to move item out of the archive data table.
  onUnarchiveTemplate(csskey: number, item: number){
      this.tpService.showLoadingAnimation(true);
      let tpItem = this.dataSource.getItem(csskey);// Get the template item
      this.tpService.unarchiveTemplate(csskey)
        .subscribe( value => {
          this.dataSource.deleteRow(item);
          if(tpItem) {
            this.tpService.addItemIntoCache(tpItem); // Put the template item into template list cache again.
          }
          this.tpService.showLoadingAnimation(false);
        },
        error => {
          console.error(error.message);
          this.tpService.showLoadingAnimation(false);
          this.alertifyService.error(error.error.title);
        }
      );
  }

  onEditClick(csskey: number){
    var myurl = 'audithub/apptool/new/' + csskey;
    this.router.navigateByUrl(myurl).then(nav => {
      console.log("Navigate to: " + myurl);
    }, err =>{
      console.log(err);
    });
  }
}


@Component({
  selector: 'download-list',
  templateUrl: './download-list-dialog.html',
  styleUrls: ['./apptool.component.css']
})
export class DownloadListDialog{
  filteredData: DownloadItem[];
  auditName: string;
  saveAs: string; // the file name when downloading at local.

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DownloadListDialog>)
    {
      this.auditName = data.audit;
      this.filteredData = this.FilterBuildResult(data.input);
    }


    // Filter build result: only keep the newest build result.
    FilterBuildResult(input :  string[]) : DownloadItem[]{
      var filtered : DownloadItem[] = [];
      input.forEach(x =>{
        var ftype = this.getType(x);
        var jurisdiction = this.getJurisdiction(x);
        var fdate = this.getDate(x);
        var fname = x;
        var saveAs = ftype + "-" + this.auditName + "-" + fdate + fname.substring(fname.indexOf("."));

        var item: DownloadItem = {ftype, jurisdiction, fdate, fname, saveAs};

        // To check if there is item that have the same jurisdiction & same build type.
        let finding = filtered.findIndex(i => i.ftype == ftype && i.jurisdiction == jurisdiction);

        if(finding === -1){ // No? add this item.
          filtered.push(item);
        }else{ // Yes? add the latest one.
          if(filtered[finding].fdate < fdate){
            filtered.splice(finding, 1, item);
          }
        }
      });
      return filtered;
    }


    getFilename(input: string){
      let ind = input.lastIndexOf("\\");
      return input.substring(ind + 1);
    }

    getDate(input:string){
      let fileType = this.getType(input);
      if(fileType === "Xcelerator"){
        var splitted = this.getFilename(input).split("-");
        return splitted[2] + "-" + splitted[3] + "-" +splitted[4];
      }else if(fileType == "RegRegister"|| fileType === "Vector"|| fileType === "csv"){
        var splitted = this.getFilename(input).split("-");
        return splitted[2] + "-" + splitted[3] + "-" + splitted[4].substring(0,2);
      }else{
        return "1900-01-01";
      }
    }

    /**
     *
     * @param input build file name
     * 1- Xcelerator: 25362-Fed-2022-06-16-XLS.zip
     * 2- Regregister: Citation-25749-2022-06-07-Fed.xlsx
     * 3- Vector: Vector-23359-2022-07-14-MS,KS,MT-XLS.zip
     * @returns
     */
    getJurisdiction(input: string){
      let fileType = this.getType(input);
      if(fileType === "Xcelerator"){
        var splitted = this.getFilename(input).split("-");
        if(splitted[1] === "none") return "Fed";
        return splitted[1];
      }else if(fileType == "RegRegister" || fileType == "Vector"|| fileType == "csv"){
        var splitted = this.getFilename(input).split("-");
        if(splitted.length <= 5){
          return "N/A";
        }else{
          if(fileType == 'RegRegister'|| fileType == "csv"){
            return splitted[5].substring(0, splitted[5].length - 5); //UT,CT,KY,LA,ND,PA,WA.xlsx
          }
          return splitted[5];
        }
      }
      else
        return "Not defined";
    }

    getType(input: string): string{
      let filename = this.getFilename(input);
      if(filename.startsWith("Citation")){
        return "RegRegister";
      }else if(filename.startsWith("Vector")){
        return "Vector";
      }else if(filename.startsWith("Csv")){
        return "csv";
      }
      else {
        return "Xcelerator";
      }
    }

    downloadFile(filename: string){
      //console.log("to download " + filename);
      window.location.assign("xcelerator-builds/" + filename); //
    }

    close(): void {
      this.dialogRef.close();
    }
  }

interface DownloadItem{
  ftype: string;
  jurisdiction: string;
  fdate: string;
  fname: string;
  saveAs: string;
}