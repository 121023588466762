import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,  ReactiveFormsModule} from '@angular/forms';
import { DialogSaveComparisonDialog, RegcompareComponent,RegcompareDisplay } from './regcompare.component';
import {RcSearchFilterComponent } from '../rc-search-filter/rc-search-filter.component';
import {TopicTreeDialog} from '../rc-search-filter/topic-tree.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { ChecklistDatabase } from '../rc-search-filter/TopicTree.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {OverlayPanelModule} from 'primeng/overlaypanel';


@NgModule({
  entryComponents:[RegcompareDisplay, TopicTreeDialog],
  exports:[RcSearchFilterComponent],
  declarations: [RegcompareComponent, RegcompareDisplay,  RcSearchFilterComponent,TopicTreeDialog, DialogSaveComparisonDialog],
  imports: [
    CommonModule,AppMaterialModule,MatExpansionModule, OverlayPanelModule, FormsModule,ReactiveFormsModule
  ],
  providers: [ChecklistDatabase]
})
export class RegcompareModule { }


