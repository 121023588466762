import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {Observable, BehaviorSubject} from 'rxjs';
import { tap} from 'rxjs/operators';
import { Account, Auth} from '../_models';
import {ApiService} from '../core/api.service';
import {localStorageKeys} from '../_constants';
import { UserProfileApiService, UserProfileSettings } from '@stp/_common/user-profile-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading = false;
  redirectUrl: string;

  get isLoggedIn() {
    if(localStorage.getItem(localStorageKeys.authToken)){
      this.loggedIn.next(true);
    }
     return this.loggedIn.asObservable();
  }

  constructor
  (private apiService: ApiService, private router: Router
  )
  {}

  public login(loginInfo: any) {
    return this.apiService.post(`Account/login`, {
      email: loginInfo.email,
      password: loginInfo.password,
      userId: loginInfo.userId??0,
      companyId: loginInfo.companyId??0
    })

   .pipe(tap(token => this.apiService.setAuthorizationHeader(token)),
       tap(isSuccess => this.loggedIn.next(isSuccess))
      );
  }

  public showLoadingAnimation(showFlag: boolean){
    this.loading = showFlag;
  }

  public getLoadingStatus() {
    return this.loading;
  }

  public getLatestPubList(){
    var url = "Publication/GetLatest";
    return this.apiService.get(url);
  }

  public getLatestTemplate(){
    var url = "Apptool/GetLatestTemplate";
    return this.apiService.get(url);
  }

  public signOut() {
    this.loggedIn.next(false)
    this.apiService.clearAuthorizationHeader();
    this.apiService.clearCookie();
    let redirectUrl = sessionStorage.getItem(UserProfileSettings.logoutURL);
    //localStorage.clear();
    UserProfileApiService.clearSessionStorage();
    //sessionStorage.clear();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
    else {
      this.router.navigateByUrl("/");
    }
  }

  public forgotPassword(email: string) {
    return this.apiService.post(`Account/forgot-password`, {
      email: email,
    });
  }

  public verifyForgotPassword(email: string, token: string) {
    return this.apiService.post(`Account/verify-forgot-password`, {
      email: email,
      token: token
    });
  }

  public setNewPassword(email: string, token: string, password: string) {
    return this.apiService.post(`Account/set-new-password`, {
      email: email,
      token: token,
      password: password
    });
  }

   public static get authorized() {
    return !!localStorage.getItem(localStorageKeys.authToken);
  }

  public static get currentUser(): Account {
    const accountString = localStorage.getItem(localStorageKeys.account);
    return accountString ? JSON.parse(accountString) : null;
  }
}