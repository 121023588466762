export const Roles = {
  stpAdmin: 'STP',
  chief: 'Chief',
  auditor: 'Auditor'
};

export const Permissions = {
  navCompanies: 'nav-companies',
  navUsers: 'nav-users',
  navQuestionnaire: 'nav-questionnaire',
  btnQuestionnaireAssign: 'btn-questionnaire-assign',
  btnQuestionnaireDelete: 'btn-questionnaire-delete',
  btnQuestionnaireAdd: 'btn-questionnaire-add',
  questionnaireResult: 'questionnaire-result',
  questionnaireSetup: 'questionnaire-setup',
  questionnaireSettings: 'questionnaire-settings',
  questionnaireSection: 'questionnaire-section',
  questionnaireCustomerType: 'questionnaire-customer-type',
  questionnaireActions: 'questionnaire-actions',
  questionnaireDownload: 'questionnaire-download',
};


export const PermissionsInRole = {
  STP: {
    text: 'STP Admin',
    key: Roles.stpAdmin,
    permissions: [
      Permissions.navCompanies,
      Permissions.navUsers
    ]
  },
  Chief: {
    text: 'Chief Auditor',
    key: Roles.chief,
    permissions: [
      Permissions.navUsers,
      Permissions.navQuestionnaire,
      Permissions.btnQuestionnaireAssign,
      Permissions.btnQuestionnaireDelete,
      Permissions.btnQuestionnaireAdd,
      Permissions.questionnaireResult,
      Permissions.questionnaireSetup,
      Permissions.questionnaireSection,
      Permissions.questionnaireCustomerType,
      Permissions.questionnaireActions,
      Permissions.questionnaireSettings,
      Permissions.questionnaireDownload
    ]
  },

  Auditor: {
    text: 'Chief Auditor',
    key: Roles.auditor,
    permissions: [
      Permissions.navQuestionnaire,
      Permissions.questionnaireSetup,
      Permissions.questionnaireSection
    ]
  }
};


