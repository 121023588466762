<mat-card class="card-demo">
  <mat-card-title class="combination screen">

    <button mat-button (click)="toggleGuidenote()" class="title">
      <ng-container *ngIf="hideGuidenote"><mat-icon matTooltip="Show Guide Note"
        matTooltipClass="tooltip1">add</mat-icon></ng-container>
      <ng-container *ngIf="!hideGuidenote"><mat-icon  matTooltip="Hide Guide Note"
        matTooltipClass="tooltip1">remove</mat-icon></ng-container>

    </button>
    <ng-container *ngIf="hasState">
      <button mat-button (click)="showState()" class="title" [ngClass]="{'highlight': selectedState != null}">
        <mat-icon matTooltip="State" matTooltipClass="tooltip1">location_on</mat-icon>
      </button>
    </ng-container>
    <h5 class="title" *ngIf="selectedState">@{{selectedState}}</h5>
    <button mat-button (click)="printRulebook()" class="title">
      <mat-icon>print</mat-icon>
    </button>
  </mat-card-title>


  <!--mat-card-subtitle>{{this.message}}
  </mat-card-subtitle-->
  <mat-card-content class="large">
    <div fxLayout="column" fxFill>
      <div fxLayout fxFlex>
        <div class="one" fxFlex="70" fxLayoutAlign="start start">
          <app-rulebook-content
            [rb$]=this.rulebook$
          >
          </app-rulebook-content>
        </div>
        <div class="two" fxFlex="30" fxLayoutAlign="start start">
          <app-rulebook-list [csskey]=this.csskey
            [rbList]=this.rulebookList
            [selected]=this.rbkey
            (selectedkey)="onClickRulebook($event)"
             class="list-rulebook">
          </app-rulebook-list>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer class="bottomPad">
    <div class="hint"></div>
  </mat-card-footer>
</mat-card>
<div class="spinner-container" *ngIf="service.loading">
  <mat-spinner></mat-spinner>
</div>