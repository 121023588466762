<div class="audit-content"  fxLayout fxFlex>
  <div  class="audit-rb" fxFlex="85">
      <ul class="nav nav-tabs">
          <li [ngClass]="{'active': displayScoring}">
              <a [routerLink]=""  (click)="onSwitch(true)">Audit Scoring</a>
          </li>
          <li [ngClass]="{'active': !displayScoring}">
            <a [routerLink]=""  (click)="onSwitch(false)">Severity Ranking</a>
        </li>
      </ul>
      <ng-container *ngIf="displayScoring">
        <div *ngIf="selectedTemplateKey != '0' && selectedUserId == currentUserId" class="oneLine">
          <mat-form-field class="score-name">
            <input matInput type="text" [(ngModel)]="newScoreName" placeholder="Add custom scoring element">
          </mat-form-field>
          <mat-form-field class="score-value" >
            <input matInput type="number" [formControl]="scoreValueControl" placeholder="Score">
            <mat-error *ngIf="scoreValueControl.hasError('min')">Score must be greater or equal to zero.</mat-error>
            <mat-error *ngIf="scoreValueControl.hasError('required')">Score value is required.</mat-error>
          </mat-form-field>
          <button mat-button [disabled]="newScoreName == undefined || scoreValueControl.hasError('min') ||scoreValueControl.hasError('required')"
            class="score-add" matSuffix mat-icon-button aria-label="Clear" (click)="onAddScoring(newScoreName,scoreValueControl.value)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div>
          <div class="table-content" *ngFor="let ac of scoringList">
            <span>{{ac.ScoringName}}</span>
            <div class="pull-right">
              <span>Score:</span><input class="score-value" [value]="ac.ScoringValue" disabled/>
              <input  *ngIf="selectedTemplateKey != 0"
               class="rankingcolor"
                [colorPicker]="ac.HexColor"
                [style.background]="ac.HexColor"
                [cpOKButton]="true"
                [disabled] = "selectedUserId != currentUserId"
                [cpSaveClickOutside]="false"
                [cpOKButtonClass]= "'btn btn-primary btn-xs'"
                (colorPickerSelect)="onColorChange($event, ac.ScoringKey)"
               />
               <input  *ngIf="selectedTemplateKey == 0"
                class="rankingcolor"
                [style.background]="ac.HexColor"
                (click)="onDefaultRank()"
               />
              <button *ngIf="this.selectedTemplateKey != 0" matTooltip="edit"
                matTooltipClass="tooltip1" class="button-action" mat-button [disabled] = "selectedUserId != currentUserId"
                (click)="onEditScoring(ac.ScoringKey)">
                <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
              </button>
              <button  *ngIf="this.selectedTemplateKey != 0" matTooltip="delete" [disabled] = "selectedUserId != currentUserId"
                matTooltipClass="tooltip1" class="button-action" mat-button
                (click)="onDeleteScoring(ac.ScoringKey)" >
                <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!displayScoring">
        <div class="alert alert-info">
					<strong>Please note:</strong>
						You are unable to add more than 10 items of severity at this time.  Please delete some before adding new items.
					<br />
        </div>
        <div *ngIf="(!rankingList || (rankingList && rankingList.length < 10)) && selectedUserId == currentUserId" class="oneLine">
          <mat-form-field class="one-row">
            <input matInput type="text" [(ngModel)]="newRank" placeholder="Add custom ranking element">
          </mat-form-field>

          <button mat-button [disabled]="newRank == undefined" class="" matSuffix mat-icon-button aria-label="Clear" (click)="onAddRank(newRank)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <ng-container *ngIf="rankingList != undefined">
          <ul class="table-content" *ngFor="let ac of rankingList">
            <li>
              {{ac.RankingName}}
              <div class="pull-right">
                <input  *ngIf="ac.RankingKey != 0"
                 class="rankingcolor"
                  [colorPicker]="ac.HexColor"
                  [style.background]="ac.HexColor"
                  [cpOKButton]="true"
                  [cpSaveClickOutside]="false"
                  [disabled] = "selectedUserId != currentUserId"
                  [cpOKButtonClass]= "'btn btn-primary btn-xs'"
                  (colorPickerSelect)="onColorChange($event, ac.RankingKey)"
                 />
                 <input  *ngIf="ac.RankingKey == 0"
                  class="rankingcolor"
                  [style.background]="ac.HexColor"
                  (click)="onDefaultRank()"
                 />
                <button *ngIf="ac.RankingKey != 0" matTooltip="edit"  [disabled] = "selectedUserId != currentUserId"
                  matTooltipClass="tooltip1" class="button-action" mat-button
                  (click)="onEditRanking(ac.RankingKey)">
                  <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                </button>
                <button  *ngIf="ac.RankingKey != 0" matTooltip="delete"  [disabled] = "selectedUserId != currentUserId"
                  matTooltipClass="tooltip1" class="button-action" mat-button
                  (click)="onDeleteRanking(ac.RankingKey)" >
                  <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                </button>
              </div>
            </li>
          </ul>
        </ng-container>
      </ng-container>
  </div>
</div>
