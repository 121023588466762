<mat-card class="card-demo">
    <mat-card-title>
      <h2>Audit Template: {{auditName}}<b *ngIf = "!sharePermission">(View Only)</b></h2>
    </mat-card-title>
    <mat-card-content class="large">
      <div fxLayout="column" fxFill>
        <div fxLayout fxFlex>
          <div class="one" fxFlex="70" fxLayoutAlign="start start">
            <app-answer-question
              [appSect$]=this.appSect$
              [rbName]=this.selectedRbName
              [editable] = this.sharePermission
              (answerApp)="onAnswerApp($event)">
            </app-answer-question>
          </div>
          <div class="two" fxFlex="30" fxLayoutAlign="start start">
            <app-list-rulebook [csskey]=this.csskey
              [auditName]=this.auditName
              [rbList]=this.rulebookList
              [selected]=this.appkey
              (selectedAppkey)="onClickRulebook($event)"
               class="list-rulebook">
            </app-list-rulebook>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer class="bottomPad">
      <div class="hint"></div>
    </mat-card-footer>
</mat-card>
<div class="spinner-container" *ngIf="service.loading">
  <mat-spinner></mat-spinner>
</div>