<h5 class="dialog-header" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <span>AuditHub</span><button mat-button [mat-dialog-close]=""  cdkFocusInitial><mat-icon>close</mat-icon></button></h5>
<div class="button-row">
  <button mat-button (click)="printReg()"><mat-icon>print</mat-icon></button>
  <button mat-button (click) = "exportexcel()"><mat-icon>file_download</mat-icon></button>
</div>
<mat-dialog-content class="dialog-content" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
<div mat-dialog-content >

  <div class="table-print">
    <table class="mat-table regcompare" id="excel-table">
      <thead>
        <tr class="mat-header">
          <th class="t1">Topic</th>
          <th class="t2">Federal</th>
          <th class="t2" *ngFor = "let state of states">{{state}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor = "let rbdata of compareData">
          <tr *ngFor = "let para of rbdata.rbState" class="mat-row">
            <td class="t1">{{rbdata.rb}} -   {{para.paraNum}}</td>
            <td class="t2" [innerHtml] = "para.question"></td>
              <ng-container *ngFor = "let st of para.paraSd">
                <td class="t2" [innerHtml]="st.stateDiff"></td>
              </ng-container>

          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button class="button-close" cdkFocusInitial>Close</button>
</div>
</mat-dialog-content>