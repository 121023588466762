<div *ngIf="rb$ | async as acs" class="screen">
  <div class="rulebook-content">
    <div class="button-line">
      <h3>Rulebook:&nbsp;{{acs.rbName}}</h3>
    </div>
    <div [innerHTML]="acs.summary"></div>
    <mat-card *ngFor="let sect of acs.sect" class="section-level">
      <mat-card-title>Section&nbsp;{{sect.rbSectNum}}:&nbsp;{{sect.rbSectName}}</mat-card-title>
      <mat-card-content>
        <ng-container *ngIf="sect.sectionStateDifference">
          <div *ngFor = "let sectsd of sect.sectionStateDifference" class="statediff">
            <div [innerHTML]="sectsd"></div>
          </div>
        </ng-container>
        <div *ngFor="let para of sect.para">
          <div *ngIf="para.question" class="paragraph-content">
            <span>Paragraph&nbsp;{{para.paraNum}}</span>
            <div [innerHTML]="para.question"></div>
          </div>
          <ng-container *ngIf="para.paraStateDifference">
            <div *ngFor = "let parasd of para.paraStateDifference" class="statediff">
              <div [innerHTML]="parasd"></div>
            </div>
          </ng-container>
          <p [ngClass]="{'hideguide': hideGuidenote}" class="guidenote" [innerHTML]="para.guideNote"></p>
          <mat-divider></mat-divider>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div *ngIf="rb$ | async as acs" class="print">
  <div class="rulebook-content">
    <div class="button-line">
      <h2>{{auditName}}</h2>
      <h4>{{acs.rbName}}</h4>
    </div>
    <div class="summary">
      <div [innerHTML]="acs.summary"></div>
    </div>

    <mat-card *ngFor="let sect of acs.sect" class="section-level">
      <mat-card-title>
        <div fxLayout="column" fxFill>
          <div fxLayout fxFlex>
            <div fxFlex="70" class="one">Section&nbsp;{{sect.rbSectNum}}:&nbsp;{{sect.rbSectName}}</div>
            <div fxLayout fxFlex="30" class="equally">
              <div fxFlex="30">N/A</div>
              <div fxFlex="30">Comply</div>
              <div>Not Comply</div>
            </div>
          </div>
        </div>
      </mat-card-title>
      <mat-card-content>
        <ng-container *ngIf="sect.sectionStateDifference">
          <div *ngFor = "let sectsd of sect.sectionStateDifference" class="statediff">
            <div [innerHTML]="sectsd"></div>
            <div>N/A</div>
            <div>Comply</div>
            <div>Not comply</div>
          </div>
        </ng-container>
        <div *ngFor="let para of sect.para">
          <div *ngIf="para.question" class="paragraph-content">
            <div fxLayout="column" fxFill>
              <div fxLayout fxFlex>
                <div class="para" fxFlex="70" [innerHTML]="para.paraNum + '&nbsp;' + para.question"></div>
                <div fxLayout fxFlex="30" class="equally">
                  <mat-checkbox fxFlex="30"></mat-checkbox><mat-checkbox  fxFlex="30"></mat-checkbox><mat-checkbox></mat-checkbox>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="para.paraStateDifference">
            <div *ngFor = "let parasd of para.paraStateDifference" class="statediff">
              <div [innerHTML]="parasd"></div>
              <div>N/A</div>
              <div>Complies</div>
              <div>Do not comply</div>
            </div>
          </ng-container>
          <p [ngClass]="{'hideguide': hideGuidenote}" class="guidenote" [innerHTML]="para.guideNote"></p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>