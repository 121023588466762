<div class="spinner-container" *ngIf="dataSource.loading">
    <mat-spinner></mat-spinner>
</div>

<mat-form-field class='pub-category'>
	<mat-label>Choose Category</mat-label>
	<mat-select [(value)]="selectedCat" placeholder="Category" (selectionChange)="onSelectCategory($event)">
	  <mat-option *ngFor="let category of categories" [value]="category.value">
		{{category.viewValue}}
	  </mat-option>
	</mat-select>
  </mat-form-field>
<div class="search-container">
	<mat-form-field class="search-pub">
		<input matInput type="text" placeholder="Search product" [(ngModel)]="searchWord" (keyup.Backspace)="cancelSearch()" (input)="searchInputEvent($event.target.value)">
	</mat-form-field>
	<button mat-button
		class="button-action"
		matTooltip="search"
		matTooltipClass="tooltip1"
		[disabled]=" !searchWord || searchWord.length === 0"
		(click)="searchProduct()">
		<mat-icon fontSet="material-icons-outlined">search</mat-icon>
	</button>
</div>

<div class="container1 mat-elevation-z8">
	<mat-table
		[dataSource]="dataSource"
		class="mat-elevation-z8"
		matSort matSortActive="title" matSortDisableClear matSortDirection="desc">

			<ng-container matColumnDef="title">
				<mat-header-cell *matHeaderCellDef>Product Title</mat-header-cell>
				<mat-cell *matCellDef="let row; let i = index" class="title-cell">
					<span>{{row.title}}</span>
					<button mat-button *ngIf="!row.old" (click)="expandArchive(row.acronym, row.title)">{{row.archived == 1 ? 'Archive' : 'Hide'}}</button>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="version">
				<mat-header-cell *matHeaderCellDef>Version</mat-header-cell>
				<mat-cell *matCellDef="let row">{{row.version}}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="lastUpdate">
				<mat-header-cell *matHeaderCellDef>Date Posted</mat-header-cell>
				<mat-cell *matCellDef="let row">{{row.lastUpdate}}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="pubId">
				<mat-header-cell *matHeaderCellDef>Release Notes<mat-icon class="help-info" (click)="openHelpInfo($event, 1, true)">info</mat-icon></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<ng-container *ngIf="(row.category == 'IP' || row.category == 'CAN-MEX')">
						<a *ngIf = "!['ACT','NT','NS','ISR','QLD','UAE'].includes(row.acronym) && (row.title.indexOf('Local') === -1)"
						href="publications\IP\IP{{row.acronym.indexOf('|') !== -1 ? row.acronym.substring(0, row.acronym.indexOf('|')) :row.acronym}}{{row.version.toString().substring(2)}}\IP{{row.acronym.indexOf('|') !== -1 ? row.acronym.substring(0, row.acronym.indexOf('|')) :row.acronym}}{{row.version.toString().substring(2)}}-RN-Marketing.pdf" target="_blank">View</a>
					</ng-container>

					<ng-container *ngIf="row.category != 'IP' && row.category != 'CAN-MEX'">
						<a *ngIf = "!['ERMM','OSS','SDEA-CA-Sum','SDOA-CA-Sum'].includes(row.acronym) || selected == 'Archive'"
						href="publications\{{row.category.toUpperCase()}}\{{row.acronym}}\{{row.acronym}}-RN-Marketing.pdf" target="_blank">View</a>
					</ng-container>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="formatAndPath">
				<mat-header-cell *matHeaderCellDef class="justify-end">Format<mat-icon class="help-info" (click)="openHelpInfo($event, 2,flase)">info</mat-icon></mat-header-cell>
				<mat-cell *matCellDef="let row" class="justify-end">
					<div class="dropdown-state">
						<mat-select *ngIf="hasState(row.pubId)" [(value)]="selected" placeholder="SD"
						(selectionChange)="onSelectStatePdf(row.pubId, row.acronym, $event)">
							<mat-option *ngFor="let state of getStates(row.pubId)" [value]="state">
							  {{state}}
							</mat-option>
						</mat-select>
						<button class="format-img" *ngFor = "let item of row.formatAndPath | jsonArray"
						(click)="openPublication(row.pubId, row.version, item.Format, row.title, item.Path, row.acronym)">
							<img src="/assets/images/Icons/{{item.Format}}-icon.png" />
						</button>
						<mat-select *ngIf="hasState(row.pubId)" [(value)]="selected" placeholder="SD"
						(selectionChange)="onSelectStateExcel(row.pubId, $event)">
							<mat-option *ngFor="let state of getStates(row.pubId)" [value]="state">
							  {{state}}
							</mat-option>
						</mat-select>
					</div>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="disabled">
				<mat-header-cell *matHeaderCellDef class="justify-end"></mat-header-cell>
				<mat-cell *matCellDef="let row" class="justify-end"></mat-cell>
			</ng-container>
			<ng-container matColumnDef="category">
				<mat-header-cell *matHeaderCellDef class="justify-end"></mat-header-cell>
				<mat-cell *matCellDef="let row" class="justify-end"></mat-cell>
			</ng-container>

			<ng-container matColumnDef="updating">
				<mat-header-cell *matHeaderCellDef class="justify-end"  [ngClass]="{'hideCol': selectedCat !== 'IP' && selectedCat !== 'CAN-MEX'}">Updating</mat-header-cell>
				<mat-cell *matCellDef="let row" class="justify-end" [ngClass]="{'hideCol': selectedCat !== 'IP' && selectedCat !== 'CAN-MEX'}">
					<button  mat-button class="purchase"
					*ngIf="row.acronym.indexOf('|') !== -1"
					(click)="purchase(row.acronym)">Purchase {{row.updating}} edition
				</button>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  			<mat-row
			  [ngClass]="{'archive-row': row.disabled && row.category === 'O', 'expanded': row.acronym === expandedAcronym}"
			  *matRowDef="let row; columns: displayedColumns;">
			</mat-row>

	</mat-table>
	<mat-paginator [length]="resultsLength" [pageSize]="50" [pageSizeOptions]="[10,20,50]"></mat-paginator>
</div>