<div class="spinner-container" *ngIf="service.loading">
  <mat-spinner></mat-spinner>
</div>

<div class="audit-header">
  <h2>International Protocol (IP) Request</h2>
  <p>To request IPs, tick the check box next to the countries of interest and hit the submit button. -
    <a href="publications/IPRequest/IPSchedule.pdf" class="fullistIPs" target="_blank"><b>click here</b></a>
    for a complete list of currently available IPs.
  </p>
  <div class="button-row">
    <button (click)="onSubmit()">Submit</button>
  </div>
</div>
<mat-accordion class="iprequest">
  <mat-expansion-panel *ngFor="let item of internationalProtocol">
    <mat-expansion-panel-header>
      <mat-panel-title>{{item.Category}}</mat-panel-title>
    </mat-expansion-panel-header>
      <div class="evenly">
        <mat-checkbox *ngFor="let ctry of item.Country" (change)="onCountryCheck($event, ctry.Id)">{{ctry.Name}}</mat-checkbox>
      </div>


  </mat-expansion-panel>
</mat-accordion>

 <!--
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="node-column">
        <button mat-icon-button disabled></button>
        <mat-checkbox>{{node.item}}</mat-checkbox>
      </mat-tree-node>



  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button
            [attr.aria-label]="'toggle ' + node.filename" matTreeNodeToggle>
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    {{node.item}}
    <mat-progress-bar *ngIf="node.isLoading"
                      mode="indeterminate"
                      class="example-tree-progress-bar"></mat-progress-bar>
  </mat-tree-node>
</mat-tree> -->
