import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {TemplateService} from '../apptool/service/template.service';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.css']
})
export class TransportComponent implements OnInit {
  topickey: string;
  f: string;

  constructor(private router : Router, public service: TemplateService) { }

  ngOnInit(): void {
    const params = new URL("http://audithub.stpehs.com" + this.router.url).searchParams;
    this.f = params.get('f');
    this.topickey = params.get('topickey');
    console.log("topickey = " + this.topickey);

    this.service.getPreauditAndIntro(this.topickey).subscribe({
      next: (v) => {
        console.log("server return: " + v.re);
        var address = v.re.split("||");
        if(this.f == "introduction"){
          console.log("navigate to " + "http://audithub.stpehs.com/" + address[1]);
          window.open("http://audithub.stpehs.com/publications/" + address[1], "_self");
        }else{
          console.log("navigate to " + "http://audithub.stpehs.com/publications/" + address[0]);
          window.open("http://audithub.stpehs.com/publications/" + address[0], "_self");
        }

      },
      error: (e) => console.error("server return error: " + e)
    });
  }



}
