/**
 * Dialog window to display the topic tree.
 */

 import { Component, OnInit, Inject } from '@angular/core';

 import { CookieService} from 'ngx-cookie-service';
 import { AppSettings } from '../_constants/AppSettings';
 import { AlertifyService } from '../_models/alertify.service';
 import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
 import { ChecklistDatabase } from './TopicTree.component';
 import {FlatTreeControl} from '@angular/cdk/tree';
 import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
 import { TodoItemFlatNode, TodoItemNode } from './TopicTree.component';
 import {SelectionModel} from '@angular/cdk/collections';
import { INPUT_MODALITY_DETECTOR_DEFAULT_OPTIONS } from '@angular/cdk/a11y';


 @Component({
  selector: './topic-tree',
  templateUrl: 'topic-tree.html',
  styleUrls: ['./topic-tree.css']
})
export class TopicTreeDialog {
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

   /** Map from flat node to nested node. This helps us finding the nested node to be modified */
   flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
   /** Map from nested node to flattened node. This helps us to keep the same object for selection */
   nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
   /** A selected parent node to be inserted */
   selectedParent: TodoItemFlatNode | null = null;
   /** The new item's name */
   newItemName = '';
  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';
  onDisplay = 1; // display Env Topic by default;
  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
   transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  selectedTopics: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<TopicTreeDialog>,
    private _database: ChecklistDatabase,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public  data: {selected: string[]},
    public cookieService: CookieService,
    private alertifyService: AlertifyService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this._database.dataChange.subscribe(data => {
      this.dataSource.data = data;} ) }

  ngOnInit(){
    this.data.selected.forEach(x => this.selectedTopics.push(x));
    this.initTree();
    this.treeControl.expand(this.treeControl.dataNodes[0]);
  }

  // Check the topics on the topic tree if the topic is checked.
  initTree(){
    if(this.data.selected.length == 0) return false;
    this.data.selected.forEach(x => {
      let node = this.treeControl.dataNodes.find(a => a.item == x);
      if(node == null) return false;
      const nodeSelected = this.checklistSelection.isSelected(node);
      if(!nodeSelected)
          this.todoItemSelectionToggle(node);
    });
  }

   /** Whether all the descendants of the node are selected. */
   descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  loadTree(treeType: number){
    this.onDisplay = treeType;
    if(treeType === 1){// Env topic
      this._database.dataChange.subscribe(data => {
        this.dataSource.data = data;} ) ;
        this.initTree();
    }else{ // OSHA topic
      this._database.dataChange2.subscribe(data => {
        this.dataSource.data = data;} ) ;
      this.initTree();
    }
    this.treeControl.expand(this.treeControl.dataNodes[0]);
  }

   /** Whether part of the descendants are selected */
   descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  printAllSelectedTopic(){
    var selected = this.checklistSelection.selected;
    this.selectedTopics = [];
    selected.forEach(x => {
      if(!x.expandable){
        //console.log("selected " + x.item);
        this.selectedTopics.push(x.item);
      }
    });
  }

   /** Toggle the to-do item selection. Select/deselect all the descendants node */
   todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.printAllSelectedTopic();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.printAllSelectedTopic();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  onConfirm(): void {
     this.dialogRef.close(this.selectedTopics);
  }

  onCancel(): void {
      this.dialogRef.close(null);
  }
}