<div class="dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <ng-container *ngIf="data === ''"><h4>Save Comparison Definition</h4></ng-container>
  <ng-container *ngIf="data !== ''"><h4>Update Comparison Definition</h4></ng-container>

  <mat-icon (click)="onCancelClick()" class="dialog-close">close</mat-icon>
</div>

<div mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="mat-form">
    <label>Comparison Name</label>
    <input id="cpName" value="{{data}}">
  </div>
</div>


<div mat-dialog-actions class="action-button">
  <button mat-button (click)="onCancelClick()" mat-dialog-close class="button-close">Cancel</button>
  <ng-container *ngIf="data === ''"><button mat-button (click)="onSaveComparison()" class="button-close" cdkFocusInitial>Save</button></ng-container>
  <ng-container *ngIf="data !== ''"><button mat-button (click)="onSaveComparison()" class="button-close" cdkFocusInitial>Update</button></ng-container>
</div>
