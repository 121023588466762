import { Component, OnInit,ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {TemplateService} from '../apptool/service/template.service';
import { AlertifyService } from '../_models/alertify.service';
import { PubViewTrack, FilterPubView } from '../apptool/model/TemplateList';
import { HelpInfoDialog} from '../_components/header/header.component';


@Component({
  selector: 'app-pubview-track',
  templateUrl: './pubview-track.component.html',
  styleUrls: ['./pubview-track.component.css']
})
export class PubviewTrackComponent implements OnInit {
  offset = 0;
  isLoading = false;
  formatType = "1";
  public temData: PubViewTrack[] = [];
  public cacheData: PubViewTrack[] = [];

  filterCheckboxDisabled = true; // no filter condtion is placed.
  hasFilter = false;
  startDate = new Date();
  toDate = new Date();
  showSearchCompany = false;

  companyList : string[];
  searchWord: string;
  notFound = false;
  previousSearchWord: string;
  loadCompany: string;
  loadCompanyId: string;
  pageStart = 0;
  previousPageItems = 0;

  constructor(
    public service: TemplateService,
    private alertifyService: AlertifyService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.startDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
    this.toDate = new Date();
    this.initCompany();
    this.getContent(0, this.startDate, this.toDate);
  }

  initCompany(){
    this.companyList = ["8 ERM", "20 STC", "36 AECOM",  "3314 Trinity Consultants", "4061 EHS Support"];
  }

  resetCompanyList(){
    this.companyList = [];
    this.temData.forEach(x => {
      if(x.company && !this.companyList.includes(x.company)){
        this.companyList.push(x.company);
      }
    });
    this.companyList.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
  }

  /**
   * When a start date keyed in, set the default value and min value for the end date Input.
   * @param input start date
   */
  OnStartDateChange(input: Date){
    const pa = this.getFilter();
    this.startDate = input;
    this.getContent(0, this.startDate, this.toDate);
  }

  OnToDateChange(input: Date){
    const pa = this.getFilter();
    this.toDate = input;
    this.getContent(0, this.startDate, this.toDate);
  }

  getDateString(theDay: Date): string {
    let getYear = theDay.toLocaleString("default", {year: "numeric"});
    let getMonth = theDay.toLocaleString("default", {month: "2-digit"});
    let getDay = theDay.toLocaleString("default", {day: "2-digit"});

    return getYear + getMonth + getDay;
  }

  getContent(page: number, startDate: Date, toDate: Date, filter? :FilterPubView){
    this.isLoading = true;
    this.service.getPubView(page, this.getDateString(startDate) + this.getDateString(toDate), filter).subscribe({
      next: (v) => {
        this.isLoading = false;
        if(v.length == 0){
          this.alertifyService.warning("No data found.");
          this.temData = [];
          return;
        }
        this.temData = v;
        if(filter?.acronym) {
          this.resetCompanyList();
        }
        this.cacheData = v;
        this.filterByFormat(this.formatType);
       },
       error: (e) => {
        this.isLoading = false;
        console.error("got an error:" + e.error);
        this.service.showLoadingAnimation(false);
        this.alertifyService.error(e.error);
       }
      });
  }

  // To load the latest data.
  onReload(){
    const pa = this.getFilter();
    this.getContent(this.offset, this.startDate, this.toDate, pa);
  }

  // next: ture - load next page; false - load previous page.
  loadNext(next: boolean){
    if(next){
      this.offset++;
      this.previousPageItems = this.temData.length;
      this.pageStart += this.previousPageItems;

    }else{
      if(this.offset == 0) {
        this.pageStart = 0;
        return; //cannot load previous page as having been on the first page already.
      }
      this.offset--;
      if(this.offset == 0)
        this.pageStart = 0;
      else
        this.pageStart -= this.previousPageItems;
    }

    const pa = this.getFilter();
    this.getContent(this.offset, this.startDate, this.toDate, pa);
  }


  // Search for client name
  onSearchClient():void{
    const input =  document.getElementById("clientName") as HTMLInputElement;
    if(input.value.length == 0) return;

    this.offset = 0;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;
    var pa = this.getFilter();
    //this.getContent(0, pa);
  }

   // Search for client name when the customer delete the original inputs.
   onSearchInputBackspace():void{
    const input =  document.getElementById('acronym') as HTMLInputElement;
    if(input.value.length == 0){
      return;
    }
    this.temData = this.cacheData;
    this.temData = this.temData.filter(x => x.acronym.toLocaleLowerCase().includes(input.value.toLocaleLowerCase()));
  }

  // Client name input event.
  onClientInputEvent(inputValue: string): void{
    if(inputValue.length == 0){
      this.temData = this.cacheData;
      this.cacheData = [];
      return;
    }
    if(this.cacheData.length == 0){
      this.cacheData = this.temData;
    }

   // this.temData = this.temData.filter(x => x.clientName.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
  }

  // Load publication view for the company
  loadForCompany(company: string){
    this.offset = 0;
    this.showSearchCompany = false;
     const index = company.indexOf(" ");
    this.loadCompanyId = company.substring(0, index);
    this.loadCompany = company.substring(index + 1);
    const input =  document.getElementById("acronym") as HTMLInputElement;

    if(this.hasFilter && input.value.length > 0){
      this.temData = this.cacheData.filter(x => x.company.toLocaleLowerCase().includes(this.loadCompany.toLocaleLowerCase()));
      this.filterByFormat(this.formatType);
    }else{
      this.hasFilter = true;
      this.filterCheckboxDisabled = false;
      this.getContent(0, this.startDate, this.toDate, this.getFilter());
    }
  }

  onSearchCompanyEnter(){
    this.offset = 0;

    if(this.companyList.length > 0){
      this.loadForCompany(this.companyList[0]);
    }
  }

  onSearchCompany(inputValue: string): void{
    if(this.notFound && inputValue.length >= this.previousSearchWord.length) {
      return; // It's no point to searh further.
    }
    this.previousSearchWord = inputValue;

    this.searchCompany();
  }

  searchCompany(){
    if(!this.searchWord || this.searchWord.length === 0 || this.searchWord.trim() === ""){
      this.notFound = false;
      this.initCompany();
      return false;
    }

    this.service.getCompany(this.searchWord).subscribe({
      next: (v) => {
        this.companyList = [];
        v.forEach(element => {
          this.companyList.push(element.key + " " + element.value);
        });
       },
       error: (e) => console.error(e)
    });

    this.notFound = false;
  }

  // Product input event.
  onProductInputEvent(inputValue: string): void{
    if(inputValue.length == 0){
      this.temData = this.cacheData;
      this.cacheData = [];
      return;
    }
    if(this.cacheData.length == 0){
      this.cacheData = this.temData;
    }

    this.temData = this.temData.filter(x => x.acronym.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
  }


  // Search for product
  onSearchProduct():void{
    const input =  document.getElementById("acronym") as HTMLInputElement;
    if(input.value.length == 0){
      return;
    }

    this.offset = 0;
    this.pageStart = 0;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;
    var pa = this.getFilter("search");
    this.getContent(0, this.startDate, this.toDate,  pa);
  }

  // Load pub view detail for a product
  loadAcronym(acronym: string){
    if(acronym.indexOf("- ") !== -1) return; // On load acronym page already, do nothing.
    const ind = acronym.indexOf("|");
    if(ind !== -1){
      acronym = acronym.substring(0, ind);
    }
    const input =  document.getElementById("acronym") as HTMLInputElement;
    input.value = acronym;
    this.offset = 0;
    this.pageStart = 0;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;
    let pa = this.getFilter();
    this.getContent(0, this.startDate, this.toDate,  pa);
  }


  //  filter by format type
  onTypeChange(formatType: string){
    if(formatType === "1"){ // display all format type.
      this.temData = this.cacheData;
      const acronym =  document.getElementById("acronym") as HTMLInputElement;
      this.formatType = formatType;
      if(!this.loadCompanyId && !acronym.value){
        this.hasFilter = false;
        this.filterCheckboxDisabled = true;
      }
      return false;
    }
    this.formatType = formatType;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;

    if(this.hasFilter){
      this.filterByFormat(formatType);
    }else{
      this.hasFilter = true;
      this.filterCheckboxDisabled = false;
      this.filterByFormat(formatType);
    }
  }

  filterByFormat(format: string){
    if(format === "doc"){
      this.temData = this.cacheData.filter(x => (x.format !== "xls" && x.format !== "pdf"));
    }else if(format === "xls" || format === "pdf"){
      this.temData = this.cacheData.filter(x => x.format == format);
    }else{
      // do nothing.
    }
  }


  // Cancel all filter settings and load DATA from page 1.
  onFilterCheck(){
    this.filterCheckboxDisabled = true;
    this.hasFilter = false;
    this.offset = 0;
    this.pageStart = 0;
    this.clearAllInputValue();
    this.getContent(0, this.startDate, this.toDate, this.getFilter());
  }

  // Clear all the input box.
  clearAllInputValue(){
    //const clientName =  document.getElementById("client") as HTMLInputElement;
    //clientName.value = "";
    this.pageStart = 0;
    const company =  document.getElementById("company") as HTMLInputElement;
    company.value = "";
    this.loadCompany = "";
    this.loadCompanyId = "";
    this.formatType = "1";
    this.initCompany();

    const audit =  document.getElementById("acronym") as HTMLInputElement;
    audit.value = "";
  }

  // click mouse to hide the search box.
  collapseSearch(event){
    if(event.target.tagName == "MAT-ICON") return false;
    if(event.target.tagName == "SPAN") return false;
    if(event.target.tagName == "BUTTON") return false;
    if(event.target.tagName == "INPUT") return false;
    this.showSearchCompany = false;
  }


  // Get the current filter combination.
  getFilter(searchFlag?: string): FilterPubView | undefined {
    const acronym =  document.getElementById("acronym") as HTMLInputElement;

    if( acronym.value ||  this.loadCompany){
      const pa: FilterPubView = {
        acronym: acronym.value,
        client: searchFlag??"",
        companyId: this.loadCompanyId
      };
      return pa;
    }else{
      return undefined;
    }
  }
}