import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {
	@Output() userAdded = new EventEmitter();
	userForm: FormGroup;

  constructor() { }

  ngOnInit() {
  	 this.userForm = new FormGroup({
            name: new FormControl('', Validators.required),
            email: new FormControl('', Validators.required),
            role: new FormControl('', Validators.required)
        });
  }

  addUser() {
  	const userToAdd = this.userForm.value;
  	this.userAdded.emit(userToAdd);
  }

}
