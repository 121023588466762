<mat-card class="card-demo">
  <mat-card-title class="l8r2">
    <h2></h2>
    <button mat-button class="button-action"
      (click)="backToHome()"
      matTooltip="Back to RegApply home"
      matTooltipClass="tooltip1">
      <mat-icon>west</mat-icon>
    </button>
  </mat-card-title>
  <mat-card-subtitle id="subtitle">
    <div fxLayout="column" fxFill>
      <div fxLayout fxFlex>
        <div class="one" fxFlex="68" fxLayoutAlign="space-between center">
          <span>Custom Template: {{this.selectedTemplateName}}</span>
          <div class="button-line">
            <button class="button-action" mat-button matTooltip="New Custom Template"
              matTooltipClass="tooltip1"
              (click)="onNewTemplate()"><mat-icon>add</mat-icon>
            </button>
            <button
              *ngIf="selectedTemplateKey != 0"
              mat-button matTooltip="Edit Custom Template"
              (click)="onEditTemplate()" matTooltipClass="tooltip1"><mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="selectedTemplateKey != 0" mat-button matTooltip="Delete Custom Template"
              (click)="onDeleteTemplate()" matTooltipClass="tooltip1"><mat-icon>delete</mat-icon>
            </button>

          </div>
        </div>
        <div class="two" fxFlex="32" fxLayoutAlign="start start">

        </div>
      </div>
    </div>
  </mat-card-subtitle>
  <mat-card-content class="large">
    <div fxLayout="column" fxFill>
      <div fxLayout fxFlex>
        <div class="one" fxFlex="80" fxLayoutAlign="start start">
          <app-edit-scoring
            [scoringList] = this.customScoring
            [rankingList] = this.customRanking
            [selectedTemplateKey] = this.selectedTemplateKey
            [selectedUserId] = this.selectedUserId
            (loadContents)="onLoadContents($event)"
            (addRankingItem)="onAddRankingItem($event)"
            (addScoringItem)="onAddScoringItem($event)"
            class="edit-custom">
          </app-edit-scoring>
        </div>
        <div class="two" fxFlex="20" fxLayoutAlign="start start">
          <app-list-scoring
            [templateList]=this.customTemplateList
            [currentItem] = this.selectedTemplateKey
            (selectedItem)="onClickTemplate($event)"
             class="list-custom">
          </app-list-scoring>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer class="bottomPad">
    <div class="hint"></div>
  </mat-card-footer>
</mat-card>
<div class="spinner-container" *ngIf="service.loading">
  <mat-spinner></mat-spinner>
</div>
