import { Component, OnInit,ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {TemplateService} from '../apptool/service/template.service';
import { AlertifyService } from '../_models/alertify.service';
import { BuildingStatus, FilterString } from '../apptool/model/TemplateList';
import { HelpInfoDialog} from '../_components/header/header.component';


@Component({
  selector: 'app-build-status',
  templateUrl: './build-status.component.html',
  styleUrls: ['./build-status.component.css']
})
export class BuildStatusComponent implements OnInit {
  offset = 0;
  isLoading = false;
  buildType = "";
  public temData: BuildingStatus[] = [];
  public cacheData: BuildingStatus[] = [];
  buildingStatus = "";
  filterCheckboxDisabled = true; // no filter condtion is placed.
  hasFilter = false;

  constructor(
    public service: TemplateService,
    private alertifyService: AlertifyService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getContent(0);
  }

  getContent(page: number, filter? :FilterString){
    if(page > 20){
      this.alertifyService.confirmOk("Please contact IT team to retreive more infomation.");
      return;
    }
    this.isLoading = true;
    this.service.getBuildingStatus(page, filter).subscribe(
      res => {
        this.isLoading = false;
        if(res.length == 0){
          this.alertifyService.warning("No data found.");
          this.temData = [];
          return;
        }
        this.temData = res;
      },
      err =>{
        this.isLoading = false;
        console.error("got an error:" + err.error);
        this.service.showLoadingAnimation(false);
        this.alertifyService.error(err.error);
      }
    );
  }

  // To load the latest data.
  onReload(){
    const pa = this.getFilter();
    this.getContent(this.offset, pa);
  }

  // next: ture - load next page; false - load previous page.
  loadNext(next: boolean){
    if(next){
      this.offset++;
    }else{
      if(this.offset == 0) return; //cannot load previous page as having been on the first page already.
      this.offset--;
    }

    const pa = this.getFilter();
    this.getContent(this.offset, pa);
  }

   /**
   * Display dialog for help information. HelpInfoDialog is defined
   * @param evt
   * @param idx
   */
  openHelpInfo(evt: MouseEvent, idx: number): void {
      const target = new ElementRef(evt.currentTarget);
      this.dialog.open(HelpInfoDialog, {
        width: '280px',
        data: {
          trigger: target,
          helperIndex: idx
        }
      });
  }


  // Search for client name
  onSearchClient():void{
    const input =  document.getElementById("clientName") as HTMLInputElement;
    if(input.value.length == 0) return;

    this.offset = 0;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;
    var pa = this.getFilter();
    this.getContent(0, pa);
  }

   // Search for client name when the customer delete the original inputs.
   onSearchInputBackspace(inputId: string):void{
    const input =  document.getElementById(inputId) as HTMLInputElement;
    if(input.value.length == 0){
      return;
    }
    this.temData = this.cacheData;
    switch(inputId){
      case "clientName":
        this.temData = this.temData.filter(x => x.clientName.toLocaleLowerCase().includes(input.value.toLocaleLowerCase()));
        break;
      case "companyName":
        this.temData = this.temData.filter(x => x.company.toLocaleLowerCase().includes(input.value.toLocaleLowerCase()));
        break;
      case "auditName":
        this.temData = this.temData.filter(x => x.auditName.toLocaleLowerCase().includes(input.value.toLocaleLowerCase()));
        break;
      case "csskey":
        this.temData = this.temData.filter(x => x.csskey.toString().toLocaleLowerCase().startsWith(input.value.toLocaleLowerCase()));
        break;
      default:
        return;
    }
  }

  // Client name input event.
  onClientInputEvent(inputValue: string): void{
    if(inputValue.length == 0){
      this.temData = this.cacheData;
      this.cacheData = [];
      return;
    }
    if(this.cacheData.length == 0){
      this.cacheData = this.temData;
    }

    this.temData = this.temData.filter(x => x.clientName.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
  }

  // Search for company.
  onSearchCompany():void{
    const input =  document.getElementById("companyName") as HTMLInputElement;

    if(input.value.length == 0) return;

    this.offset = 0;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;
    var pa = this.getFilter();
    this.getContent(0, pa);
  }

  // Company input event.
  onCompanyInputEvent(inputValue: string): void{
    if(inputValue.length == 0){
      this.temData = this.cacheData;
      this.cacheData = [];
      return;
    }
    if(this.cacheData.length == 0){
      this.cacheData = this.temData;
    }

    this.temData = this.temData.filter(x => x.company.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
  }


  // Search for audit name
  onSearchAudit():void{
    const input =  document.getElementById("auditName") as HTMLInputElement;
    if(input.value.length == 0) return;

    this.offset = 0;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;
    var pa = this.getFilter();
    this.getContent(0, pa);
  }

  // Audit name input event.
  onAuditInputEvent(inputValue: string): void{
    if(inputValue.length == 0){
      this.temData = this.cacheData;
      this.cacheData = [];
      return;
    }
    if(this.cacheData.length == 0){
      this.cacheData = this.temData;
    }

    this.temData = this.temData.filter(x => x.auditName.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
  }

  // Search for csskey
  onSearchCsskey():void{
    const input =  document.getElementById("csskey") as HTMLInputElement;
    if(input.value.length == 0) return;

    this.offset = 0;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;
    var pa = this.getFilter();
    this.getContent(0, pa);
  }

  // Csskey input event.
  onCsskeyInputEvent(inputValue: string): void{
    if(inputValue.length == 0){
      this.temData = this.cacheData;
      this.cacheData = [];
      return;
    }
    if(this.cacheData.length == 0){
      this.cacheData = this.temData;
    }

    this.temData = this.temData.filter(x => x.csskey.toString().includes(inputValue.toLocaleLowerCase()));
  }


  //  Query on build type
  onTypeChange(buildType: string){
    this.buildType = buildType;
    this.offset = 0;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;
    var pa = this.getFilter();
    this.getContent(0, pa);
  }

  // Query on building status
  onStatusChange(buildingStatus: string){
    this.buildingStatus = buildingStatus;
    this.offset = 0;
    this.filterCheckboxDisabled = false;
    this.hasFilter = true;
    var pa = this.getFilter();
    this.getContent(0, pa);

  }

  // Cancel all filter settings and load all building status from page 1.
  onFilterCheck(){
    this.filterCheckboxDisabled = true;
    this.hasFilter = false;
    this.buildType = "";
    this.buildingStatus = "";
    this.offset = 0;

    this.clearAllInputValue();
    this.getContent(0);
  }

  // Clear all the input box.
  clearAllInputValue(){
    const clientName =  document.getElementById("clientName") as HTMLInputElement;
    clientName.value = "";

    const companyName =  document.getElementById("companyName") as HTMLInputElement;
    companyName.value = "";

    const auditName =  document.getElementById("auditName") as HTMLInputElement;
    auditName.value = "";

    const csskey =  document.getElementById("csskey") as HTMLInputElement;
    csskey.value = "";
  }

  // Rebuild the template
  rebuild(buildId: number){
    this.alertifyService.confirmOkCancel("STP AuditHub", "Are you sure you want to rebuild the template?",
    () =>{
      this.service.rebuildTemplate(buildId).subscribe({
        next: (v) =>{
          var item = this.temData.find(x => x.id == buildId);
          if(item !== null){
            item!.buildStatus = 0;
            item!.builder = "";
          }
        },
        error:(e) =>{},
        complete: ()=> console.log("complete.")
      });
    },
    () =>{// do nothing
    });
  }

  // Get the current filter combination.
  getFilter(): FilterString{
    const clientName =  document.getElementById("clientName") as HTMLInputElement;
    const csskey =  document.getElementById("csskey") as HTMLInputElement;
    const auditName =  document.getElementById("auditName") as HTMLInputElement;
    const companyName =  document.getElementById("companyName") as HTMLInputElement;

    const pa: FilterString = {
      csskey: csskey.value,
      buildStatus: this.buildingStatus,
      clientName: clientName.value,
      auditName: auditName.value,
      buildType: this.buildType,
      company: companyName.value
    };
    return pa;
  }

  // Get the number of filters that had been set.
  getFilterNumber(): number {
    var result = 0;

    const pa = this.getFilter();
    let el: keyof typeof pa;

    for(el in pa){
      if(pa[el] !== null && pa[el].trim().length > 0){
        result++;
      }
    }
    return result;
  }
}