<div class="close-div"><button mat-button [mat-dialog-close]=""  cdkFocusInitial><mat-icon>close</mat-icon></button></div>
<h1 mat-dialog-title>Please choose the predefined answer list code for Intelex</h1>

<div mat-dialog-content class="evenly">
    <mat-radio-group class="answerRadio" [(ngModel)]="answerSelected"  color="primary">
            <mat-radio-button *ngFor="let st of data.answers" class="intelexAnswer" color="primary" [value]="st" (change)="radioChange($event)">
                {{st}}
            </mat-radio-button>
      </mat-radio-group>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onConfirm()" [disabled] = "answerSelected.length === 0" class="button-close" cdkFocusInitial>Confirm</button>
  <button mat-button (click)="onCancel()" mat-dialog-close class="button-close">Cancel</button>
</div>