import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import{HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';

import { ApiService } from '../core/api.service';
import { PublicationModel, PublicationNode } from './publication';
import { ComparisonDef } from '../regcompare/statediff-model';


@Injectable({
  providedIn: 'root'
})
export class PublicationService{
  public loading = false;
  public selectState = "None";

  constructor( private apiService: ApiService,
    protected http:HttpClient) {
  }

  public getPublication(userId: number, category: string): Observable<PublicationModel[]> {
  	let URL = "Publication/GetList/" + userId + "/" + category;
    return this.apiService.get(URL);
  }

  public getPublications(userId: number): Observable<PublicationModel[]> {
  	let URL = "Publication/GetList/" + userId;
  	return this.apiService.get(URL);
  }


  /**
   * Call backend to search publications.
   * @param userId - who initials the search, the result list will only include the pub the user subscribed.
   * @param keystring - the key word for searching.
   * @returns
   */
  public searchPublication(userId: number, keystring: string): Observable<PublicationModel[]> {
  	let URL = "Publication/SearchPub/" + userId + "/" + keystring;
  	return this.apiService.get(URL);
  }

  // Sent email to info@stpub.com for purchasing product.
  public sendEmail(message: string){
    let URL = "Utility/SendEmail";
    return this.apiService.post(URL, {
      "emailTo": "info@stpub.com",
      "subject": "Product purchasing",
      "message": message
    });
  }

  public getSelectedState(): string{
    return this.selectState;
  }

  public setSelectedState(st: string): void {
    this.selectState = st;
  }

  decodeHtml(html: string){
    if(html == null) return null;
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };


  public getChild(parentString: string, parentKey: number, level: number): Observable<PublicationNode[]>{
    var targetURL = "Publication/"
    if(level == 0){
      targetURL = targetURL + "GetTopic/" + parentString + "/" + parentKey;
    } else if(level == 1){
      targetURL = targetURL + "GetRulebook/" + parentKey;
    } else if(level == 2){
      targetURL = targetURL + "GetSection/" + parentKey + "/" + this.getSelectedState();
    } else if(level == 3){
      targetURL = targetURL + "GetParagraph/" + parentKey + "/" + this.getSelectedState();
    }
    return this.apiService.get(targetURL);
  }

  public getGuidenote(paraKey: number){
    var targetURL = "Publication/GetGuidenote/" + paraKey;
    return this.apiService.get(targetURL);
  }

  showLoadingAnimation(showFlag: boolean){
    this.loading = showFlag;
  }

  public getFileContent(filePath: string){
    return this.http.get(filePath, {responseType: 'text'});
  }

  public getPdfUrl(pubid: string, version:string){
    var targetURL = "Publication/GetPdf/" + pubid + "/" + version;
    return this.apiService.get(targetURL);
  }

  downloadFile(filePath: string){
    let headers = new HttpHeaders(); //.set('authorization','Bearer '+token);
    return this.http.get(filePath, {headers: headers, responseType: 'blob'});
  }

  // Do the comparison
  public regCompare(states: string[], rbkeys: number[]){
    let stateString = states.join("|");
    let rbkeyString = rbkeys.join("|");
    var targetURL = "Publication/RegCompare/" + stateString + "/" + rbkeyString;
    return this.apiService.get(targetURL);
  }

  // Get the comparison definition.
  public getRegComparison(userId: string){
    let targetURL = "Apptool/GetComparison/" + userId;
    return this.apiService.get(targetURL);
  }

  // Delete the comparison definition.
  public DeleteRegComparison(cId: string){
    let targetURL = "Apptool/DeleteComparison/" + cId;
    return this.apiService.delete(targetURL);
  }

  // Save comparison definition.
  public saveComparison(comparisonDef: ComparisonDef){
    let URL = "Apptool/SaveComparison";
    return this.apiService.post(URL, comparisonDef);
  }

  // Save the aciont on publication.
  public markPublicationView(pubId: number, version:string, format: string, title: string, acronym: string){
    let URL = "Publication/TouchPublication";
    const index = acronym.indexOf("|");
    if(index != -1){
      acronym = acronym.substring(0, index);
    }

    return this.apiService.post(URL, {
      "pubid": pubId,
      "version": version,
      "format": format,
      "title": title,
      "acronym": acronym,
      "userid": 0
    });
  }
}