<div *ngIf="customList else noData" class="right-block">

  <div class="widget-body">
    <div class="widget-toolbox" fxLayout="row" fxLayoutAlign="end start">
        <button  matTooltip="New sub-topic"
          matTooltipClass="tooltip1" class="button-action" mat-button
          [matTooltipDisabled] = "userId !== selectedTopic?.userID && (selectedTopic?.locktoUser??true) === false"
          [disabled]="userId !== selectedTopic?.userID && selectedTopic?.locktoUser !== null"
          (click)="onNewTopic()" >
          <mat-icon fontSet="material-icons-outlined">add</mat-icon>
        </button>
        <button  matTooltip="Delete sub-topic"
          matTooltipClass="tooltip1"
          class="button-action"
          [matTooltipDisabled] = "userId !== selectedTopic?.userID && (selectedTopic?.locktoUser??true) === false"
          [disabled]="userId !== selectedTopic?.userID  && (selectedTopic?.locktoUser??true) === false"
          (click)="onDeleteTopic(selectedTopic?.topicID)"
          mat-button>
          <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
        </button>
        <button  mat-button class="button-action"
          matTooltip="Save sub-topic"
          matTooltipClass="tooltip1"
          [matTooltipDisabled] = "!getTopicChangeFlag()||(userId !== selectedTopic?.userID && (selectedTopic?.locktoUser??true)) === false"
          [disabled]="!getTopicChangeFlag()||(userId !== selectedTopic?.userID && selectedTopic?.locktoUser !== null)"
          (click)="saveTopic()">
          <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        </button>
    </div>
    <div class="widget-main">
      <span class=" warn"
        *ngIf="userId !== selectedTopic?.userID && (selectedTopic?.locktoUser ?? true) === false">
        *Shared with VIEW permission by {{selectedTopic.userName}}. Cannot edit.
      </span>
      <span class=" warn"
        *ngIf="userId !== selectedTopic?.userID && selectedTopic?.locktoUser === null">
        *Shared with EDIT permission by {{selectedTopic?.userName}}.
      </span>
      <mat-form-field class="mat-form-title" id="subTopic-title">
        <h4 class="small-title" matTooltip="Click to start to edit SubTopic" matTooltipClass="tooltip1">SubTopic:&nbsp;
          <input matInput #subTopicInput
            class="stpInput" [value]="selectedTopic?.topic"  [formControl]="subTopicTitleFormControl"  maxlength="255"
            (change)="changeTopic($event)"
            required>
        </h4>
        <mat-error *ngIf="subTopicTitleFormControl.hasError('required')">Sub topic is required.</mat-error>

      </mat-form-field>
      <ng-container *ngIf="selectedTopic?.contents">
      <mat-card *ngFor="let content of selectedTopic?.contents" class="section-item">
        <mat-card-title>
          <div>{{selectedTopic.topic}} Question - {{content.questionNum}}</div>
          <div class="button-line">
            <button  mat-button class="button-action"
              (click)="onDeleteSubTopic(content.questionNum)"
              matTooltip="delete question"
              [disabled]="userId !== selectedTopic?.userID && (selectedTopic?.locktoUser??true) === false"
              matTooltipClass="tooltip1">
              <mat-icon  fontSet="material-icons-outlined">delete</mat-icon>
            </button>
          </div>
        </mat-card-title>
        <mat-card-content class="card-content">
          <mat-form-field  class="stpInput">
              <mat-label class="form-label">Question</mat-label>
              <textarea matInput
                cdkTextareaAutosize
                name="question"
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8"
                class="stpInput" [value]="removeHtmlTag(content.question)" maxlength="8000"
                placeholder="Question"
                (change)="changeTopicContent($event, content.questionNum)"
                (keyup)="changeTopicContent($event, content.questionNum)"
                required>
              </textarea>
          </mat-form-field>

          <mat-form-field  class="stpInput">
            <mat-label class="form-label">Citation reference</mat-label>
            <input matInput
              name="citation"
              class="stpInput" [value]="content.citation" maxlength="255"
              placeholder="Citation reference"
              (keyup)="changeTopicContent($event, content.questionNum)"
              (change)="changeTopicContent($event, content.questionNum)">
          </mat-form-field>

          <mat-form-field  class="stpInput">
            <mat-label class="form-label">Citation Link</mat-label>
            <input matInput
              name="citationLink"
              class="stpInput" [value]="content.citationLink" maxlength="255"
              placeholder="Citation link"
              (keyup)="changeTopicContent($event, content.questionNum)"
              (change)="changeTopicContent($event, content.questionNum)">
          </mat-form-field>

          <mat-form-field  class="stpInput">
            <mat-label class="form-label">Guide note</mat-label>
            <textarea matInput
              name="guidenote"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize" maxlength="8000"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="8"
              (keyup)="changeTopicContent($event, content.questionNum)"
              (change)="changeTopicContent($event, content.questionNum)"
              class="stpInput" [value]="removeHtmlTag(content.guidenote)" placeholder="Guide Note">
            </textarea>
          </mat-form-field>
        </mat-card-content>

      </mat-card>
      </ng-container>
      <button mat-button
        *ngIf="userId === selectedTopic?.userID || (selectedTopic?.locktoUser??true) === true"
            class="button-action"
            matTooltip="new question"
            matTooltipClass="tooltip1"
            (click)="onNewSubtopic()">
            <mat-icon fontSet="material-icons-outlined">add</mat-icon>
          </button>
    </div>
  </div>
</div>

<ng-template #noData>No Data Available</ng-template>