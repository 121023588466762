<div class="close-div"><button mat-button [mat-dialog-close]=""  cdkFocusInitial><mat-icon>close</mat-icon></button></div>
<h1 mat-dialog-title>Rulebook update review</h1>
<div mat-dialog-content class="update-review">
  <ng-container *ngFor="let sc of data">
    <div>
        <h4 class="sectionTitle">Section&nbsp;{{sc.rbSectNum}}:&nbsp;{{sc.rbSectName}}</h4>
        <div class = "para" *ngFor = "let p of sc.para">
            <div class="b">Para&nbsp;{{p.paraNum}}</div>
            <div  [innerHTML]="p.newQuestion"></div>
            <div  [innerHTML]="p.newGuidenote"></div>
        </div>
    </div>
  </ng-container>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onRemoveFlag()" class="button-close" cdkFocusInitial>Remove flag</button>
  <button mat-button (click)="onClose()" mat-dialog-close class="button-close">Close</button>
</div>