import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {  Router } from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {

  constructor(public router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        let handled: boolean = false;
        console.error(error);
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.deleteCookie();
                //this.alertifyService.error("Unauthorized visit.");
                this.router.navigateByUrl("/");
                handled = true;
                break;
              case 403:     //forbidden
                this.deleteCookie();
                //this.alertifyService.error("Unauthorized visit.");
                this.router.navigateByUrl("/");
                console.log(`redirect to login`);
                handled = true;
                break;
            }
          }
        }
        else {
          console.error("Other Errors");
        }

        if (handled) {
          console.log('return back ');
          return of(error);
        } else {
          console.log('throw error back to to the subscriber');
          return throwError(error);
        }
      })
    )
  }


  deleteCookie(){
    let domain = environment.DOMAIN;
    try{
      document.cookie = "STPHUB_ID=test@test.com; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; Domain=" + domain + ";Secure";
      //this.cookieService.delete(cookie, "/", domain);
    }catch(err){
      console.error("error: " + err.message);
    }
  }
}