<div class="pdf-contents">
  <div class="outline">
    <div>
      <img src="./assets/images/STP_Logo_xsm.png">
    </div>
    <template #pdfOutlineContainer></template>
</div>
  <div class="detail">
    <div *ngIf="pdfOnDisplay == 'unavailable'; else displayPdf">
      <h3>Sorry, this protocol is not available currently.</h3>
    </div>
    <ng-template #displayPdf>
      <iframe [src] = "pdfOnDisplay | safe: 'resourceUrl'" style="width:100%;height:800px" type="application/pdf"></iframe>
    </ng-template>
  </div>
</div>
