import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule} from '@angular/forms';

import { allContainerComponents } from './container';
import { allPresentationalComponents } from './presentational';
import { UserFormComponent } from './presentational/user-form/user-form.component';
 
@NgModule({
    imports: [CommonModule, ReactiveFormsModule],
    declarations: [...allPresentationalComponents, ...allContainerComponents, UserFormComponent],
    exports: [...allContainerComponents]
})
export class UserModule {}
 