import { Component, OnInit,  Input, Output, EventEmitter , ViewChild, ElementRef } from '@angular/core';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';

import {CustomTopic, CustomContent, CustomTopicWithTitle} from '../../model/CustomQuestion';
import { CustomQuestionService } from '../../service/custom-question.service';
import { AlertifyService } from '../../../_models/alertify.service';

@Component({
  selector: 'app-edit-custom',
  templateUrl: './edit-custom.component.html',
  styleUrls: ['./edit-custom.component.css']
})
export class EditCustomComponent implements OnInit {
  @Input() customList: CustomTopicWithTitle[];
  @Input() selectedTopic: CustomTopic;
  @Input() userId: number;
  @Output() deleteSubtopic = new EventEmitter<number>();
  @Output() saveCustomTopic = new EventEmitter<CustomTopic>();
  @Output() deleteTopic = new EventEmitter<string>();
  @Output() newSubtopic = new EventEmitter();

  @ViewChild("subTopicInput") subTopicInputField: ElementRef;

  topicContents: CustomContent[];
  subTopicTitleFormControl = new FormControl("",[Validators.required]);

  constructor(private service: CustomQuestionService,
    private alertifyService: AlertifyService) { }

  ngOnInit() {
    this.subTopicTitleFormControl = new FormControl(this.selectedTopic? this.selectedTopic.topic: '',[Validators.required]);
    //this.subTopicTitleFormControl.setValue(this.selectedTopic? this.selectedTopic.topic : '' );

  }

  public ngOnChanges(): void {
   if(this.subTopicInputField){
    this.subTopicInputField.nativeElement.focus();
   }

   this.subTopicTitleFormControl = new FormControl(this.selectedTopic? this.selectedTopic.topic: '',[Validators.required]);
  }

  // Add a new topic
  onNewTopic(){
    this.service.setTopicChangeFlag(true);
    this.subTopicInputField.nativeElement.focus();
    this.newSubtopic.emit();
  }

  // Delete topic button clicked.
  onDeleteTopic(topicID: string){
    this.alertifyService.confirmOkCancel("STP AuditHub", "Are you sure to delete the subtopic?",
    () => { // Confirmed.
      this.deleteTopic.emit(topicID);
    },
    () => { // Canceled.
    });
  }

  removeHtmlTag(str: string): string {
    if ((str===null) || (str===''))
        return "";
    else
        str = str.toString().replaceAll("&nbsp;", " ");

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
  }

  // Delete subtopic button clicked.
  onDeleteSubTopic(questionNum: number){
    this.alertifyService.confirmOkCancel("STP AuditHub", "Are you sure to delete the question?",
    () =>{
      let contentKey = this.getContentkey(questionNum);
      if (contentKey == -1){
        this.alertifyService.warning("Cannot find the subtopic.");
        return false;
      }

      if(contentKey == 0){ // This is a new subtopic which has not been saved persistly.
        var index = 0;
        for(let element of this.selectedTopic.contents){
          if(element.questionNum == questionNum){
            this.selectedTopic.contents.splice(index, 1);
            return false;
          }
          index++;
        }
      }
      this.deleteSubtopic.emit(contentKey);
    },
    () =>{// do nothing
    });
  }

  // Add a subtopic.
  onNewSubtopic() {
    var nc: CustomContent = {
      contentKey: 0,
      questionNum: this.getMaxQuestionNum() + 1,
      question: "",
      guidenote: "",
      citation: "",
      citationLink: ""
    };
    if(this.selectedTopic.contents){
      this.selectedTopic.contents.push(nc);
    }else{
      this.selectedTopic.contents = [nc];
    }

    this.service.setTopicChangeFlag(true);
  }

  // Save current topic.
  saveTopic() {
    let warnMessage = this.ValidateTopic();
    if(warnMessage.length > 0){
      alert(warnMessage);
      return false;
    }
    //console.log("call backend to save topic.");
    this.service.setTopicChangeFlag(false);
    // Emit the event to parent to handle it.
    this.saveCustomTopic.emit(this.selectedTopic);
  }

  // The content of <input> element for Topic has changed, update the topic.
  changeTopic(e){
    if(this.userId == this.selectedTopic.userID || this.selectedTopic.locktoUser === null){
      this.selectedTopic.topic = e.target.value;
      this.service.setTopicChangeFlag(true);
    }
  }

  // The content of <input> element has changed, update the dataset accordingly.
  changeTopicContent(e, num:number){
    if(this.userId == this.selectedTopic.userID || this.selectedTopic.locktoUser === null){
      this.service.setTopicChangeFlag(true);
      for(let element of this.selectedTopic.contents){
        if(element.questionNum == num){
          let targetInput = e.target.name;
          element[targetInput] = e.target.value;
          break;
       }
     }
    }
  }

  getTopicChangeFlag():boolean{
    return this.service.getTopicChangeFlag();
  }


  // Validate topic before saving.
  ValidateTopic(): string {
    if(this.selectedTopic.topic == ''){
      return "Topic cannot be empty.";
    }
    for(let element of this.selectedTopic.contents){
       if(typeof element.question == 'undefined' || element.question == null || element.question.trim().length == 0){
        return "Rulebook Question - " + element.questionNum + " is empty.";
      }
    }
    return "";
  }

  // Get the max question num for the current topic.
  getMaxQuestionNum(): number {
    var result = 0;
    if(this.selectedTopic.contents === null) return 0;

    this.selectedTopic.contents.forEach(element => {
      if(element.questionNum > result){
        result = element.questionNum;
      }
    });
    return result;
  }

  // Get the content key by question number.
  getContentkey(questionNum:number): number{
    for(let element of this.selectedTopic.contents){
      if(element.questionNum == questionNum){
        return element.contentKey;
      }
    }
    return -1; // not found.
  }
}