<div class="close-div">
  <h4 class="dialog-title">&nbsp;&nbsp;&nbsp;STP AuditHub</h4>
  <button mat-button [mat-dialog-close]=""  cdkFocusInitial><mat-icon>close</mat-icon></button>
</div>
<p class="dialog-content">Do you want to include any states?</p>
<p class="dialog-note" innerHtml="{{tips}}"></p>
<div mat-dialog-content class="evenly">
  <ng-container *ngFor="let st of data.states; let i = index">
    <mat-checkbox
      (change) = "onStateClick($event, st)"
      [disabled] = "(stateChecked.length == 1
        && this.data.buildType !== 'X3.0'
        && this.data.buildType !== 'C3.0'
        && this.data.buildType !== 'reghub'
        && this.data.buildType !== 'csv'
        && this.data.buildType !== 'regcompare'
        && !stateChecked.includes(st)) ||
        (stateChecked.length == 5 && this.data.buildType == 'regcompare' && !stateChecked.includes(st))"
      [ngModel] = "this.stateChecked.includes(st)">{{st}}
    </mat-checkbox>
  </ng-container>
</div>
<hr class="solid">
<mat-checkbox
  *ngIf = "data.buildType == 'X3.0'"
  (change) = "onUnlockClick($event)"
  matTooltip="Unlocking means you can format or edit the file. Note, STP will not support Unlocked Xcelerators if changes to the file break formulas or cause other issues." matTooltipClass="tooltip1"
  class="emphasize">
  Unlock Xcelerator
 </mat-checkbox>
<div mat-dialog-actions>
  <button mat-button (click)="onConfirm()" class="button-close" cdkFocusInitial>Confirm</button>
  <button mat-button (click)="onCancel()" mat-dialog-close class="button-close">Cancel</button>
</div>