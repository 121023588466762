<div class="close-div">
  <h4 class="dialog-title">&nbsp;&nbsp;&nbsp;STP AuditHub</h4>
  <button mat-button [mat-dialog-close]=""  (click)="onCancel()" cdkFocusInitial><mat-icon>close</mat-icon></button>
</div>
<p class="dialog-content">Are you sure you want to proceed?</p>
<hr class="solid">

<div mat-dialog-actions [ngClass]="{'dialog-confirm': build === 'X3.0'}">
  <mat-checkbox
    *ngIf = "build == 'X3.0'"
    matTooltip="Unlocking means you can format or edit the file. Note, STP will not support Unlocked Xcelerators if changes to the file break formulas or cause other issues." matTooltipClass="tooltip1"
    (change) = "onCheckboxClick($event)"
    class="emphasize">
    Unlock Xcelerator
 </mat-checkbox>
 <div>
  <button mat-button (click)="onConfirm()" class="button-close" cdkFocusInitial>Confirm</button>
  <button mat-button (click)="onCancel()" mat-dialog-close class="button-close">Cancel</button>
 </div>

</div>