import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService } from './alertify.service';
import { RouteConfigs } from '../_constants';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ApiErrorHandler {

    constructor(private router: Router,
      private alertify: AlertifyService) {
    }

    public handle(error: HttpErrorResponse) {
        console.debug("handle error: " + error.status);
        switch (error.status) {
            case 500:
                this.showErrorMessage(error);
                break;
            case 404:
               // this.showErrorMessage(error);
                break;
            case 401:
                console.error("Unauthorized visit. Will be redirected to login page.")
                this.router.navigateByUrl(RouteConfigs.login);
                break;
            case 0:
                this.router.navigateByUrl(RouteConfigs.errorPage);
                break;
        }
    }

    showErrorMessage(error: any) {
        console.log(error);
        const errorResponse = error.error;
        const message = errorResponse.Message;
        if (errorResponse !== undefined) {
            this.alertify.error(errorResponse);
        } else {
            this.alertify.error(error.message);
        }
    }
}
