import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private controllerEndpoint = `user`;
  constructor(private readonly http: HttpClient) {}
 
  getAll() {
    return this.http.get<User[]>(`${environment.endpoint}${this.controllerEndpoint}`);
  }

  add(toAdd: User){
  	return this.http.post<User>(
  		`${environment.endpoint}${this.controllerEndpoint}`,
  		toAdd
  	);
  }
}
