import { Component, OnInit } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { AlertifyService } from './_models/alertify.service';
import {environment} from './../environments/environment';
import {filter, map} from 'rxjs/operators';
import { SessionTimeoutService } from './_common/session-timeout-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(private alertifyService: AlertifyService, private swUpdate: SwUpdate, private sessionService: SessionTimeoutService){}

  ngOnInit(): void {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates
        .subscribe(async evt => {
          switch(evt.type){
            case 'VERSION_DETECTED':
                    console.log(`Downloading new app version: ${evt.version.hash}`);
                    break;
            case 'VERSION_READY':
                    console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                    await this.swUpdate.activateUpdate();
                    this.alertifyService.confirmOkCancel("Update to the latest version of STP AuditHub", "Changes have been made to STP AuditHub. Please update before continuing.",
                    () => {
                      window.location.reload();
                    },
                    () =>{ // Cancel
                      return false;
                    });
                    break;
            case 'VERSION_INSTALLATION_FAILED':
                    console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                    break;

          }
        });
      }
    }
}
