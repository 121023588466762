<div class="spinner-container" *ngIf="dataService.loading">
  <mat-spinner></mat-spinner>
</div>

<div class="audit-header">
  <mat-form-field class="search-pub">
    <input matInput type="text" placeholder="Filter product" [(ngModel)]="searchWord" (input)="searchThis($event.target.value)">
  </mat-form-field>
  <mat-icon>search</mat-icon>
  <ng-container *ngIf="stateList !== null">
    <mat-form-field appearance="fill" class="selectState">
      <mat-label>State difference</mat-label>
      <mat-select [value]="selectedState" (selectionChange)="onSelectStateChange($event)">
        <mat-option *ngFor="let st of stateList" [value]="st">{{st}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="button-row">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="node-column">
    <button mat-icon-button disabled></button><span  [innerHTML]="node.item"></span>
    <button *ngIf="!node.item.startsWith('SD')" class="button-action"
      matTooltip="Display guide note"
			matTooltipClass="tooltip1"
      (click)="onDisplayGuidenote(node.key)">Guide Note
  </button>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="spinner-container">
    <button *ngIf="!node.item.startsWith('SD')" mat-icon-button
            [attr.aria-label]="'click to toggle'" matTreeNodeToggle>
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <span [innerHTML]="node.item"></span>
    <mat-spinner *ngIf="node.isLoading"
                      mode="indeterminate"
                      class="example-tree-progress-bar"></mat-spinner>
  </mat-tree-node>
</mat-tree>