import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {  MatDialog } from '@angular/material/dialog';
import { MatSidenav} from '@angular/material/sidenav';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';

import { HeaderComponent,  } from '../_components/header/header.component';
import { FooterComponent } from '../_components/footer/footer.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  declarations: [
  	HeaderComponent,
  	FooterComponent,
  	DashboardComponent,

  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
      MatSidenavModule,
       MatListModule,
       MatExpansionModule,
    DashboardRoutingModule,FlexLayoutModule,
  ],
  exports:[DashboardComponent,HeaderComponent,FooterComponent,]
})
export class DashboardModule { }