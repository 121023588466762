<div class="spinner-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div class="tp-container mat-elevation-z8">
	<div class="toolbtn">
		<mat-radio-group class="share-template" [(ngModel)]="myTemplate" color="primary">
				<mat-radio-button
					color="primary" value="1"
					[disabled]="isLoading"
					(change)="onRidaoChange($event)">My Templates
				</mat-radio-button>
				<mat-radio-button
					color="primary" value="0"
					(change)="onRidaoChange($event)"
					[disabled]="inProgress == 2 || isLoading">Shared Templates
				</mat-radio-button>
		</mat-radio-group>
		<div>
		<button mat-button class="button-action"
			matTooltip="Refresh RegApply Home Page"
			matTooltipClass="tooltip1"
			[disabled]="inProgress == 2 || isLoading"
			(click)="reload()">
			<mat-icon fontSet="material-icons-outlined">sync</mat-icon>
		</button>
		<button  mat-button class="button-action"
          matTooltip="New audit template"
          matTooltipClass="tooltip1"
          [disabled]="inProgress == 2 || isLoading"
          (click)="newAudit()">
          <mat-icon fontSet="material-icons-outlined">add</mat-icon>
        </button>
		</div>
	</div>
	<div class="widget-toolbox" fxLayout="row" fxLayoutAlign="end center">
		<div class="search-container">
			<mat-form-field class="search-pub">
				<input [disabled]="isLoading" matInput type="text" placeholder="Search template" [(ngModel)]="searchWord" (input)="searchInputEvent($event.target.value)">
			</mat-form-field>
			<button mat-button
				class="button-action"
				matTooltip="search"
				matTooltipClass="tooltip1"
				[disabled]="isLoading || !searchWord || searchWord.length === 0"
				(click)="searchTemplate()">
				<mat-icon fontSet="material-icons-outlined">search</mat-icon>
			</button>
		</div>
		<mat-button-toggle-group #group="matButtonToggleGroup" value="1">
			<mat-button-toggle value="1" (change)="onValChange($event.value)"
				[disabled]="isLoading"
				matTooltip="current"
				matTooltipClass="tooltip1">
				<mat-icon fontSet="material-icons-outlined">not_started</mat-icon>
			</mat-button-toggle>
					<mat-button-toggle value="2" (change)="onValChange($event.value)"	matTooltip="archived"
					[disabled]="isLoading"
					matTooltipClass="tooltip1" [disabled]="myTemplate == '0'">
					<mat-icon fontSet="material-icons-outlined">archive</mat-icon>
			</mat-button-toggle>
		</mat-button-toggle-group>
	</div>

	<mat-table [dataSource]="dataSource" class="mat-elevation-z8"
		matSort matSortActive="auditName"
		matSortDisableClear matSortDirection="desc" #empTbSort="matSort">
		<!-- Acronym Column -->
			<ng-container matColumnDef="csskey">
				<mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
				<mat-cell *matCellDef="let element; let j = index" class="action-cell" [ngClass] ="{'upgrading': element.status == 'upgrading'}">
					<ng-container *ngIf="inProgress == 2"> <!-- Archived templates: only 'unarchive' action available. -->
						<button mat-button class="button-action"
							(click)="onUnarchiveTemplate(element.csskey, j)"
							[disabled]="isLoading || element.status == 'upgrading'"
							matTooltip="Unarchive"
							matTooltipClass="tooltip1">
							<mat-icon fontSet="material-icons-outlined">unarchive</mat-icon>
						</button>
					</ng-container>
					<ng-container *ngIf="inProgress != 2">
						<button mat-button class="button-action"
							(click)="onViewClick(element.csskey, element.auditName)"
							[disabled]="isLoading|| element.status == 'upgrading'"
							matTooltip="view template"
							matTooltipClass="tooltip1">
							<mat-icon fontSet="material-icons-outlined">zoom_in</mat-icon>
						</button>
						<button mat-button
							class="button-action"
							matTooltip="edit template"
							matTooltipClass="tooltip1"
							[disabled]="isLoading|| element.status == 'upgrading'"
							(click)="onEditClick(element.csskey)">
							<mat-icon fontSet="material-icons-outlined">edit</mat-icon>
						</button>
						<button mat-button class="button-action"
							matTooltip="Copy template"
							matTooltipClass="tooltip1"
							[disabled]="isLoading|| element.status == 'upgrading'"
							(click)="onCopyClick(element.csskey)">
							<mat-icon fontSet="material-icons-outlined">note_add</mat-icon>
						</button>

						<button mat-button class="button-action"
							(click)="onCopyClipboard(element.csskey)"
							[disabled]="isLoading"
							matTooltip="template keys"
							matTooltipClass="tooltip1">
							<mat-icon fontSet="material-icons-outlined">key</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'no')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>BenchmarkESG</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'no')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>Cority</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'EHS')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>EHS Insight</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'no')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>Enablon</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'ETQ')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>ETQ</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'no')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>Intelex</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'no')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>Origamirisk	</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'NO')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>Partner Demo</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'processmap')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>ProcessMap</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'SAFETYSTR')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>SafetyStratus</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'NO')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>SBN</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'Vector')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>Vector</span>
							</button>
							<button mat-menu-item (click)="onExportEmis(element.csskey, 'NO')">
								<mat-icon  fontSet="material-icons-outlined">cloud_download</mat-icon>
								  <span>Velocity</span>
							</button>
						</mat-menu>
						<button *ngIf="myTemplate == '1'" mat-button class="button-action"
							(click)="onDeleteTemplate(element.csskey, j)"
							[disabled]="isLoading|| element.status == 'upgrading'"
							matTooltip="Delete template"
							matTooltipClass="tooltip1">
							<mat-icon  fontSet="material-icons-outlined">delete</mat-icon>
						</button>
						<button *ngIf="myTemplate == '1'" mat-button class="button-action"
							(click)="onArchiveTemplate(element.csskey, j)"
							[disabled]="isLoading|| element.status == 'upgrading'"
							matTooltip="Archive template"
							matTooltipClass="tooltip1">
							<mat-icon  fontSet="material-icons-outlined">archive</mat-icon>
						</button>
					</ng-container>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="color" >
				<mat-header-cell  [hidden]="true" *matHeaderCellDef>Download</mat-header-cell>
				<mat-cell  [hidden]="true" *matCellDef="let element"  [ngClass] ="{'upgrading': element.status == 'upgrading'}">
					<button *ngIf="myTemplate == '1'" mat-button class="button-action download-icon"
							(click)="onDownloadTemplate(element.csskey, element.auditName)"
							matTooltip="Download"
							[disabled]="!element.downloadable || isLoading || inProgress == 2"
							matTooltipClass="tooltip1">
							<mat-icon fontSet="material-icons-outlined" class="byturn">download</mat-icon>
					</button>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="isUpdateAvailable">
				<mat-header-cell *matHeaderCellDef>Download</mat-header-cell>
				<mat-cell *matCellDef="let element"  [ngClass] ="{'upgrading': element.status == 'upgrading'}">
					<button  mat-button class="button-action download-icon"
							(click)="onDownloadTemplate(element.csskey, element.auditName)"
							matTooltip="Download"
							[disabled]="!element.downloadable || isLoading || inProgress == 2"
							matTooltipClass="tooltip1">
							<mat-icon fontSet="material-icons-outlined" class="byturn">download</mat-icon>
					</button>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="auditName">
				<mat-header-cell *matHeaderCellDef mat-sort-header>Audit Name</mat-header-cell>
				<mat-cell
					*matCellDef="let element"
					[matTooltip] = "element.hasOwnProperty('status') && element.status == 'upgrading' ? 'Template is updating' : element.hasOwnProperty('status') && element.status == 'upgraded' ? 'Template updated' : null"
					(click) = "inProgress == 1 && element.hasOwnProperty('status') && element.status != 'upgrading' && onViewClick(element.csskey, element.auditName)"
					[ngClass] ="{'audit-name': inProgress == 1, 'upgrading': element.hasOwnProperty('status') && element.status == 'upgrading'}">
					{{element.auditName}}
					<mat-icon
						matTooltip="Monitoring RegRegister"
						*ngIf="element.monitoring != null && element.monitoring.toLowerCase().includes('monitored')"
						class="monitor-icon">visibility
					</mat-icon>
					<mat-icon
						matTooltip="Pushed to Intelex"
						*ngIf="element.monitoring != null &&  element.monitoring.toLowerCase().includes('intelex')"
						class="monitor-icon">file_download_done
					</mat-icon>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="cssDate">
				<mat-header-cell *matHeaderCellDef mat-sort-header><mat-icon>access_time</mat-icon> Last Viewed </mat-header-cell>
				<mat-cell *matCellDef="let row" [ngClass] ="{'upgrading': row.status == 'upgrading'}">{{row.cssDate.toLocaleString().slice(0, 10)}}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="isShared">
				<mat-header-cell *matHeaderCellDef>Share Permission</mat-header-cell>
				<mat-cell *matCellDef="let row" [ngClass] ="{'upgrading': row.status == 'upgrading'}">
					<mat-form-field class="share-permission">
						<select matNativeControl
							[disabled]="isLoading || myTemplate == '0' || inProgress == 2|| row.status == 'upgrading'"
							(change)="onPermissionChange($event.target.value, row.csskey, row.isShared, row.permission)">
							<option value="Not" [selected]="row.isShared === false">No</option>
							<option value="Edit" [selected]="row.permission === 'Edit'">Edit</option>
							<option value="View" [selected]="row.permission === 'View'">View</option>
						</select>
				</mat-form-field>
				</mat-cell>
			</ng-container>
			<ng-container *ngIf="myTemplate == '1'" matColumnDef="status">
				<mat-header-cell [hidden]="true" *matHeaderCellDef>sdd</mat-header-cell>
				<mat-cell [hidden]="true" *matCellDef="let row">
					{{row.status}}
				</mat-cell>
			</ng-container>

			<ng-container *ngIf="myTemplate == '0'" matColumnDef="sharedBy">
				<mat-header-cell *matHeaderCellDef>Shared By</mat-header-cell>
				<mat-cell *matCellDef="let row">{{row.sharedBy}}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="permission">
				<mat-header-cell *matHeaderCellDef>Update?</mat-header-cell>
				<mat-cell *matCellDef="let row" class="action-cell" [ngClass] ="{'upgrading': row.status == 'upgrading'}">
					<button *ngIf="row.isUpdateAvailable" mat-button class="button-action download-icon"
							(click)="onUpgradeTemplate(row.csskey, row.auditName)"
							[disabled]="isLoading || inProgress == 2 ||myTemplate == '0' || row.hasOwnProperty('status') && row.status == 'upgrading'"
							matTooltip="Update available"
							matTooltipClass="tooltip1">
							<mat-icon  fontSet="material-icons-outlined">notification_important</mat-icon>
					</button>
					<mat-icon  *ngIf="row.status == 'upgraded'"
						[ngClass] ="{'upgraded': row.status == 'upgraded'}"
						matTooltip="Update completed"
						fontSet="material-icons-outlined">done
					</mat-icon>
				</mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>
	<mat-paginator
		(page) = "onChangePage($event)"
		[disabled]="isLoading" [length]="resultsLength" [pageSize]="pagesize" [pageSizeOptions]="[5, 10, 20]">
	</mat-paginator>
</div>