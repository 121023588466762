<div class="close-div"><button mat-button [mat-dialog-close]=""  cdkFocusInitial><mat-icon>close</mat-icon></button></div>
<h1 mat-dialog-title>Include A State</h1>
<div mat-dialog-content class="evenly">
  <ng-container *ngFor="let st of data.states">
    <mat-checkbox  (change)="onStateClick($event, st)" [(ngModel)]="st === data.selected">{{st}}</mat-checkbox>
  </ng-container>
    
</div>
<div mat-dialog-actions>  
  <button mat-button (click)="onCancel()" mat-dialog-close class="button-close">Close</button>
</div>