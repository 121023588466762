import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PocketRefComponent,GuidenoteDisplayDialog } from './pocket-ref/pocket-ref.component';
import { FormsModule } from '@angular/forms'

import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTreeModule} from '@angular/material/tree';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
  entryComponents:[GuidenoteDisplayDialog],
  declarations: [PocketRefComponent, GuidenoteDisplayDialog, ],
  imports: [
    CommonModule, FormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatProgressBarModule,
    MatCheckboxModule, MatTooltipModule, MatDialogModule,MatInputModule, MatSelectModule,
  ]
})
export class PocketRefModule { }
