import { Injectable } from '@angular/core';
import {BehaviorSubject, fromEvent} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleService {

  public idle$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public wake$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isIdle = false;
  private idleAfterSeconds = 1;
  private countDown;

  constructor() {
    // Setup events
    fromEvent(document, 'mousemove').subscribe(() => this.onInteraction('mousemove'));
    fromEvent(document, 'touchstart').subscribe(() => this.onInteraction('touchstart'));
    fromEvent(document, 'keydown').subscribe(() => this.onInteraction('keydown'));
    fromEvent(document, 'scroll').subscribe(() => this.onInteraction('scroll'));
    fromEvent(document, 'visibilitychange').subscribe(() => this.onInteraction('visibilitychange'));
    fromEvent(document, 'click').subscribe(() => this.onInteraction('click'));

    this.onInteraction('');
  }

  onInteraction(event: string) {
    // Is idle and interacting, emit Wake
    // console.log(`onInteraction ${event}: ${new Date().getTime()}`);
    if (this.isIdle) {
      this.isIdle = false;
      this.wake$.next(true);
    }

    // User interaction, reset start-idle-timer
    clearTimeout(this.countDown);
    this.countDown = setTimeout(() => {
      // Countdown done without interaction - emit Idle
      this.isIdle = true;
      this.idle$.next(true);
    }, this.idleAfterSeconds * 1000);
  }
}
