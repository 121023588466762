import {Observable, BehaviorSubject} from 'rxjs';
import { tap, finalize} from 'rxjs/operators';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { TemplateListService } from './service/templateList.service';
import { TemplateService} from './service/template.service';
import { TemplateList,TemplateListReq } from './model/TemplateList';
import { MatSort } from '@angular/material/sort';

export class TemplateListDataSource implements DataSource<TemplateList>{

  private templateSubject = new BehaviorSubject<TemplateList[]>([]);
  private resultNumber = new BehaviorSubject<number>(0);
  public loading = false;
  public temData: TemplateList[] = [];

  constructor( private service: TemplateListService, private templateService: TemplateService) {
  }

  loadDatas(
    id: number,
    myTemplate: boolean,
    inProgress: number,
    pageIndex: number, // 1- current; 2- archived
    pageSize: number,
    sortDirection: string,
    sortColumn: string
  ) {
     var cache = this.templateService.loadFromCache();
     // load data from session storage if there are data, otherwise call backend.
     if(cache != null && cache.length > 0 && inProgress == 1){ //archived template are not cached.
      this.processData(cache, pageIndex, pageSize, sortDirection, sortColumn);
     }else{
      this.callBackend(id, myTemplate, inProgress, pageIndex, pageSize, sortDirection, sortColumn);
     }
  }

  // load data from backend.
  reloadDatas(
    id: number,
    myTemplate: boolean,
    inProgress: number,
    pageIndex: number, // 1- current; 2- archived
    pageSize: number,
    sortDirection: string,
    sortColumn: string
  ) {
    this.templateService.removeCache();
    this.callBackend(id, myTemplate, inProgress, pageIndex, pageSize, sortDirection, sortColumn);
  }


  callBackend(id: number, myTemplate: boolean, inProgress: number, pageIndex:number, pageSize: number, sortDirection:string, sortColumn:string){
    let req: TemplateListReq = {
      userid: id,
      myTemplate: myTemplate,
      inProgress: inProgress //1- current; 2- archived
    };

    this.templateService.showLoadingAnimation(true);
  	this.service.getTemplateList(req).subscribe(
      data => {
        if(inProgress == 1){ // cache the current template list
          this.templateService.cacheTemplateList(data);
        }
        this.processData(data, pageIndex, pageSize, sortDirection, sortColumn);
      },
      error =>{
        console.log(error);
        if(error.status == 404){
          this.templateSubject.next(null);
          this.resultNumber.next(0);
        }

        this.templateService.showLoadingAnimation(false);
      }
    );
  }

  processData(data: TemplateList[], pageIndex: number, pageSize: number, direction: string, column: string){
     //console.log("load " + data.length);
     if(direction == "desc" && column == "cssDate"){
      this.temData = data.sort((a,b) => (a.cssDate < b.cssDate) ? 1: ((b.cssDate < a.cssDate) ? -1 : 0));
     }else if(direction == "asc" && column == "cssDate"){
      this.temData = data.sort((a,b) => (a.cssDate > b.cssDate) ? 1: ((b.cssDate > a.cssDate) ? -1 : 0));
     }else if(direction == "desc" && column == "auditName"){
      this.temData = data.sort((a,b) => (a.auditName.toLowerCase() < b.auditName.toLocaleLowerCase()) ? 1: ((b.auditName.toLocaleLowerCase() < a.auditName.toLocaleLowerCase()) ? -1 : 0));
     }else{
      this.temData = data.sort((a,b) => (a.auditName.toLowerCase() > b.auditName.toLocaleLowerCase()) ? 1: ((b.auditName.toLocaleLowerCase() > a.auditName.toLocaleLowerCase()) ? -1 : 0));
     }


     var pageData: TemplateList[] = [];
     let start = pageIndex * pageSize;

     for(var i = 0; i < this.temData.length; i++){
       if(i >= start && i < start + pageSize){
         if(!this.temData[i].isShared){
          this.temData[i].permission = "N/A";
         }else{
           this.temData[i].permission = this.temData[i].permission.trim();
         }

        pageData.push(this.temData[i]);
       }
     }
     this.templateSubject.next(pageData);
     this.resultNumber.next(this.temData.length);
     this.templateService.showLoadingAnimation(false);
  }


  loadPage(pageIndex: number, pageSize: number){
    let start = pageIndex * pageSize;
    var pageData: TemplateList[] = [];

        for(var i = 0; i < this.temData.length; i++){
          if(i >= start && i < start + pageSize){
            pageData.push(this.temData[i]);
          }
        }
    this.templateSubject.next(pageData);
  }

  searchTemplate(keystring: string) : number{
    var pageData: TemplateList[] = [];
    for(var i = 0; i < this.temData.length; i++){
      let index = this.temData[i].auditName.toUpperCase().indexOf(keystring.toUpperCase());
      if(index != -1){
        pageData.push(this.temData[i]);
        continue;
      }
    }
    if(pageData.length > 0){
      this.resultNumber.next(pageData.length);
      this.templateSubject.next(pageData);
      return 0;
    }
    else{
      this.resultNumber.next(0);
      this.templateSubject.next(null);
      return -1;
    }
  }

  getItem(csskey: number): TemplateList{
    var data = this.templateSubject.value;
    return data.find(x => x.csskey == csskey);
  }

  getPercentage(csskey: number){
    let item = this.getTemplateItem(csskey);
    if(item){
      return item.percentComplete;
    }
    else{
      return null;
    }
  }

  getMonitor(csskey: number){
    let item = this.getTemplateItem(csskey);
    if(item){
      return item.monitoring;
    }
    else{
      return null;
    }
  }

  getFacilityLocation(csskey: number){
    let item = this.getTemplateItem(csskey);
    if(item){
      return item.facilityLocation;
    }
    else{
      return null;
    }
  }

  getAuditDate(csskey: number){
    let item = this.getTemplateItem(csskey);
    if(item){
      return new Date(item.startDate).toDateString() + " - " + new Date(item.endDate).toDateString();
    }
    else{
      return null;
    }
  }

  getTemplateItem(csskey:number): TemplateList{
    var data = this.templateSubject.value;

    for(var i = 0; i < data.length; i++){
      if(data[i].csskey == csskey){
        return data[i];
      }
    }
    return null;
  }

  getNumber() : Observable<number> {
    return this.resultNumber.asObservable();
  }

  deleteRow(rowid: number): Observable<TemplateList[]>{
    var temData = this.templateSubject.value;
    temData.splice(rowid, 1);
    this.templateSubject.next(temData);
    this.resultNumber.next(this.resultNumber.value - 1);
    return this.templateSubject.asObservable();
  }

  connect(collectionViewer: CollectionViewer): Observable<TemplateList[]>{
  	return this.templateSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
  	this.templateSubject.complete();
  }
}