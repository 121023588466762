import { Injectable } from '@angular/core';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

import {AzureAuthService} from '../azure-auth/azure-auth.service';
import { AlertifyService } from '../_models/alertify.service';


@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(private authService: AzureAuthService,
    private alertsService: AlertifyService) { }

  async addEventToCalendar(newEvent: MicrosoftGraph.Event): Promise<void> {
    if (!this.authService.graphClient) {
      this.alertsService.error('Graph client is not initialized.');
      return undefined;
    }
    if (!this.authService.user) {
      this.alertsService.error('Graph client has not login.');
      return undefined;
    }
    try {
      // POST /me/events
      await this.authService.graphClient
        .api('/me/events')
        .post(newEvent);
    } catch (error) {
      console.error(JSON.stringify(error, null, 2));
      throw Error(JSON.stringify(error, null, 2));
    }
  }

  isLogin(): boolean{
    if (this.authService.graphClient && this.authService.user) {
      return true
    }
    return false;
  }
}
