<h2 mat-dialog-title>Editing Scoring Item</h2>
<form mat-dialog-content [formGroup]="myGroup">
        <mat-form-field class="score-name" mat-focused>
            <mat-label>Name: </mat-label>
            <input matInput formControlName="scoringNameControl" cdkFocusInitial>
            <mat-error *ngIf="myGroup.get('scoringNameControl').invalid">Name is required.</mat-error>
        </mat-form-field>
        <mat-form-field class="score-value" mat-focused>
            <mat-label>Score: </mat-label>
            <input type="number" [min] = "0"
              matInput formControlName="scoringValueFormControl" cdkFocusInitial>
              <mat-error *ngIf="myGroup.get('scoringValueFormControl').invalid">Score must be greater or equal to 0.</mat-error>
        </mat-form-field>
      </form>
<div mat-dialog-actions class="right-end">
  <button mat-button (click)="close()" class="mat-raised-button" cdkFocusInitial>Cancel</button>
  <button [disabled]="!myGroup.valid" class="mat-raised-button mat-primary" mat-button (click)="onUpdateRankingName()">Save</button>
</div>