import { DialogData } from "../_models/dialogData";

export class AppSettings {
    public static  US_STATES = ["AK","AL","AR","AZ","CO","CT","DE","FL","GA","HI","IA","ID","IL","IN","KS","KY","LA","MA","MD","ME","MI","MN","MO","MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY","OH","OK","OR","PA","PR","RI","SC","SD","TN","TX","UT","VA","VT","WA","WI","WV","WY"];
    public static HelpInfo: {id: number, helper: DialogData}[] = [
        {"id": 0, "helper": {"title": "Product List", "content": "<p>Choose Category – Products are separated into categories. Environmental, Health & Safety, etc.</p>"}},
        {"id": 1, "helper": {"title": "Release Notes", "content": `<p>Keeps you up to date on current changes and what is going on in the industry. Release notes are published with every update and contain up to 3 sections.</p>
        <ul><li><b>New and noteworthy</b>: Overview of key changes found in current updated version.</li>
        <li><b>Highlights of this release</b>: What and where changes have occurred.</li>
        <li><b>Did you know</b>: Information on current industry subjects and issues.</li></ul>`}},
        {"id": 2, "helper": {"title": "Format", "content": `<ul><li>PDF- Access to product.</li>
        <li>Word- Access to a Word version of scoresheets. For International Protocols, access to a Word version of the full protocol.</li>
        <li>Excel – Access to excel version of scoresheets.</li></ul>`}},
        {"id": 3, "helper": {"title": "New Template", "content": `<p>Add Audit Name, share function, dates. Facility and Facility Addresses are optional. Open the product and choose modules needed for Audit or Self-Assessment. SAVE.</p>`}},
        {"id": 4, "helper": {"title": "Share Template", "content": "<p>Allows you to share your template with all licensed users. Choose either <b>View</b> or <b>Edit</b></p>"}},
        {"id": 5, "helper": {"title": "Build Applicability", "content": "Answer applicability screening questions."}},
        {"id": 6, "helper": {"title": "Tip", "content": "Check <b>N/A</b> to any irrelevant rulebooks."}},
        {"id": 7, "helper": {"title": "Build Xcelerator", "content": `<p>Moves applicable audit statements into the Xcelerator file.
        An email will be sent once completed and an icon will highlight on the edit template page.</p>`}},
        {"id": 8, "helper": {"title": "Pocket Reference", "content": `<p>Gives you access to your EHS products and allows you to navigate and search the rulebooks and state differences.
        </p><p><b>Access online: </b>Start by first choosing a publication. Continue search by next choosing a module or enter a key word in Search. Add State differences at any time.
        </p><p><b>Access through a mobile app: </b>It can be accessed from any mobile device and can be found in App Store under Specialty Technical Publishers.
         Use your email address and your STP site password to gain access.</p><p>Start by choosing a publication.</p>`}},
        {"id": 9, "helper": {"title": "Build RegRegister", "content": `<p>Builds an excel list of all relevant regulatory citations
        included in that specific Template.</p>`}},
        {"id": 10, "helper": {"title": "View RegRegister", "content": `<p>Allows you to view the list of all relevant regulatory citations
        included in that specific Template</p>`}},
        {"id": 11, "helper": {"title": "Build for Partner", "content": `<p>It moves applicable audit statements to the EMIS partner of choice</p>`}},
        {"id": 12, "helper": {"title": "Custom Questions", "content": `<p>Can be added to your template and uploaded into the Xcelerator as part of your Audit
        or Self-Assessment.</p><p><b>New Custom Topic</b> - Add a new Topic and First Sub-Topic, SAVE. Then add <b>question</b>. <i>Citation reference</i>,
        <i>citation link</i> and <i>Guidenotes</i> are optional.</p><p>Click on <b>+</b>, continue adding questions.</p>
        <p>Once you have completed entering your custom questions, you can click on button <b>New Custom Topic</b> to add custom topic or on <b>+</b> to add
        new Sub-Topic.</p>
        <p>To edit, click on Sub-Topic name and make changes, additions, deletions. You may also remove Sub-Topics and Topics. Topics will be removed once all Sub-Topics
         are deleted.</p>`}},
        {"id": 13, "helper": {"title": "Ranking & Scoring", "content": `<h4>Allows you to customize scoring system that can be used in the Xcelerator tool for your Audit or Self-Assessment.</h4>
        <p>+ Add new custom template. Add template name, check Make this your Active Template. If you do not check this, the customization will NOT carry through to the Xcelerator tool.
        Option to Not share with Company. SAVE template.</p>
        <p>Audit Scoring and Severity Ranking – add scoring element and score amount. Hit +. Continue adding until complete.</p>
        <p>Can change colour scheme, edit or delete.</p>
        <p>Can delete Custom scoring template selecting template name and clicking on delete.</p>`}},
        {"id": 14, "helper":
            {"title": "AuditHub <br>&copy; 2024 STP ComplianceEHS",
            "content": `
                <h4>Version 1.0.73 Release Notes - Oct 29, 2024</h4>
                <ul  class="circleList">
                    <li>Bug fixed: the Pocket Reference is missing the empty federal sections and the associated state difference.</li>
                    <li>Bug fixed: displaying error message when there is no custom question.</li>
                    <li>Bug fixed: rulebooks populate as empty untill clear cache.</li>
                    <li>Dashboard info update</li>
                </ul>
                <h4>Version 1.0.72 Release Notes - Aug 29, 2024</h4>
                <ul  class="circleList">
                    <li>Users are logged out of AuditHub if they are inactive for a configured duration. The default timeout duration is 24 hours and can be configured as a company-wide setting.</li>
                    <li>For Customers using the Intelex integration, this release includes updates that will improve the success rate of pushing templates to Intelex and prevent users from re-pushing completed templates.</li>
                    <li>Dashboard info update</li>
                </ul>
        `}},
        {"id": 15, "helper": {"title": "Building Status", "content": `
        <ul  class="circleList">
        <li><b>0</b> - Waiting </li>
        <li><b>1</b> - In process</li>
        <li><b>2</b> - Completed </li>
        <li><b>3</b> - Failed </li>
        <li><b>4</b> - Failed: empty template </li>
        </ul>`}},
        {"id": 16, "helper": {"title": "Building Type", "content": `
        <ul  class="circleList">
        <li><b>X3.0</b> - request for building Xcelerator file.</li>
        <li><b>X3.1</b> - request for building unlocked Xcelerator file.</li>
        <li><b>C3.0</b> - request for building RegRegister file.&nbsp;&nbsp;&nbsp;&nbsp;</li>
        <li><b>csv</b> - request for building CSV file. </li>
        <li><b>reghub</b> - send monitoring request to RegHub. </li>
        <li><b>upgrading</b> - update template to latest protocol version. </li>
        <li><b>velocity</b> - send template data to partner Velocity. </li>
        <li><b>Ideagen EHS</b> - send template data to partner Ideagen EHS(ProcessMap). </li>
        <li><b>intelex</b> - send template data to partner Intelex. </li>
        <li><b>ETQ</b> - send template data to partner ETQ. </li>
        <li><b>SAFETYSTR</b> - send template data to partner SAFETYSTR. </li>
        <li><b>no</b> - for Partner Demo, no real building. </li>
        </ul>`}},
    ];
    public static STATES: {code: string, name:string}[] = [
        {"code":"AK","name":"ALASKA"}, {"code":"AL","name":"ALABAMA"}, {"code":"AR","name":"ARKANSAS"},
        {"code":"AZ","name":"ARIZONA"}, {"code":"CA","name":"CALIFORNIA"}, {"code":"CO","name":"COLORADO"},
        {"code":"CT","name":"CONNECTICUT"}, {"code":"DE","name":"DELAWARE"}, {"code":"FL","name":"FLORIDA"},
        {"code":"GA","name":"GEORGIA"}, {"code":"HI","name":"HAWAII"}, {"code":"IA","name":"IOWA"},
        {"code":"ID","name":"IDAHO"}, {"code":"IL","name":"ILLINOIS"}, {"code":"IN","name":"INDIANA"},
        {"code":"KS","name":"KANSAS"}, {"code":"KY","name":"KENTUCKY"}, {"code":"LA","name":"LOUISIANA"},
        {"code":"MA","name":"MASSACHUSETTS"}, {"code":"MD","name":"MARYLAND"}, {"code":"ME","name":"MAINE"},
        {"code":"MI","name":"MICHIGAN"}, {"code":"MN","name":"MINNESOTA"}, {"code":"MO","name":"MISSOURI"},
        {"code":"MS","name":"MISSISSIPPI"}, {"code":"MT","name":"MONTANA"}, {"code":"NC","name":"NORTH CAROLINA"},
        {"code":"ND","name":"NORTH DAKOTA"}, {"code":"NE","name":"NEBRASKA"}, {"code":"NH","name":"NEW HAMPSHIRE"},
        {"code":"NJ","name":"NEW JERSEY"}, {"code":"NM","name":"NEW MEXICO"}, {"code":"NV","name":"NEVADA"},
        {"code":"NY","name":"NEW YORK"}, {"code":"OH","name":"OHIO"}, {"code":"OK","name":"OKLAHOMA"},
        {"code":"OR","name":"OREGON"}, {"code":"PA","name":"PENNSYLVANIA"}, {"code":"PR","name":"PUERTO RICO"},
        {"code":"RI","name":"RHODE ISLAND"}, {"code":"SC","name":"SOUTH CAROLINA"}, {"code":"SD","name":"SOUTH DAKOTA"},
        {"code":"TN","name":"TENNESSEE"}, {"code":"TX","name":"TEXAS"}, {"code":"UT","name":"UTAH"}, {"code":"VA","name":"VIRGINIA"},
        {"code":"VT","name":"VERMONT"}, {"code":"WA","name":"WASHINGTON"}, {"code":"WI","name":"WISCONSIN"}, {"code":"WV","name":"WEST VIRGINIA"},
        {"code":"WY","name":"WYOMING"}
    ];
}