<mat-sidenav-container class="sidenav-container">
	<mat-sidenav #sidenav class="sidebar" mode="side" [(opened)]="opened" (opened)="events.push('open!')" (closed)="events.push('close!')">
		<mat-nav-list class="nav">
			<a mat-list-item class="nav-header">
				<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/08/Logo-Stp-white-orange.png" alt="logo"
				title="AuditHub Version: {{version}}">
			</a>
			<a
				[class.disabled]="true"
				routerLinkActive="active" mat-list-item class="has-sub" (click)="menuClick('home')">
				<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/08/Home-icon.png" class="dashboard-img"/>
				<span class="menu-item">Dashboard</span>
			</a>
			<a  (click)="menuClick('product')" mat-list-item class="has-sub" [disabled] = "account.userType.charAt(2) === '0'">
				<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/Toc-Icon-1.png " class="dashboard-img"/>
				<span class="menu-item">Product List</span>
			</a>

			<mat-expansion-panel *ngIf="account.userType.charAt(1) === '1'">
					<mat-expansion-panel-header id="panel-hader" class="app-menu">
							<a mat-list-item class="has-sub">
								<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/RegApply-Icon.png" class="dashboard-img"/>
								<span class="menu-item">RegApply</span>
							</a>
					</mat-expansion-panel-header>
					<a (click)="menuClick('list-template')" mat-list-item class="has-sub">RegApply Home</a>
					<a (click)="menuClick('new-template')" mat-list-item class="has-sub">New Template</a>
					<a (click)="menuClick('custom-questions')" mat-list-item class="has-sub">Custom Questions</a>
					<a (click)="menuClick('rank-and-score')" mat-list-item class="has-sub">Ranking & Scoring</a>
			</mat-expansion-panel>
			<mat-expansion-panel>
					<mat-expansion-panel-header id="panel-hader" class="app-menu">
						<a mat-list-item class="has-sub">
							<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/Request-Jurisdiction.png" class="dashboard-img"/>
							<span class="menu-item">Request Jurisdiction</span>
						</a>
					</mat-expansion-panel-header>
					<a (click)="menuClick('iprequest')" mat-list-item class="has-sub">New Request</a>
					<a (click)="menuClick('iprequest-list')" mat-list-item class="has-sub">My List</a>
			</mat-expansion-panel>
			<a (click)="menuClick('pocketref')" routerLinkActive="active" mat-list-item class="has-sub" [disabled] = "account.userType.charAt(2) === '0'">
				<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/Pocket-Reference-Icon.png" class="dashboard-img"/>
				<span class="menu-item">Pocket Reference</span>
			</a>
			<a *ngIf="regComparePermission" (click)="menuClick('regcompare')" routerLinkActive="active" mat-list-item class="has-sub">
				<img src="{{AuditHubDomain}}/assets/wp-content/uploads/RegCompare-Icon.png" class="dashboard-img"/>
				<span class="menu-item">RegCompare</span>
			</a>
			<mat-divider></mat-divider>
			<a mat-list-item class="has-sub"
				href=" https://outlook.office365.com/owa/calendar/STPComplianceSuiteTraining@glaciermedia.onmicrosoft.com/bookings/"
				target="_blank">
				<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/sign-up-for-training-icon.png " class="dashboard-img"/>
				<span class="menu-item">Sign up for Training</span>
			</a>
			<a mat-list-item class="has-sub" href="mailto:info@stpub.com">
				<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/Help-icon.png " class="dashboard-img"/>
				<span class="menu-item">Help</span>
			</a>
			<a [disabled] = "account.userType.charAt(0) === '0'" mat-list-item class="has-sub" href="{{RegHubDomain}}/dashboard" target="_blank">
				<img src="{{AuditHubDomain}}/assets/wp-content/uploads/2021/09/AuditHub-Icon.png " class="dashboard-img material-icons"/>
				<span class="menu-item">STP RegHub</span></a>
			<a href="https://elibrary.stpehs.com/videos" target="_blank" mat-list-item class="has-sub">
				<span class="dashboard-img material-icons">video_library</span>
				<span class="menu-item">Training Videos</span>
			</a>
			<mat-expansion-panel *ngIf = "account.companyId == 355">
				<mat-expansion-panel-header id="panel-hader" class="app-menu">
					<a mat-list-item class="has-sub">
						<mat-icon><span class="material-icons-outlined">monitor_heart</span></mat-icon>
						<span class="menu-item">Internal Tools</span>
					</a>
				</mat-expansion-panel-header>
				<a (click)="menuClick('buildingStatus')" routerLinkActive="active" mat-list-item class="has-sub" *ngIf = "account.companyId == 355">
					<span class="menu-item">Building Status</span>
				</a>
				<a (click)="menuClick('publicationView')" routerLinkActive="active" mat-list-item class="has-sub" *ngIf = "account.companyId == 355">
					<span class="menu-item">Product Views</span>
				</a>
			</mat-expansion-panel>

            <!--a href="javascript:;" mat-list-item class="sidebar-minify-btn sidebar-toggle"><button (click)="toggleSidenav()" mat-icon-button>
    					<mat-icon aria-label="open/close sidebar">swap_horiz</mat-icon>
  					</button></a-->

			        <!-- end sidebar minify button -->

     <!--New items in the menu</a-->
				</mat-nav-list>
	</mat-sidenav>

	<mat-sidenav-content>
		<mat-toolbar color="#707478" class="fixed-top stp-header">
			<app-header  class="mainheader"
				[user]="account.name"
				[userEmail]= "userEmail"
				[title]="title"
				(trigger)="goHome()"
				(openMenuEvent)="openSideMenu($event)"></app-header>
		</mat-toolbar>
	    <div id="content" class="main-wrapper">
			<router-outlet></router-outlet>
			<div class="push"></div>
		</div>
		<stp-footer></stp-footer>
  	</mat-sidenav-content>
</mat-sidenav-container>
<!-- Start of stpub Zendesk Widget script -->
<script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=f54263b9-c9be-4bdc-8302-8191042a9143"> </script>
<!-- End of stpub Zendesk Widget script -->