import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import {CookieService} from 'ngx-cookie-service';
import { AuthService } from '@stp/auth/auth.service';
import {Subscription, interval} from 'rxjs';
import {IdleService} from './idle-service';
import Swal, {SweetAlertResult} from 'sweetalert2';
import {skip} from 'rxjs/operators';
import { UserProfileSettings } from './user-profile-api.service';

@Injectable({
  providedIn: 'root'
})
/*
* Handle user session timeout [5803] */
export class SessionTimeoutService {

  secondsInMinute = 60;

  // When not idle is detected, this cookie will be set to the UTC epoch time of
  // the expiry datetime as a heartbeat for multiple tabs so user is not logged out
  // when they are active in other tabs or AH
  isIdleCookieName = environment.cookieIdleMultiTab;

  // Time limit until user is warned about timeout
  sessionTimeoutWarningPopupDuration: number;

  // How long the warning pop-up is displayed until logout (seconds)
  sessionTimeoutDuration: number;

  isIdle = true;

  isInitialized = false;

  // Timer event to handle main logic
  intervalSubscription: Subscription;

  // Swal object, this class variable is used to call warningPopup.close() when user cancels the timeout warning.
  // The purpose is to close just this timeout warning; however, in practice, the current version of Swal
  // actually closes all pop-ups, even when attempting to close only this specific one, so there is a bug
  warningPopup: any;

  isLoggingOut = false;

  // The main control variable used to enter state of timeout warning pop-up countdown
  isShowingSessionTimeoutWarning = false;

  constructor(private cookieService: CookieService, private authService: AuthService,
              private idleService: IdleService) {

    const timeout = sessionStorage.getItem(UserProfileSettings.timeout);
    const timeoutWarning = sessionStorage.getItem(UserProfileSettings.timeoutWarning);

    if (!this.isEmptyValue(timeoutWarning)) {
      this.sessionTimeoutWarningPopupDuration = this.toMs(Number(timeoutWarning) * this.secondsInMinute);
    }

    if (!this.isEmptyValue(timeout)) {
      this.sessionTimeoutDuration = this.toMs(Number(timeout) * this.secondsInMinute);
    }

    this.initializeSessionTimeout();
    this.updateTimeoutSettings = this.updateTimeoutSettings.bind(this);

    this.clearTimerEvent = this.clearTimerEvent.bind(this);
    this.onBeforeunload = this.onBeforeunload.bind(this);
    window.addEventListener('beforeunload', this.onBeforeunload);
  }

  updateTimeoutSettings(userProfileSettings) {
    // const lastUserInteractionTime = Number(this.cookieService.get(this.isIdleCookieName));
    // console.log(`lastUserInteractionTime: ${lastUserInteractionTime}`);
    this.sessionTimeoutWarningPopupDuration = this.toMs(userProfileSettings.timeoutWarning * this.secondsInMinute);
    this.sessionTimeoutDuration = this.toMs(userProfileSettings.timeout * this.secondsInMinute);
    if (this.cookieService.check(this.isIdleCookieName)) {
      // When all tabs are closed but user did not log out, this cookie will remain
      this.logUserInIfTimeoutExpires();
    }
    this.isInitialized = true;
  }

  initializeSessionTimeout() {
    if (this.cookieService.get(this.isIdleCookieName) === '' ||
      Number(this.cookieService.get(this.isIdleCookieName)) === 0) {
      this.updateIdleCookie();
    }

    if (this.cookieService.check(this.isIdleCookieName)) {
      this.logUserInIfTimeoutExpires();
    }

    this.onWake = this.onWake.bind(this);
    this.idleService.wake$.pipe(skip(1)).subscribe(this.onWake);

    this.onIdle = this.onIdle.bind(this);
    this.idleService.idle$.pipe(skip(1)).subscribe(this.onIdle);

    // console.log(`this.isInitialized = ${this.isInitialized}`);
    if (this.intervalSubscription === undefined/* && this.isInitialized === true*/) {
      this.intervalSubscription = interval(1000).subscribe(
        () => {
          // wait until the timeout values have been fetched to process timeout
          if (!this.isInitialized) {
            return;
          }

          // Already logging out, stop processing
          if (this.isLoggingOut) {
            return;
          }

          // If the app is initialized and not logging out, but the activeAt cookie has been
          // removed, log the user out, as they have been logged out in another tab.
          if (!this.cookieService.check(this.isIdleCookieName)) {
            this.isLoggingOut = true;
            this.logOut();
            return;
          }

          // Pause when tab not active
          if (document.hidden && this.isShowingSessionTimeoutWarning) {
            return;
          }

          const lastUserInteractionTime = Number(this.cookieService.get(this.isIdleCookieName));
          const now = new Date().getTime();

          // tslint:disable-next-line:max-line-length
          // console.log(`lastUserInteractionTime + this.sessionTimeoutDuration - now = ${lastUserInteractionTime + this.sessionTimeoutDuration - now}`);
          // console.log(`this.isShowingWarning = ${this.isShowingSessionTimeoutWarning}`);
          // console.log(`lastUserInteractionTime = ${lastUserInteractionTime}`);

          if (lastUserInteractionTime + this.sessionTimeoutWarningPopupDuration <= now) {
            if (!this.isShowingSessionTimeoutWarning && this.isIdle) {
              this.showSessionTimerWarningPopUp();
              this.isShowingSessionTimeoutWarning = true;
            }
          } else {
            this.closeSessionTimerWarningPopup();
          }

          if (this.isShowingSessionTimeoutWarning) {
            this.logUserOutIfTimeoutExpires();
          }
        });
    }
  }

  onWake() {
    // console.log(`variable: ${variable}`);
    // console.trace();
    this.isIdle = false;
    if (!this.isShowingSessionTimeoutWarning) {
      // console.log(`onWake: ${new Date().getTime()}`);
      // isInitialized is true after timeout is initialized and logout check is performed.
      // therefore, do not automatically update the idle cookie until the check is performed
      if (this.isInitialized) {
        this.updateIdleCookie();
      }
    }
  }

  onIdle() {
    this.isIdle = true;
    if (!this.isShowingSessionTimeoutWarning) {
      // console.log(`onWake: ${new Date().getTime()}`);
      // isInitialized is true after timeout is initialized and logout check is performed.
      // therefore, do not automatically update the idle cookie until the check is performed
      if (this.isInitialized) {
        this.updateIdleCookie();
      }
    }
  }

  logUserInIfTimeoutExpires() {
    const lastUserInteractionTime = Number(this.cookieService.get(this.isIdleCookieName));

    // console.log(`lastUserInteractionTime: ${lastUserInteractionTime}`);
    // Invalid/non-existent cookie
    // if (lastUserInteractionTime === 0) {
    //   return;
    // }

    // tslint:disable-next-line:max-line-length
    // console.log(`lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration <= new Date().getTime(): ${lastUserInteractionTime} + ${this.sessionTimeoutDuration} + ${this.sessionTimeoutWarningPopupDuration} <= ${new Date().getTime()}`);

    // tslint:disable-next-line:max-line-length
    // console.log(`lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration <= new Date().getTime(): ${lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration} <= ${new Date().getTime()}`);

    // tslint:disable-next-line:max-line-length
    // console.log(`lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration <= new Date().getTime(): ${lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration - new Date().getTime()}`);

    if (lastUserInteractionTime + this.sessionTimeoutWarningPopupDuration + this.sessionTimeoutDuration <= new Date().getTime()) {
      this.deleteCookies();
      window.location.href=environment.authURL;
    }
  }

  logUserOutIfTimeoutExpires() {
    const lastUserInteractionTime = Number(this.cookieService.get(this.isIdleCookieName));

    // console.log(`lastUserInteractionTime: ${lastUserInteractionTime}`);
    // Invalid/non-existent cookie
    // if (lastUserInteractionTime === 0) {
    //   return;
    // }

    // console.log(`lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration <= new Date().getTime(): ${lastUserInteractionTime} + ${this.sessionTimeoutDuration} + ${this.sessionTimeoutWarningPopupDuration} <= ${new Date().getTime()}`);
    //
    // console.log(`lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration <= new Date().getTime(): ${lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration} <= ${new Date().getTime()}`);
    //
    // console.log(`lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration <= new Date().getTime(): ${lastUserInteractionTime + this.sessionTimeoutDuration + this.sessionTimeoutWarningPopupDuration - new Date().getTime()}`);
    if (lastUserInteractionTime + this.sessionTimeoutWarningPopupDuration + this.sessionTimeoutDuration <= new Date().getTime()) {
      this.logOut();
    }
  }

  private updateIdleCookie() {
    // Cookie is sometimes set incorrectly when user is logging out, causing double login issue
    if (this.isLoggingOut) {
      return;
    }
    // console.log('updateidlecookie');
    // console.trace();
    // console.log(`updateIdleCookie: ${new Date().getTime()}`);
    SessionTimeoutService.resetCounter(this.cookieService);
  }

  private showSessionTimerWarningPopUp() {
    if (this.isLoggingOut) {
      return;
    }
    const timeoutDisplayValue = Math.round(this.toS(this.sessionTimeoutDuration) / this.secondsInMinute);
    this.warningPopup = Swal.fire({
      title: 'Session Timeout',
      // tslint:disable-next-line:max-line-length
      html: `<p>Warning: Your session will be closed in <span id="warnTimeout-3fcf465dbbf8">${timeoutDisplayValue}</span> ${timeoutDisplayValue === 1 ? 'minute' : 'minutes'}.<br/>Would you like to stay connected?</p>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Stay connected',
      cancelButtonText: 'Log out',
      backdrop: true
    });

    this.warningPopup.then((result: SweetAlertResult) => {
      if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
        this.logOut();
      } else if (result.isConfirmed || result.isDismissed) {
        this.closeSessionTimerWarningPopup();
      }
    });
  }

  closeSessionTimerWarningPopup() {
    if (!this.isShowingSessionTimeoutWarning) {
      return;
    }

    this.isShowingSessionTimeoutWarning = false;
    if (typeof this.warningPopup !== 'undefined' && typeof this.warningPopup.close !== 'undefined') {
      this.warningPopup.close();
      // ensure that idle state is removed when user interacts with the popup by closing it

      // console.log(`closeSessionTimerWarningPopup`);
      this.onWake();
    }
  }

  clearTimerEvent() {
    if (this.intervalSubscription !== undefined && this.intervalSubscription !== null) {
      this.intervalSubscription.unsubscribe();
    }
  }
  deleteCookies(){
    this.cookieService.deleteAll("/", environment.DOMAIN);
  }

  logOut() {
    this.clearTimerEvent();
    this.isLoggingOut = true;
    this.cookieService.delete(this.isIdleCookieName, '/', environment.DOMAIN);
    this.authService.signOut();
  }

  toS(milliseconds: number) {
    return Math.floor(milliseconds / 1000);
  }

  toMs(seconds: number) {
    return seconds * 1000;
  }

  isEmptyValue(item) {
    return item === null || item === undefined || item === '';
  }

  onBeforeunload() {
    this.clearTimerEvent();
  }

  public static resetCounter(cookieService: CookieService) {
    const utcMilliseconds = new Date().getTime();
    cookieService.set(
      environment.cookieIdleMultiTab,
      utcMilliseconds.toString(),
      1,
      '/',
    environment.DOMAIN, 
    true);
  }
}
