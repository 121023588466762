import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';

import { CustomQuestionService } from '../../service/custom-question.service';
import { AlertifyService } from '../../../_models/alertify.service';
import {CustomRanking,CustomScoring, KeyValuePair} from '../../model/CustomQuestion';


@Component({
  selector: 'app-edit-scoring',
  templateUrl: './edit-scoring.component.html',
  styleUrls: ['./edit-scoring.component.css']
})
export class EditScoringComponent implements OnInit {
  @Input() scoringList: CustomScoring[];
  @Input() rankingList: CustomRanking[];
  @Input() selectedTemplateKey: string;
  @Input() selectedUserId: number;
  @Output() loadContents = new EventEmitter<boolean>();
  @Output() addRankingItem = new EventEmitter<CustomRanking>();
  @Output() addScoringItem = new EventEmitter<CustomScoring>();

  displayScoring = true;
  newRank: string;
  newScoreName: string;
  newScoreValue: number;
  scoreValueControl = new FormControl("",[Validators.required, Validators.min(0)]);
  currentUserId = JSON.parse(localStorage.getItem("account")).id;

  constructor(
    public service: CustomQuestionService,
    private alertifyService: AlertifyService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    //console.log("will display template: " + this.selectedItem);
    this.newRank = undefined;
    this.newScoreName = undefined;
    this.newScoreValue = undefined;
  }

  // Switch from Ranking to Scoring, or verse.
  onSwitch(ind: boolean){
    if(ind != this.displayScoring){
      this.displayScoring = ind;
      this.loadContents.emit(ind);
    }
  }

  // change the color for rank item or scoring item.
  onColorChange(event, itemKey: number){
    console.log("color changed to" + event + " for " + itemKey + this.displayScoring);
    if(this.displayScoring){// change the color for scoring item.
      for(let item in this.scoringList){
        if(this.scoringList[item].ScoringKey == itemKey){
          this.scoringList[item].HexColor = event;
          this.service.updateScoringElement(this.scoringList[item]).subscribe(
            rep => {
              console.log("backend return: " + rep);
            }
          );
        }
      }
    }else{ // change the color for rank item.
      for(let item in this.rankingList){
        console.log("check " + this.rankingList[item].RankingName);
        if(this.rankingList[item].RankingKey == itemKey){
          this.rankingList[item].HexColor = event;
          this.service.updateRankingElement(this.rankingList[item]).subscribe(
            rep => {
              console.log("backend return: " + rep);
            }
          );
          break;
        }
      }
    }
  }

  // Click on delete button while in ranking form.
  onDeleteRanking(rankingKey: number){
    var rep = confirm("Are you sure to delete the ranking item?");
    if(rep){
      this.service.deleteRankingElement(rankingKey).subscribe(
        rep => {
          this.alertifyService.success("Rank item deleted.");
          var index = 0;
          for(let element of this.rankingList){
            if(element.RankingKey == rankingKey){
              this.rankingList.splice(index, 1);
              return false;
            }
            index++;
          }
        }
      );
    }
  }

  validateScore(event:number){
    if(event < 0){

    }
  }


  // Click on delete button while in scoring form.
  onDeleteScoring(scoringKey: number){
    var rep = confirm("Are you sure to delete the scoring item?");
    if(rep){
      this.service.deleteScoringElement(scoringKey).subscribe(
        rep => {
          this.alertifyService.success("Scoring item deleted.");
          var index = 0;
          for(let element of this.scoringList){
            if(element.ScoringKey == scoringKey){
              this.scoringList.splice(index, 1);
              return false;
            }
            index++;
          }
        }
      );
    }
  }

  // Click on edit button
  onEditRanking(rankingKey: number){
    var index: string;

    for(let item in this.rankingList){
      console.log("check " + this.rankingList[item].RankingName);
      if(this.rankingList[item].RankingKey == rankingKey){
        index = item;
        break;
      }
    }

    const dialogRef = this.dialog.open(EditRankingDialog, {
      position: {top: '200px'},
      width: '480px',
      data:this.rankingList[index].RankingName
    });

    dialogRef.afterClosed().subscribe(result => {
      this.rankingList[index].RankingName =  result.rankingNameFormControl;
      this.service.updateRankingElement(this.rankingList[index]).subscribe(
        rep => {
          console.log("backend return: " + rep);
          this.alertifyService.success("Item saved.");
        },
        error =>{
          this.alertifyService.error(error.message);
        }
      );
    });
  }

  onEditScoring(scoringKey: number){
    var index: string;

    for (let item in this.scoringList){
      if(this.scoringList[item].ScoringKey === scoringKey){
        index = item;
        break;
      }
    }

    const dialogRef = this.dialog.open(EditScoringDialog, {
      position: {top: '200px'},
      width: '800px',
      data: {scoringName: this.scoringList[index].ScoringName, scoringValue: this.scoringList[index].ScoringValue}
    });

    dialogRef.afterClosed().subscribe(result =>{
      console.debug("dialog close.");
      if(result){
        this.scoringList[index].ScoringName = result.scoringNameControl;
      this.scoringList[index].ScoringValue = +result.scoringValueFormControl;
      console.log("to update.");

      this.service.updateScoringElement(this.scoringList[index]).subscribe(
        rep => {
          console.log("backend return: " + rep);
          this.alertifyService.success("Item saved.");
        },
        error =>{
          console.error("backend return error: " + error.error);
          this.alertifyService.error(error.message);
        }
      );
      }

    });
  }

  // add a scoring item.
  onAddScoring(scoringName: string, scoringValue: number){
    const randomColor = Math.floor(Math.random()*16777215).toString(16);
    var newScoringItem: CustomScoring = {
      ScoringKey: 0,
      ScoringName: scoringName,
      ScoringValue: scoringValue,
      HexColor: '#' + randomColor
    }
    this.newScoreName = undefined;
    this.newScoreValue = undefined;
    this.addScoringItem.emit(newScoringItem);
  }

  // Click on Default rank item.
  onDefaultRank(){
    this.alertifyService.warning("Cannot edit the default template.");
  }

  onAddRank(rankName: string){
    console.log("to add a new rank.");
    this.newRank = rankName;
    if(this.rankingList && this.rankingList.length == 10){
      alert("You are unable to add more than 10 items of severity at this time.  Please delete some before adding new items.");
      return false;
    }
    const randomColor = Math.floor(Math.random()*16777215).toString(16);

    var newRankItem: CustomRanking = {
      RankingKey: 0,
      RankingName: this.newRank,
      HexColor: '#' + randomColor
    }
    /*if(!this.rankingList){
      this.rankingList = [newRankItem];
    }else{
      this.rankingList.push(newRankItem);
    }*/
    this.newRank = undefined;
    //console.log("add a new ranking item: " + newRankItem.RankingName);
    this.addRankingItem.emit(newRankItem);
    //console.log("emit event.");
  }
}

@Component({
  selector: 'edit-scoring',
  templateUrl: './edit-scoring.html',
  styleUrls: ['./edit-scoring.component.css']
})
export class EditScoringDialog {
  myGroup: FormGroup;
  scoringNameControl: FormControl;
  scoringValueFormControl: FormControl;
  scoringName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditScoringDialog>
  ){

    this.myGroup = new FormGroup({
      scoringNameControl: new FormControl(data.scoringName, Validators.required),
      scoringValueFormControl: new FormControl(data.scoringValue, [Validators.required, Validators.min(0)])
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  // To save the new custom scoring item.
  onUpdateRankingName(){
    this.dialogRef.close(this.myGroup.value);
  }
}

@Component({
  selector: 'edit-ranking',
  templateUrl: './edit-ranking-name.html',
  styleUrls: ['./edit-scoring.component.css']
})
export class EditRankingDialog{

  myGroup: FormGroup;
  rankingNameFormControl: FormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditRankingDialog>)
  {
      this.myGroup = new FormGroup({
        rankingNameFormControl: new FormControl(this.data, Validators.required)
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  // To save the new custom rank.
  onUpdateRankingName(){
    console.log("update ranking name." + this.myGroup.value) ;
    this.dialogRef.close(this.myGroup.value);
  }
}