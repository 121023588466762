export const RouteConfigs = {
  publications: '/publication',
  users: 'companies/:companyId/users',
  login: '/',
  forgotPassword: 'forgot-password',
  newPassword: 'new-password/:email/:token',
  errorPage: 'error-page',
  questionnaires: 'questionnaires',
  questionnaireDetail: 'questionnaires/:questionnaireId',
  questionnaireDetailSetup: 'setup',
  questionnaireDetailScoreSettings: 'settings',
  questionnaireDetailResult: 'result',
  questionnaireDetailRuleBook: 'ruleBooks/:ruleBookId',
  questionnaireDetailSection: 'ruleBooks/:ruleBookId/sections/:sectionId',
  questionnaireDetailQuestion: 'ruleBooks/:ruleBookId/sections/:sectionId/questions/:questionId',
  questionnaireDetailCustomerType: 'customSections/:typeId',
  questionnaireCustomerTypeQuestion: 'customSections/:typeId/questions/:questionId'
};
