import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserModule } from './user/user.module';
import { UsersComponent } from './user/container/users/users.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HomeLayoutComponent } from  './layouts/home-layout/home-layout.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from './app-material/app-material.module';
import { DatePipe} from '@angular/common';
import { CookieService} from 'ngx-cookie-service';

import { PublicationComponent } from './publication/publication.component';
import {HelpInfoDialog } from './_components/header/header.component';

import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ApptoolComponent } from './apptool/apptool.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {CredentialsInterceptor} from './core/noop-interceptor';
import { ApptoolModule } from './apptool/apptool.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { GlobalHttpInterceptorService } from './core/GlobalHttpInterceptorService';
import { ConfigService } from './_common/config-service';

// for integration with MS Graph
import { IPublicClientApplication,
         PublicClientApplication,
         BrowserCacheLocation } from '@azure/msal-browser';
import { MsalModule,
         MsalService,
         MSAL_INSTANCE } from '@azure/msal-angular';
import { OAuthSettings } from './oauth/oauth';

import { IpRequestModule} from './ip-request/ip-request.module';
import { PocketRefModule} from './pocket-ref/pocket-ref.module';
import { JsonArrayPipe,  SafePipe, FilterPipe} from './json-array.pipe';
import { ShowPublicationComponent, OutlineComponent } from './publication/show-publication/show-publication.component';
import { SsoComponent } from './sso/sso.component';

import { DxVectorMapModule } from 'devextreme-angular';
import { NotFoundComponent } from './not-found-component/not-found-component.component';
import { SasolComponent } from './sasol/sasol.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {interceptorProviders} from './core/interceptors';
import { TransportComponent } from './transport/transport.component';
import { RegcompareModule } from './regcompare/regcompare.module';
import { TrainVideoComponent } from './train-video/train-video.component';
import { PlayVideoComponent } from './train-video/play-video/play-video.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { UserIdleModule } from 'angular-user-idle';


let msalInstance: IPublicClientApplication | undefined = undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance =  new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      redirectUri: OAuthSettings.redirectUri,
      postLogoutRedirectUri: OAuthSettings.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    }
  });

  return msalInstance;
}

export function getConfigdata(configSer: ConfigService){
  return () => configSer.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeLayoutComponent,
    LoginComponent,
    PublicationComponent,
    LoginLayoutComponent,
    ApptoolComponent,
    JsonArrayPipe, SafePipe, FilterPipe,
    ShowPublicationComponent , OutlineComponent, SsoComponent,
     NotFoundComponent, SasolComponent, HelpInfoDialog,  TransportComponent, TrainVideoComponent, PlayVideoComponent,
  ],
  imports: [
    ApptoolModule,
    BrowserModule,
    MDBBootstrapModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    UserModule,
    AppMaterialModule,
    DashboardModule,
    AppRoutingModule,
    BrowserAnimationsModule,MatButtonToggleModule,
    IpRequestModule, PocketRefModule, DxVectorMapModule, DragDropModule, RegcompareModule,
    MsalModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production, 
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    interceptorProviders,// it contains more than one interceptors.
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: APP_INITIALIZER,
      useFactory: getConfigdata,
      deps: [ConfigService],
      multi: true
    },
    MsalService,
    CookieService
  ],
  bootstrap: [AppComponent],
  entryComponents: [OutlineComponent,HelpInfoDialog]
})
export class AppModule { }
