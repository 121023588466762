import { ApiResponse } from './apiResponse';

export class Account {
  public id: number;
  public userId: number;
  public companyId: number;
  public firstName: string;
  public lastName: string;
  public roles: string[];
  public email: string;
  public companyName: string;
  public name: string;
  public emis: number;
  public userType: string;
}

export class Auth extends ApiResponse {
  public token: string;
  public user: Account;
}


export interface UserProfile {
  aud: string;
  acr: string;
  auth_time: number;
  signInName: string;
  CompanyName:string;
  STPID: string;
  exp: number;
  extension_Company_Name: string;
  extension_Permission_Reg: string;
  iat: number;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  sub: string;
  ver: string;
  RegHubFeaturePermission: string;
  RegHubIntlPermission: string;
  AuditHubPermission: string;
  EMISPartnerID: string;
  UserID: string;
  CompanyID: string;
}

export interface AuditHubPermissionModel{
  feature: string;
  eaf: string;
  of: string;
}

export interface LoginValue{
  email: string;
  password: string;
  userId: number;
  companyId: number;
}

export class User {
  displayName!: string;
  email!: string;
  avatar!: string;
  timeZone!: string;
}