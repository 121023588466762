import { Component, OnInit,Injectable } from '@angular/core';

import { TemplateService } from '../../apptool/service/template.service';
import { Country } from '../../apptool/model/InternationalProtocol';
import { AlertifyService } from '../../_models/alertify.service';
import  { AuthService} from '../../auth/auth.service';


@Component({
  selector: 'app-my-list',
  templateUrl: './my-list.component.html',
  styleUrls: ['./my-list.component.css']
})
export class MyListComponent implements OnInit {
  IPArray: Country[] = [];  
  selectCountries = new Set<number>();
  
  constructor(public service: TemplateService, private authService: AuthService, private alertifService: AlertifyService) {   
  }

  ngOnInit() {
    console.log("mylist start.");
    let account = AuthService.currentUser;
    this.service.showLoadingAnimation(true);   
    this.service.getMyIP(account.id).subscribe(data => {           
      data.forEach(element => {
        element.Country.forEach(con => {
          this.IPArray.push(con);
        });        
      });
      this.service.showLoadingAnimation(false);
    });
  }

}