import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-play-video',
  templateUrl: './play-video.component.html',
  styleUrls: ['./play-video.component.css']
})
export class PlayVideoComponent implements OnInit {
  src: string;
  @ViewChild('videoRef', { static: true }) videoRef!: ElementRef

  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      paraMap => {
        this.src = paraMap.get('id');
        console.log("to open : " + this.src);

      })
  }

  ngAfterViewInit(): void {
    ​const media = this.videoRef.nativeElement
    ​media.muted = true
    ​media.play()
  ​ }

}
