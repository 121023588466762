import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from 'rxjs';
import { map, take} from 'rxjs/operators';
import { AuthService } from './auth.service';
import { SessionTimeoutService } from '../_common/session-timeout-service';
import { UserProfileApiService } from '../_common/user-profile-api.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router, private userProfileApiService: UserProfileApiService,
		 private sessionTimeoutService: SessionTimeoutService, private route: ActivatedRoute, private cookieService: CookieService) {}


	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		this.userProfileApiService.initializeUserProfileApi(route, this.sessionTimeoutService.updateTimeoutSettings);

		let url: string = state.url;
		return this.authService.isLoggedIn.pipe(
	    	take(1),
	    	map((isLoggedIn: boolean) => {
	    		if (!isLoggedIn) {
	    			console.log("isLoggedIn: false." );
	    			this.router.navigate(['/']);
	    			return false;
        		}
        		//console.log("AuthGard: isLoggedIn = true." );
        		return true;
        	})
        );
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean{
		return this.checkLogin(state.url);
	}

	checkLogin(url: string): boolean {
		console.log("isLoggedIn? " + this.authService.isLoggedIn);
		if(this.authService.isLoggedIn) {
			return true;
		}

		// Store the attempted URL for redirecting
		this.authService.redirectUrl = url;

		//Navigate to the login page with extras
		this.router.navigate(['']);
		return false;
	}
}
