import { Component, AfterViewInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import { Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import {ActivatedRoute, Router } from '@angular/router';
import {MatDialog, MatDialogRef, } from '@angular/material/dialog';

import {TemplateCategory, TemplateHead, CheckTopic, TemplateAudit,TemplateTopic} from '../model/TemplateTopics';
import {TemplateList} from '../model/TemplateList';
import {AuditContent} from '../model/TemplateRulebook';
import { TemplateService } from '../service/template.service';
import { AlertifyService } from '../../_models/alertify.service';
import { HelpInfoDialog} from '../../_components/header/header.component';

import { NewEvent } from '../../graph/new-event';
import { GraphService } from '../../graph/graph.service';
import { AzureAuthService } from '../../azure-auth/azure-auth.service';


@Component({
  selector: 'app-new-audit',
  templateUrl: './new-audit.component.html',
  styleUrls: ['./new-audit.component.css']
})
export class NewAuditComponent implements AfterViewInit {
  @ViewChild('mapContainer') mapElement: any;
  templateHead = new FormGroup({
    auditName: new FormControl('', [Validators.required]),
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
    shared: new FormControl(''),
    facilityName: new FormControl(''),
    facilityAddress: new FormControl('')
  });

  tHead: TemplateHead;
  permission = "Not";

  step = -1;
  csskey?: number;
  isShared?: boolean;
  isNew = true;

  data$: Observable<TemplateAudit>;
  topics: TemplateCategory[];
  currentCategory: string;
  cacheTopickey: number[] = new Array();
  cacheCustomTopic: number[] = new Array();

  isLoading = false;  // Flag to froze the page when loading.
  map: google.maps.Map;

  address: Object;
  establishmentAddress: Object;
  startDateValue: Date;
  formattedAddress: string;
  formattedEstablishmentAddress: string;
  editable  = true;

/*
  // Is a user logged in microsoft?
  get authentiacted(): boolean {
    return this.azureService.authenticated;
  }

  // the user
  get user(): User | undefined {
    return this.azureService.user;
  }
*/

  constructor(
    public service: TemplateService,
    private router: Router,
    private route: ActivatedRoute,
    private alertifyService: AlertifyService,
    public zone: NgZone,
    private azureService: AzureAuthService,
    private graphService: GraphService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(
      paraMap => (
        this.csskey = +paraMap.get('id')
      )
    );

    this.data$ = this.service.getAcronyms(this.csskey);
    this.startDateValue = new Date();
    this.refreshTopic();
  }

  initMat(): void {
    const myLatlng = { lat: 49.267784, lng:  -123.113351 };
    const coordinates = new google.maps.LatLng(myLatlng);

    const marker = new google.maps.Marker({
      position: coordinates,
      map: this.map,
      title: 'STP'
    });
    this.mapInitializer(coordinates, marker);
  }


  mapInitializer(coordinates, marker) {
    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: 8
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.map.setCenter(coordinates);
    this.map.setMapTypeId('roadmap');
    marker.setMap(this.map);
    //this.getAddress(marker.position);
    this.map.addListener('click',(event) => {
      this.placeMapMarker(event.latLng);
    });
  }

  refreshTopic() {
    this.service.showLoadingAnimation(true);
    this.data$.subscribe(
      res => {
        this.fillTopic(res);
        let userId = JSON.parse(localStorage.getItem("account")).id;
        if(res.auditHead && res.auditHead.clientId !== userId){
          if(res.auditHead.permission.trim() === "View"){
            this.editable = false;
          }
        }
        this.service.showLoadingAnimation(false);
      },
      error =>{
        this.alertifyService.error(error.error);
        this.service.showLoadingAnimation(false);
      }
    );
  }

  fillTopic(data: TemplateAudit) {
    this.currentCategory = data.categoryAcronyms[0].category;

    if(data.auditHead != undefined) {
      this.isNew = false;
      this.fillTemplateHead(data.auditHead);
      this.permission = data.auditHead.permission !== null? data.auditHead.permission.trim() : 'View';
      this.isShared = data.auditHead.shared;
    }

    this.topics = data.categoryAcronyms;
    this.topics.forEach(acronym => {
      acronym.selected = 0;

      acronym.acronyms.forEach(a => {
        acronym.selected += a.selected;
        if(acronym.category == "IP" || acronym.category == "CAN-MEX" ) {
          a.title = a.title.substring(a.title.indexOf("- ") + 1);
        }
      })
    });
  }

  getAddress(place: object) {
    this.address = place['formatted_address'];
    this.formattedAddress = place['formatted_address'];
    this.refreshAddressOnMap();

    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }

  getEstablishmentAddress(place: object) {
    this.establishmentAddress = place['formatted_address'];

    this.formattedEstablishmentAddress = place['formatted_address'];
    this.zone.run(() => {
      this.formattedEstablishmentAddress = place['formatted_address'];

    });
  }

  getAddrComponent(place, componentTemplate) {
    let result;

    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' },
      streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: 'long_name' },
      street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getCity(place) {
    const COMPONENT_TEMPLATE = { locality: 'long_name' },
      city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getState(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getDistrict(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getCountryShort(place) {
    const COMPONENT_TEMPLATE = { country: 'short_name' },
      countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return countryShort;
  }

  getCountry(place) {
    const COMPONENT_TEMPLATE = { country: 'long_name' },
      country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  // Fill value for Inputs in Reactive Form with data from backend.
  fillTemplateHead(headData: TemplateHead){
    this.tHead = headData;
    this.templateHead.get('auditName').setValue(this.tHead.auditName);
    this.templateHead.get('startDate').setValue(this.tHead.auditStart);
    this.templateHead.get('endDate').setValue(this.tHead.auditEnd);
    this.templateHead.get('facilityName').setValue(this.tHead.facilityName);
    this.templateHead.get('facilityAddress').setValue(this.tHead.facilityLocation);
    this.isShared = headData.shared;

    if(headData.facilityLocation){
      this.formattedAddress = headData.facilityLocation;
      this.refreshAddressOnMap();
    }
  }

  ngAfterViewInit() {
   this.initMat();
  }

  mapCategory(category: string):string{
    switch(category){
      case 'ENV':
        return 'Environment';
      case 'IP':
        return 'International';
      case 'OSHA':
        return 'Health & Safety';
      case 'TRANSPORTATION':
        return 'Transportation';
      case 'CAN-MEX':
        return 'CAN/MEX';
      default:
        return category;
    }
  }

  getErrorMessage() {
    return this.templateHead.get('auditName').hasError('required') ? 'Please enter an audit name before saving template.' : '';
  }

  onCategoryChange(category:string){
    this.currentCategory = category;
    this.step = -1;
  }

   /**
   * When a start date keyed in, set the default value and min value for the end date Input.
   * @param input start date
   */
    OnStartDateChange(input: Date){
      this.startDateValue = input;
      this.startDateValue.setHours(0, 0, 0, 0);
      if(input > this.templateHead.get("endDate").value){
        this.templateHead.get("endDate").setValue(new Date(input));
      }
    }

    async addEventToCalendar(): Promise<void> {
      var model = new NewEvent();
      const timeZone = this.azureService.user.timeZone;
      const graphEvent = model.getGraphEvent(timeZone);

      try {
        await this.graphService.addEventToCalendar(graphEvent);
        this.alertifyService.success('Event created.');
      }catch(error){
        this.alertifyService.error("Error creating event: " + error.message);
      }
    }

  async onAddEventToCalendar(): Promise<void> {
    let model = new NewEvent();
    model.subject = "Audit: " + this.templateHead.get('auditName').value;
    var start = new Date(this.templateHead.get("startDate").value);
    var today = new Date();
    start.setHours(today.getHours(), today.getMinutes(), today.getSeconds(), 0);
    model.start = start.toLocaleString();
    var end = new Date(this.templateHead.get("endDate").value);
    end.setHours(23, 59, 59, 0);


    model.end =  end.toLocaleString();
    //console.log("end: " + model.end);
    model.body = "Facility: " + this.templateHead.get('facilityName').value + " at " + this.templateHead.get('facilityAddress').value;

    const timeZone = (this.azureService.user && this.azureService.user.timeZone) || 'UTC';
    const graphEvent = model.getGraphEvent(timeZone);

    try {
      await this.signIn();
      if(this.graphService.isLogin()){
        await this.graphService.addEventToCalendar(graphEvent);
        this.alertifyService.success('Event created on Outlook calendar.');
      }
    } catch (error) {
      this.alertifyService.error('Error creating event:' + error.message);
    }
  }

  // sign in microsoft account.
  async signIn(): Promise<void> {
    await this.azureService.signIn();
  }


  // Click publication accordion to expand it and show topic list
  setStep(index: number, acronym: string) {
    this.step = index;

    if(!acronym || 0 === acronym.length){
      this.alertifyService.warning("Invalid acronym!");
      return false;
    }

    let theAcronym = this.topics.find(a => a.acronyms.some(item => item.acronym === acronym));
    const theTopics =  theAcronym.acronyms.find(a => a.acronym === acronym);

    if(theTopics.topics == null){
      this.service.showLoadingAnimation(true);
      this.service.getTopics(acronym, theTopics.releaseNum,this.csskey, theTopics.selected).subscribe(
        res => {
          this.service.showLoadingAnimation(false);
          let idx = this.topics.findIndex(x => x.category === this.currentCategory);
          if(idx !== -1){
            let tmp = this.topics[idx].acronyms.find(x => x.acronym === acronym);
            tmp.topics = res;
            tmp.topics.forEach( tp => {
              tp.topic = this.service.decodeHtml(tp.topic);
            })
          }
        },
        err =>{
          this.service.showLoadingAnimation(false);
          if(err.status === "404"){
            this.alertifyService.warning("Cannot find the protocol or not authorized.");
          }else{
            this.alertifyService.error(err.error);
          }
          this.step = -1;
          //this.alertifyService(err);
        }
      );
    }
  }

  unsetStep(){
    this.step = -1;
  }

  onSaveTemplate() {
    let errorMessage = this.getErrorMessage();
    if(errorMessage.length > 0){
      this.alertifyService.error(errorMessage);
      return false;
    }
    this.service.showLoadingAnimation(true);
    this.isLoading = true;
    let userId = JSON.parse(localStorage.getItem("account")).id;
    var th: TemplateHead = {
      csskey: this.csskey,
      auditName: this.templateHead.get('auditName').value,
      facilityName: this.templateHead.get('facilityName').value,
      auditStart: this.templateHead.get('startDate').value,
      auditEnd: this.templateHead.get('endDate').value,
      facilityLocation: this.formattedAddress??'122 West 2nd Avenue, Vancouver, BC, Canada',
      shared: this.permission != 'Not',
      permission: this.permission,
      clientId: userId,
      topicKey: this.cacheTopickey,
      customTopic: this.cacheCustomTopic
    };

    this.service.createTemplate(th)
      .subscribe(
        value => {
          this.isLoading = false;
          this.service.setAuditName(th.auditName, th.facilityLocation,
            new Date(th.auditStart).toDateString() + " - " + new Date(th.auditEnd).toDateString(),
            ""
          );
          if(value.csskey == this.csskey){
            // Update the template in the session storage: delete first, then add.
            this.service.removeItemFromCache(this.csskey);
            var templateItem: TemplateList = {
              csskey: this.csskey,
              auditName: th.auditName,
              cssDate: new Date(),
              isShared: this.isShared ? this.isShared:  false,
              sharedBy: '',
              permission: this.permission,
              status: '',
              citationLink:'',
              isUpdateAvailable: false,
              color: '',
              xceleratorLink:'',
              downloadable: false,
              isBuilding: false,
              monitoring:'',
              percentComplete:'',
              facilityLocation: th.facilityLocation,
              startDate: th.auditStart,
              endDate: th.auditEnd
            };
            this.service.addItemIntoCache(templateItem);
            this.alertifyService.success("Audit template updated.");
            this.service.setAuditName(th.auditName, th.facilityLocation,
              new Date(th.auditStart).toDateString() + " - " +  new Date(th.auditEnd).toDateString(),'');
            } else {
            this.csskey = value.csskey;
            var templateItem: TemplateList = {
              csskey: this.csskey,
              auditName: th.auditName,
              cssDate: new Date(),
              isShared: this.isShared ? this.isShared:  false,
              sharedBy: '',
              permission: this.permission,
              status: '',
              citationLink:'',
              isUpdateAvailable: false,
              color: '',
              xceleratorLink:'',
              downloadable: false,
              isBuilding: false,
              monitoring:'',
              percentComplete:'',
              facilityLocation: th.facilityLocation,
              startDate: th.auditStart,
              endDate: th.auditEnd
            };
            this.service.addItemIntoCache(templateItem);
            this.alertifyService.success("Audit template saved.");
          }
        },
        error => {
          this.alertifyService.error(error.error);
          this.service.showLoadingAnimation(false);
          this.isLoading = false;
        },
        () => {
          this.service.showLoadingAnimation(false);
          this.isLoading = false;
        }
      );
  }

  // Place a marker on map.
  placeMapMarker(loc){
    const geocoder = new google.maps.Geocoder;
    geocoder.geocode(
      {location: loc},
      ( results, status ) => {
          if(status === "OK"){
            if(results[0]){
              this.templateHead.get('facilityAddress').setValue(results[0].formatted_address);

              let marker = new google.maps.Marker({
                map: this.map,
                position: loc
              });
              let coordinates = loc;
              this.mapInitializer(coordinates, marker);
            }else{
              alert("No results found");
            }
          }else {
            alert("Geocoder failed due to: " + status);
          }
      });
  }

  backToHome(){
    this.router.navigateByUrl("/audithub/apptool/list");
  }

  // event handler for user inputs an address.
  refreshAddressOnMap(){
    const geocoder = new google.maps.Geocoder;
    geocoder.geocode({address: this.formattedAddress}, (results, status) => {
      if(status === "OK"){
        let coordinates = results[0].geometry.location;
        //this.map.setCenter(this.coordinates);
        let marker = new google.maps.Marker({
          map: this.map,
          position: coordinates
        });
        this.mapInitializer(coordinates, marker);
      } else {
        alert("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  /**
   * Open the dialog to display help information.
   * @param $event: click mouse event
   * @param index: the index of the help info. See ./_constants/AppSettting for more detail.
   */
  openHelpInfo($evt: MouseEvent, index: number): void {
    const target = new ElementRef($evt.currentTarget);
    const dialogRef = this.dialog.open(HelpInfoDialog, {
      width: '280px',
      data: {
        trigger: target,
        helperIndex: index
      }
    });
  }

  // Button 'Build Applicability' click event
  onBuildApplicability(){
    console.log("build applicability button clicked.");

    if(this.getSelectedTopicNumber() == 0){
      this.alertifyService.confirmOk("Cannot build applicability before any topics were selected.");
    }else{
      this.router.navigate(['audithub/apptool/edit/'+ this.csskey]);
    }
  }

  // refresh the model data to reflex check/uncheck on topics.
  topicKeyChange(key: number, change: boolean){
    for(var ca of this.topics){
      if(ca.category === this.currentCategory){
        for(var ac of ca.acronyms){
          if(ac.topics == null)
            continue;
          for(var tp of ac.topics){
            if(tp.topicKey == key){
              tp.selected = change;
              change? ac.selected++: ac.selected--;
              change? ca.selected++: ca.selected--;
              return;
            }
          }
        }
      }
    }
  }


   // Topic checkbox change event.
   onTopicChange(event, key:TemplateTopic, ac: string){
    if(this.csskey == 0){ // if it is a new audit, cache the topic key for future submitting, but not call the backend yet.
      if(key.selected){
        if(this.currentCategory == "Custom"){
          this.cacheCustomTopic.push(key.topicKey);
        }else{
          this.cacheTopickey.push(key.topicKey);
        }
      }else{
        if(this.currentCategory == "Custom"){
          let idx = this.cacheCustomTopic.indexOf(key.topicKey);
          if(idx != -1)
            this.cacheCustomTopic.splice(idx, 1);
        }else{
          let idx = this.cacheTopickey.indexOf(key.topicKey);
          if(idx != -1)
            this.cacheTopickey.splice(idx, 1);
        }
      }
      this.topicKeyChange(key.topicKey, key.selected); // refresh the model data to reflex check/uncheck on topics.
      return;
    }

    var data: CheckTopic = {
      csskey: this.csskey,
      topicKey: [key.topicKey],
      flag: key.selected,
      isCustomTopic: this.currentCategory === "Custom"
    };

    this.service.showLoadingAnimation(true);
    this.isLoading = true;
    this.service.updateTopic(data)
    .subscribe(
      val => {
        this.topicKeyChange(key.topicKey, key.selected); // refresh the model data to reflex check/uncheck on topics.
        this.service.showLoadingAnimation(false);

        if(key.selected){ // Add topic to the template.
          this.alertifyService.success( "Topic '" + key.topic + "' added.");
          if(data.isCustomTopic){// For custom topic, update the local storage.
            console.log("to save custom topic to local storage.");
            this.service.addCustomTopicLocalStorage(this.csskey, ac, key.topic, key.topicKey );
          }else{// For regular topic, remove the template from local storage becasue it is not acurate any more duo to the topic added.
            this.service.removeTemplateFromStorage(this.csskey);
          }
        }
        else{ // Remove topic from the template.
          this.alertifyService.success("Topic '" + key.topic + "' canceled.");

          if(data.isCustomTopic){ // There is difference when dealing with custom topics.
            this.service.removeCustomTopicFromStorage(this.csskey, key.topicKey);
          }else{
            this.service.removeTopicFromStorage(this.csskey, key.topicKey);
          }
          // delete the template from the cache if there is no rulebook in the template.
          this.checkTempalteStorage(this.csskey);
        }
      },
      error => {
        console.error("updateTopic() return: " + error.error);
        this.service.showLoadingAnimation(false);
        //this.serverMessage = error.error;
      },
      ( )=> {
        this.service.showLoadingAnimation(false);
        this.isLoading = false;
      }
    );
  }


  /**
   * Check if the template has any rulebooks in the storage.
   * If not, then remove the template from the storage.
   * @param csskey
   * @returns
   */
  checkTempalteStorage(csskey: number){
    var cacheTemplate = localStorage.getItem('template');
    if(cacheTemplate === null) return;

    let obj: AuditContent[] = JSON.parse(cacheTemplate);

    for(var audit of obj){
      if(audit.csskey != csskey) continue;

      if(audit.value == null || audit.value.length === 0){
        this.service.removeTemplateFromStorage(csskey);
      }
    }
  }

  /**
   * Remove rulebook list of the topic from template in the cache.
   * @param topicKey
   */
  removeRulebooksFromCache(topicKey: number){
    var tp = this.service.getRulebookLocalStorage(this.csskey);
    if(tp === null) return;
  }


  /**
   * Remove template from the cache;
   * Called when there is change in the template becasue the rulebook list in the cache will not be accurate.
   * @param csskey
   */
  removeTemplateFromCache(csskey: number){
    this.service.removeTemplateFromStorage(csskey);
  }

  // Event handler: check or uncheck all the topics under a publication
  onSelectAllClick(event){
    event.stopPropagation(); // do not propagate the event to expand or collapse the accordion.
  }

  // Event handler: check all topic checkbox change event.
  onSelectAllChange(event, acronym: string){
    if(this.csskey == 0){ // if it is a new audit, cache the topic key for future submitting, but not call the backend yet.
      if(event.checked){
        for(var ca of this.topics){
          if(ca.category != this.currentCategory)
            continue;
          for(var ac of ca.acronyms){
            if(ac.acronym == acronym){
              ac.topics.forEach(tp => {
                tp.selected = true;
                if(this.currentCategory == "Custom"){
                  let idx = this.cacheCustomTopic.indexOf(tp.topicKey);
                  if(idx == -1){
                    ac.selected++;
                    ca.selected++
                    this.cacheCustomTopic.push(tp.topicKey);
                  }
                }else{
                  let idx = this.cacheTopickey.indexOf(tp.topicKey);
                  if(idx == -1){
                    ac.selected++;
                    ca.selected++;
                    this.cacheTopickey.push(tp.topicKey);
                  }
                }
              });
              break;
            }
          }
        }
        return;
      }else {// existed template.
        for(var ca of this.topics){
          if(ca.category != this.currentCategory)
            continue;
          for(var ac of ca.acronyms){
            if(ac.acronym == acronym){
              ac.topics.forEach(tp => {
                if(this.currentCategory == "Custom"){
                  let idx = this.cacheCustomTopic.indexOf(tp.topicKey);
                  tp.selected = false;

                  if(idx != -1){
                    ac.selected--;
                    ca.selected--;
                    this.cacheCustomTopic.splice(idx, 1);
                  }
                }else{
                  let idx = this.cacheTopickey.indexOf(tp.topicKey);
                  tp.selected = false;

                  if(idx != -1){
                    ac.selected--;
                    ca.selected--;
                    this.cacheTopickey.splice(idx, 1);
                  }
                }
              });
              break;
            }
          }
        }
      }
    }

    this.doUpdateTopic(acronym, event.checked);
  }


  onShareChange(event){
    //console.log("is share: " + event.checked);
    this.isShared = event.checked;
  }


  // User checked/unchecked checkbox 'select all' and confirmed.
  doUpdateTopic(acronym: string, action: boolean){
    this.service.showLoadingAnimation(true);
    this.isLoading = true;
    var tpKeys = new Array();
    var isCustom = false;

    for(var ca of this.topics){
      for(var ac of ca.acronyms){
        if(ac.acronym == acronym){
          if(ca.category === "Custom") {
            isCustom = true;
          }
          ac.topics.forEach(tp => {
            if(tp.selected != action){
              tpKeys.push(tp.topicKey);
            }
          }); break;
        }
      }
    }

    var data: CheckTopic = {
      csskey: this.csskey,
      topicKey: tpKeys,
      flag: action,
      isCustomTopic: isCustom
    };

    this.service.updateTopic(data)
    .subscribe(
      val => {
        data.topicKey.forEach(x => {
          this.topicKeyChange(x, data.flag);
          if(data.flag){ // Add topic to the template.
            this.service.removeTemplateFromStorage(this.csskey);// remove the tempalte from cache because it is not accurate any more.
          }
          else{ // Remove topic from the template.
            if(data.isCustomTopic){ // There is difference when dealing with custom topics.
              this.service.removeCustomTopicFromStorage(this.csskey, x);
            }else{
              this.service.removeTopicFromStorage(this.csskey, x);
            }
          }
        });
        this.service.showLoadingAnimation(false);
        this.isLoading = false;
      },
      error => {
        console.error("updateTopic() return: " + error.error);
        this.service.showLoadingAnimation(false);
        this.isLoading = false;
      },
      ( )=> {
        this.service.showLoadingAnimation(false);
        this.isLoading = false;
      }
    );
  }

  // To delete the template
  onDeleteTemplate(){
    this.alertifyService.confirmOkCancel("STP AuditHub", "Are you sure to delete the template?",
    () =>{
      if(this.csskey == 0 || this.csskey == undefined) return;
      this.service.showLoadingAnimation(true);
      this.service.deleteTemplate(this.csskey)
        .subscribe( value => {
          this.service.showLoadingAnimation(false);
          this.service.removeItemFromCache(this.csskey);
          this.service.removeTemplateFromStorage(this.csskey);
          this.alertifyService.success("Template deleted.");
          this.router.navigateByUrl("/audithub/apptool/list");
        },
        error => {
          this.service.showLoadingAnimation(false);
          this.alertifyService.error(error.message);
        }
      );
    },
    () =>{// do nothing
    });
  }


  // get acronym by topickey
 afterTopickeyClick(topickey: number, action: boolean){
    for(var ca of this.topics){
      for(var ac of ca.acronyms){
        for(var tp of ac.topics){
          if(tp.topicKey == topickey){
            action? ac.selected++: ac.selected--;
            return;
          }
        }
      }
    }
  }

  openDialog(acronym:string): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed:' + result.event);

      if(result.event === 'Confirm'){ // Only delete topic when user confirms.
        this.doUpdateTopic(acronym, false);
      }else{
        this.afterTopicUpdate(acronym, true);
      }
    });
  }

  // get the number of topics select for this template
  getSelectedTopicNumber(){
    var result = 0;

    this.topics.forEach( tp => {
      tp.acronyms.forEach( ac => {
        result += ac.selected;
      });
    });

    return result;
  }

  // set selected topic numbers for the acronym
  afterTopicUpdate(acronym: string, action: boolean){
    for(var ca of this.topics){
      for(var ac of ca.acronyms){
        if(ac.acronym == acronym){
          ac.selected = 0;
          ac.topics.forEach(tp => {
            tp.selected = action;
            if(action)
              ac.selected++;
          });
          return false;
        }
      }
    }
  }
}


@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
})
export class ConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>) {}

    onConfirmClick(){
      this.dialogRef.close({event: 'Confirm'});
    }

    onCancelClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }
}