import { Component, ElementRef, OnInit, Injectable, Inject } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { AppSettings } from '../_constants/AppSettings';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import { AlertifyService } from '../_models/alertify.service';
import { PublicationService } from '../publication/publication.service';
import {RulebookCompare} from '../regcompare/subtopics';
import {RbStateDiffs,RbkeyStateDiffs,StateDiffs,ParaStateDiffs} from '../regcompare/statediff-model'
import { CookieService} from 'ngx-cookie-service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material/dialog';
/**
 * Node for to-do item
 */
 export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  dataChange2 = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  get data2(): TodoItemNode[] {
    return this.dataChange2.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    var rulebookCompare = new RulebookCompare();
    let source = rulebookCompare.TREE_DATA;
    const data = this.buildFileTree(source, 0);
    const data2 = this.buildFileTree(rulebookCompare.TREE_DATA2, 0);

    // Notify the change.
    this.dataChange.next(data);
    this.dataChange2.next(data2);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({item: name} as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}