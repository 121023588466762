<h2 mat-dialog-title>Editing Ranking Name</h2>
<div mat-dialog-content [formGroup]="myGroup">
        <mat-form-field class="full-width" mat-focused>
            <mat-label>Rank Name: </mat-label>
            <input matInput formControlName="rankingNameFormControl" cdkFocusInitial>
        </mat-form-field>
   
</div>
<div mat-dialog-actions class="right-end">  
  <button mat-button (click)="close()" class="mat-raised-button" cdkFocusInitial>Cancel</button>
  <button class="mat-raised-button mat-primary" mat-button (click)="onUpdateRankingName()">Save</button>
</div>