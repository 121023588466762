export const OAuthSettings = {
  appId: '67482d69-5ac2-40d3-9a46-140093aa0110',
  client_secret:'',
  redirectUri: 'https://audithub.stpehs.com',
  scopes: [
    "user.read",
    "mailboxsettings.read",
    "calendars.readwrite"
  ]
};

// Ref: https://docs.microsoft.com/en-us/graph/tutorials/angular?tutorial-step=3