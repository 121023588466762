import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-train-video',
  templateUrl: './train-video.component.html',
  styleUrls: ['./train-video.component.css']
})
export class TrainVideoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  displayVideo(src: string){
    var win = window.open();
    win.location.href = "/audithub/playvideo/" + src;
  }

}
