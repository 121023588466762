<div *ngIf="rbList else noData" class="right-block">

    <div class="widget-body">
      <div class="widget-toolbox" fxLayout="row" fxLayoutAlign="end center">
          <button mat-button (click)="onBackSummary()">Back to Summary</button>
          <button mat-button (click)="onNextRulebook()">Next Rulebook<mat-icon aria-hidden="true" aria-label="Next">arrow_right_alt</mat-icon></button>
      </div>
      <div class="widget-main">
        <ul *ngFor="let ac of rbList" class="nav-list">
          <ng-container *ngIf = "ac.acronym !== 'Custom'">

          <ul *ngFor="let tpc of ac.topics" class="sub-menu" fxLayout="row" fxLayoutAlign="start start">
            <li class="sub-menu">
              <span class="heavy">{{ac.acronym}}<mat-icon>chevron_right</mat-icon>{{tpc.topic}}</span>
              <ul *ngFor="let rb of tpc.rulebooks" class="sub-item">
                <div fxLayout="row" [ngClass]="{'active': rb.appKey == this.selected}">
                  <span fxFlex="75" [ngClass]="{'disabled': rb.abridged == 1}"
                    class="text-inside-list"  (click)="onClickRulebook(rb.appKey, rb.rbName, rb.abridged)">
                    {{rb.rbName}}<mat-icon *ngIf="rb.abridged" matTooltip="Abridged Protocol">info</mat-icon>
                  </span>
                  <span fxFlex="25">
                    <span *ngIf="rb.checkNa==false" class="rb-percent"
                      [ngClass]="{'complete': rb.percentage == '100%'}">{{rb.percentage}}
                    </span>
                    <span *ngIf="rb.checkNa==true" class="rb-checkNa">N/A</span>&nbsp;
                    <mat-icon *ngIf="rb.appKey == this.selected" aria-hidden="true" aria-label="Next" class="active">grade</mat-icon>
                  </span>
                </div>
              </ul>
            </li>
          </ul>
        </ng-container>
        </ul>
      </div>
      <div class="widget-toolbox" fxLayout="row" fxLayoutAlign="end center">
          <button mat-button (click)="onBackSummary()">Back to Summary</button>
          <button mat-button (click)="onNextRulebook()">Next Rulebook<mat-icon aria-hidden="true" aria-label="Next">arrow_right_alt</mat-icon></button>
      </div>
    </div>

</div>

<ng-template #noData>No Data Available</ng-template>