import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { PublicationComponent } from '../publication/publication.component';
import { ApptoolComponent } from '../apptool/apptool.component';
import { EditTemplateComponent } from '../apptool/edit-template/edit-template.component';
import {EditApplicabilityComponent} from '../apptool/edit-applicability/edit-applicability.component';
import {ViewRulebooksComponent} from '../apptool/view-rulebooks/view-rulebooks.component';
import {ViewCitationsComponent} from '../apptool/view-citations/view-citations.component';
import { AuthGuard } from '../auth/auth.guard';
import { NewAuditComponent} from '../apptool/new-audit/new-audit.component';
import {CustomQuestionsComponent} from '../apptool/custom-questions/custom-questions.component';
import { IpRequestComponent} from '../ip-request/ip-request/ip-request.component';
import {MyListComponent} from '../ip-request/my-list/my-list.component';
import { PocketRefComponent} from '../pocket-ref/pocket-ref/pocket-ref.component';
import { ShowPublicationComponent} from '../publication/show-publication/show-publication.component';
import { HomeComponent } from '../home/home.component';
import { RankAndScoreComponent } from '../apptool/rank-and-score/rank-and-score.component';
import { RegcompareComponent } from '../regcompare/regcompare.component';
import {TrainVideoComponent} from '../train-video/train-video.component';
import {PlayVideoComponent} from '../train-video/play-video/play-video.component';
import { BuildStatusComponent } from '../build-status/build-status.component';
import {PubviewTrackComponent} from '../pubview-track/pubview-track.component';

const dashboardRoutes: Routes = [
	{
		path: 'audithub',
		component: DashboardComponent,
		children:[
			{
				path: 'pocketref',
				component: PocketRefComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'home',
				component: HomeComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'publication',
				component: PublicationComponent,
				canActivate: [AuthGuard]
				//runGuardsAndResolvers: 'always'
			},
			{
				path: 'regcompare',
				component: RegcompareComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'trainvideo',
				component: TrainVideoComponent,
				canActivate:[AuthGuard]
			},
			{
				path: 'playvideo/:id',
				component: PlayVideoComponent
			},
			{
				path: 'publication/show/:id/:version/:format-id',
				component: ShowPublicationComponent,
				canActivate: [AuthGuard],
			},
			{
				path: 'iprequest',
				component: IpRequestComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'iprequest/list',
				component: MyListComponent,
				canActivate: [AuthGuard],
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'apptool/list',
				component: ApptoolComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'apptool/edit/:id',
				component: EditTemplateComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'apptool/new',
				component: NewAuditComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'apptool/new/:id',
				component: NewAuditComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'apptool/viewRulebooks/:csskey/:rbkey',
				component: ViewRulebooksComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'apptool/viewCitations/:csskey',
				component: ViewCitationsComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'apptool/applicability/:id/:appkey',
				component: EditApplicabilityComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'apptool/custom-questions',
				component: CustomQuestionsComponent,
				canActivate: [AuthGuard]
			},{
				path: 'apptool/rank-and-score',
				component:RankAndScoreComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'apptool/buildingStatus',
				component:BuildStatusComponent,
				canActivate: [AuthGuard],
			},
			{
				path: 'apptool/pubViewTrack',
				component:PubviewTrackComponent,
				canActivate: [AuthGuard],
			}
		]
	}
];

@NgModule({
  imports: [ RouterModule.forRoot(dashboardRoutes,  {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }