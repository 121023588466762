import { AfterViewInit, Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material/dialog';
import { CookieService} from 'ngx-cookie-service';

import {TemplateService} from '../service/template.service';
import { RegRegister} from '../model/TemplateRulebook';
import { AlertifyService } from '../../_models/alertify.service';
import {StateListDialog} from '../edit-template/edit-template.component';
import {StateDialog} from '../view-rulebooks/view-rulebooks.component';
import  { AuthService} from '../../auth/auth.service';
import { Account } from '../../_models';


@Component({
  selector: 'app-view-citations',
  templateUrl: './view-citations.component.html',
  styleUrls: ['./view-citations.component.css']
})
export class ViewCitationsComponent implements OnInit{
  csskey: number;
  regRegister$: Observable<RegRegister[]>;
  displayedColumns = ['citation', 'para', 'acronym', 'topic', 'rulebook', 'section'];
  account: Account;
  dataSource = new MatTableDataSource<RegRegister>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  hasState = false;
  states: string[];
  selectedState: string;

  constructor(public service: TemplateService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public cookieService: CookieService,
    private alertifyService: AlertifyService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      paraMap => {
        let para = paraMap.get('csskey');
        console.debug("para = " + para);
        this.csskey = +para.substring(0, para.indexOf('|'));
        console.debug("csskey = " + this.csskey);
      }

    );
    this.account = AuthService.currentUser;
    if(this.cookieService.check("authorized_state")){
      this.states = this.cookieService.get("authorized_state").split(",").sort();
    }

    this.selectedState = this.service.getFavoriteState(this.csskey.toString());
    this.loadConents(this.csskey, this.selectedState);

  }


  // Call backend to load the RegRegister
  loadConents(csskey: number, state?:string){
    try{
      this.service.showLoadingAnimation(true);
      this.regRegister$ =  this.service.getRegRegister(csskey, state);
      this.regRegister$.subscribe(
        res => {
          this.service.showLoadingAnimation(false);
          this.dataSource.data = res;
          this.dataSource.paginator = this.paginator;
          this.hasState = this.hasStateDifference();
          console.log("backend return ");
        },
        err => {
          console.error("got an error:" + err);
          this.service.showLoadingAnimation(false);
          this.alertifyService.error(err);
        },
        () => this.service.showLoadingAnimation(false)
      );
    }catch(Error){
      this.alertifyService.error(Error.message);
    }
  }


  // Given a rulebook key, to get the Acronym.
  getAcronym(): boolean{
    const found = this.dataSource.data.find(x => x.acronym == 'EAF' || x.acronym == 'OF');

    if(found !== undefined)
      return true;
    return false;
  }


  // Display state dialog.
  showState(){
    const state = this.service.getFavoriteState(this.csskey.toString());
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      selected: state,
      states: this.states
    };
    dialogConfig.width = '800px';

    const dialogRef = this.dialog.open(StateDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.service.cacheFavoriteState(this.csskey.toString(), result);
        this.selectedState = result;
        this.loadConents(this.csskey, result);
      }
    });
  }


  decodeHtml(str: string):string{
    var txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
  }


  // Back to summary.
  onBackSummary(){
    var myurl = 'audithub/apptool/edit/' + this.csskey ;
    this.router.navigateByUrl(myurl).then( err =>{
      console.log(err);
    });
  }

  // Build Reg Register.
  onBuild(buildType: string){
    if(this.hasStateDifference()){
      const state = this.service.getFavoriteState(this.csskey.toString());
      const states = this.cookieService.get("authorized_state").split(",").sort();

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        selected: state,
        states: states
      };
      dialogConfig.width = '800px';

      const dialogRef = this.dialog.open(StateListDialog, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if(result !== null){
          if(result.length > 0){
            this.service.cacheFavoriteState(this.csskey.toString(), result[0]);
            this.buildRegRegister(buildType, result)
          }else{
            this.buildRegRegister(buildType);
          }
        }
      });
    }else{
      this.buildRegRegister(buildType);
    }
  }


  buildRegRegister(buildType: string, st?: string[]): void {
    this.service.showLoadingAnimation(true);
    this.service.buildXcelerator(this.csskey, buildType,  "", st).subscribe(
      res  => {
        this.service.showLoadingAnimation(false);
        if(buildType == "reghub"){
          this.alertifyService.confirmOk("<p>RegRegister monitor has been set for the template.</p>");
        }else{
          this.alertifyService.confirmOk("Submit building request successfully. An email notice will be received when the file is ready.");
        }
      },
      err => {
        this.service.showLoadingAnimation(false);
        this.alertifyService.warning(err.message);
      },
      () => {
        this.service.showLoadingAnimation(false);

      }
    );
  }


   // To check if the template contains protocols that might have state difference.(Only EAF or OF has SD)
   hasStateDifference(): boolean {
    let authStates = this.cookieService.get("authorized_state");
    if(authStates && authStates.trim().length > 0){
      let states = authStates.split(",");
      if(states.length == 0) return false;
    }else{
      return false;
    }


    for(var item of this.dataSource.data){
      console.debug("acronym = " + item);
      if(item.acronym == "EAF" || item.acronym == "OF"){
        return true;
      }
    }
    return false;
  }

  // display citation link
  displayCitation(citation: string){
    this.service.getCitation(citation).subscribe(
      res => {
        window.open(res.link, "_blank");
      },
      err => {
        if(err.status == 404){
          this.alertifyService.error("Sorry, citation link not found")
        }else{
          this.alertifyService.error(err.message.substring(err.message.indexOf(": ")));
        }
      }
    );
  }

  // Event listenr for displaying paragraph
  displayParagraph(parakey: number): void {
    this.service.showLoadingAnimation(true) ;
    this.service.getPara(parakey).subscribe(
      res =>{
        this.service.showLoadingAnimation(false);
        let tmp = [res.paraNum, this.service.decodeHtml(res.question), this.service.decodeHtml(res.guideNote)];
        const dialogRef = this.dialog.open(ParaDisplayDialog, {
          width: '800px',
          data: tmp
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log("dfsljl");
        });
      },
      err => {
        this.service.showLoadingAnimation(false);
      }
    );
  }
}


@Component({
  selector: 'para-display',
  templateUrl: 'para-display.html',
  styleUrls: ['./view-citations.component.css']
})
export class ParaDisplayDialog {
  content: string;
  constructor(
    public dialogRef: MatDialogRef<ParaDisplayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string[]) {
      this.content = data[1] + data[2];
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}