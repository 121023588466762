import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IpRequestComponent } from './ip-request/ip-request.component';

import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTreeModule} from '@angular/material/tree';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import { MyListComponent } from './my-list/my-list.component';


@NgModule({
  declarations: [IpRequestComponent, MyListComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatProgressBarModule,
    MatCheckboxModule,MatExpansionModule
  ],
  exports:[IpRequestComponent, MyListComponent]
})
export class IpRequestModule { }
