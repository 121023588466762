<div class="widget-box">
	<div id="intro-answerquestions">
		<p>Use the following General Applicability Checklist to determine whether a rulebook and its sections apply to your operation.</p>
		<p>If you answer YES to the rulebook question, the rulebook applies to you unless you answer YES for a rulebook exemption.</p>
		<p>Once you determine that the rulebook does apply to your operation, you must answer the section
questions. If a section question does not exist, then that section applies if the rulebook applies. If you
answer YES to a section question, that section applies to you unless you answer YES for an exemption
from that section. If you answer YES to a rulebook or section exemption, then the rulebook or section
does not apply.</p>
  </div>
  <div class="answer-question" *ngIf="appSect$ | async as acs">
    <div class="widget-header">
      <h3>{{this.rbName}}</h3>
    </div>
    <div class="widget-body">
        <mat-card class="card-demo section-app" *ngIf="acs[0].rbSectNum == 0" [ngClass]="{'notAnswered':acs[0].answerQ == 'I'}">
            <mat-card-title><h3>Rulebook Question</h3></mat-card-title>
            <mat-card-content class="large">
              <div fxLayout="column" fxFill>
                <div fxLayout fxFlex>
                  <div class="one" fxFlex="83.3" [innerHTML]="acs[0].question.trim()">
                  </div>
                  <div class="two" fxFlex="26.7" fxLayoutAlign="end start">
                      <button id="s"
                        class="btn btn-audit btn-light Y"
                        [ngClass]="{'btn-selected': acs[0].answerQ == 'Y'}"
                        data-qtype="rulebook"
                        type="button"
                        [disabled]="isLoading || !editable || acs[0].answerQ == 'Y'"
                        (click)="answerRbAppQ(0, 'Y')">Yes
                      </button>
                      <button id="s"
                        class="btn btn-audit btn-light N"
                        [ngClass]="{'btn-selected': acs[0].answerQ == 'N'}"
                        data-qtype="rulebook"
                        type="button"
                        [disabled]="isLoading || !editable || acs[0].answerQ == 'N'"
                        (click)="answerRbAppQ(0, 'N')">No
                      </button>
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxFill *ngIf="acs[0].exemption != null && acs[0].answerQ == 'Y'" class="exemption-block">
                  <div fxLayout fxFlex>
                    <div class="one" fxFlex="83.3" [innerHTML]="acs[0].exemption">
                    </div>
                    <div class="two" fxFlex="26.7" fxLayoutAlign="end start">
                        <button id="s"
                          class="btn btn-audit btn-light Y"
                          [ngClass]="{'btn-selected': acs[0].answerE.toLocaleString() == 'y'}"
                          data-qtype="rulebook"
                          type="button"
                          [disabled]="isLoading || !editable || acs[0].answerE.toLocaleString() == 'y'"
                          (click)="answerRbAppE(0, 'y')">
                            Yes
                          </button>
                          <button id="s"
                            class="btn btn-audit btn-light N"
                            [ngClass]="{'btn-selected': acs[0].answerE.toLocaleString() == 'n'}"
                            data-qtype="rulebook"
                            type="button"
                            [disabled]="isLoading || !editable|| acs[0].answerE.toLocaleString() == 'n'"
                            (click)="answerRbAppE(0, 'n')">
                            No
                          </button>
                    </div>
                  </div>
                </div>
            </mat-card-content>
            <mat-card-footer class="bottomPad">
              <div class="hint"></div>
            </mat-card-footer>
        </mat-card>

        <mat-card class="card-demo section-app" *ngIf="acs.length > 1 && acs[0].answerQ == 'Y' && acs[0].answerE.toLocaleString() != 'y'">
            <mat-card-title>
              <h3>Section Question</h3>
              <mat-toolbar *ngIf="acs.length > 2" color="primary" class="all-button-line">
                <button mat-button [disabled]="isLoading || !editable" (click)="answerToAll('Y')">Yes to All</button>
                <button mat-button [disabled]="isLoading|| !editable" (click)="answerToAll('N')">No to All</button>
                <button mat-button [disabled]="isLoading|| !editable" (click)="answerToAll('I')">Clear All</button>
              </mat-toolbar>
            </mat-card-title>
            <mat-card-content *ngFor="let ac of acs" class="large">

                <mat-card *ngIf="acs.indexOf(ac) > 0" class="section-item" [ngClass]="{'notAnswered':ac.answerQ == 'I'}">
                  <mat-card-title>Section {{ac.rbSectNum}}</mat-card-title>
                  <mat-card-content   class="large">
                        <div fxLayout="column" fxFill>
                            <div fxLayout fxFlex>
                              <div class="one" fxFlex="83.3" [innerHTML]="ac.question.trim()">
                              </div>
                              <div class="two" fxFlex="26.7" fxLayoutAlign="end start">
                                  <button id="s"
                                    class="btn btn-audit btn-light Y"
                                    [ngClass]="{'btn-selected': ac.answerQ == 'Y'}"
                                    data-qtype="rulebook"
                                    type="button"
                                    [disabled]="isLoading || !editable || ac.answerQ == 'Y'"
                                    (click)="answerRbAppQ(acs.indexOf(ac), 'Y')">
                                      Yes
                                    </button>
                                    <button id="s"
                                      class="btn btn-audit btn-light N"
                                      [ngClass]="{'btn-selected': ac.answerQ == 'N'}"
                                      data-qtype="rulebook"
                                      type="button"
                                      [disabled]="isLoading || !editable ||  ac.answerQ == 'N'"
                                      (click)="answerRbAppQ(acs.indexOf(ac), 'N')">
                                      No
                                    </button>
                              </div>
                            </div>
                          </div>
                          <div fxLayout="column" fxFill *ngIf="ac.exemption != null && ac.answerQ == 'Y'" class="exemption-block">
                              <div fxLayout fxFlex>
                                <div class="one" fxFlex="83.3" [innerHTML]="ac.exemption">
                                </div>
                                <div class="two" fxFlex="26.7" fxLayoutAlign="end start">
                                    <button id="s"
                                      class="btn btn-audit btn-light Y"
                                      [ngClass]="{'btn-selected': ac.answerE.toLocaleString() == 'y'}"
                                      data-qtype="rulebook"
                                      type="button"
                                      [disabled]="isLoading|| !editable || ac.answerE.toLocaleString() == 'y'"
                                      (click)="answerRbAppE(acs.indexOf(ac), 'y')">
                                        Yes
                                      </button>
                                      <button id="s"
                                        class="btn btn-audit btn-light N"
                                        [ngClass]="{'btn-selected': ac.answerE.toLocaleString() == 'n'}"
                                        data-qtype="rulebook"
                                        type="button"
                                        [disabled]="isLoading|| !editable || ac.answerE.toLocaleString() == 'n'"
                                        (click)="answerRbAppE(acs.indexOf(ac), 'n')">
                                        No
                                      </button>
                                </div>
                              </div>
                            </div>
                    </mat-card-content>
                </mat-card>
              </mat-card-content>
              <mat-card-footer class="bottomPad">
                <div class="hint"></div>
              </mat-card-footer>
        </mat-card>
    </div>
  </div>
</div>