import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';

import { ApiService } from '../../core/api.service';
import { TemplateList,TemplateListReq,SearchTemplateListReq } from '../model/TemplateList';


@Injectable({
  providedIn: 'root'
})
export class TemplateListService {
  myTemplate: string;

  constructor( private apiService: ApiService) {
  }

  public getTemplateList(req: TemplateListReq): Observable<TemplateList[]> {
  	let URL = "Apptool/GetTemplateList";
  	return this.apiService.get(URL, req);
  }

  public searchTemplateList(req: SearchTemplateListReq): Observable<TemplateList[]> {
  	let URL = "Apptool/SearchTemplateList";
  	return this.apiService.get(URL, req);
  }

  public getMyTemplate(): string {
    if(this.myTemplate === undefined) return "1"; // display my template by default.
    return this.myTemplate;
  }

  public setMyTemplate(myTemplate: string): void {
    this.myTemplate = myTemplate;
  }
}