import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'stp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  StpDomain = environment.StpDomain;
  AuditHubDomain = environment.AudithubDomain;
  constructor() { }

  ngOnInit() {
  }

}
