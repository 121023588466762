<form (ngSubmit)="addUser()" [formGroup]="userForm">
  Name
  <br>
  <input type="text" formControlName="name">
  <br> Email
  <br>
  <input type="text" formControlName="email">
  <br> Role
  <br>
  <input type="text" formControlName="role">
  <br>
  <button [disabled]="userForm.invalid">Add User</button>
</form>
 
