import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config_data: any;

  constructor(private http: HttpClient) {}

  load(){
    const json_file_path = 'assets/dashboard/dashboard-data.json';

    return new Promise((resolve, reject) => {
        this.http.get<any>(json_file_path).toPromise().then(
            result => {
                this.config_data = result;
                resolve(this.config_data);
            }).catch(() => {
                reject(`couldn't load file ${json_file_path}`);
            });
        });
    }
}