import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sasol',
  templateUrl: './sasol.component.html',
  styleUrls: ['./sasol.component.css']
})
export class SasolComponent implements OnInit {

  constructor() { }
  public  sasolLink: string = "https://stphub.b2clogin.com/stphub.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_STPHub_SignUp_SignIn&client_id=685424a0-b4bd-4b7c-ba58-bc827d1a53a8&nonce=defaultNonce&redirect_uri=https%3A%2F%2Faudithub.stpehs.com%2Fsasol.asp&scope=openid&response_type=id_token&prompt=login";

  ngOnInit() {
  }

  login() {
    console.log("sasol login.");
    window.location.href = this.sasolLink;
  }

}
